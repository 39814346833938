@import '../../../styles/variables';
.calendarPageTable {
  &--date-column {
    text-align: center;
  }

  &--day, &--description {
    color: $adoptech-dark-grey-07;
  }

  &--item-column {
    display: inherit;
  }

  &--description, &--title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .meatballMenu {
    margin-left: auto;
  }

  &--4-columns {
    .adoptechGridTable--header,
    .adoptechGridTable--row {
      grid-template-columns: 1fr 2fr 8fr 1fr;
      margin-top: 5px;
    }
  }

  &--3-columns {
    .adoptechGridTable--header,
    .adoptechGridTable--row {
      grid-template-columns: 1fr 10fr 1fr;
      margin-top: 5px;
    }

    .userAvatar--text {
      display: none;
    }
  }


  &.wide-table {
    // for legal register actions rhs
    .calendarPageTable--3-columns {
      .adoptechGridTable--header,
      .adoptechGridTable--row {
        grid-template-columns: 2fr 8fr 1fr;
        margin-top: 5px;
      }
    }
    // for control check rhs
    .calendarPageTable--4-columns {
      .adoptechGridTable--header,
      .adoptechGridTable--row {
        grid-template-columns: 2fr 2fr 6fr 1fr;
        margin-top: 5px;
      }
    }

    .sortableTableHeader--icon {
      display: none;
    }
    .sortableTableHeader--header, .owner-column {
      cursor: initial;
      font-size: 12px;
    }
  }
  &--band-column {
    height: 100%;
    position: absolute;
    border-radius: 8px 0px 0px 8px;
    &.action {
      border-left: 8px solid $adoptech-aqua-blue;
    }
    &.event {
      border-left: 8px solid $adoptech-orange-07
    }
    &.corrective-action {
      border-left: 8px solid $adoptech-green-03
    }

    &.incident {
      border-left: 8px solid $adoptech-red
    }
  }
  .adoptechGridTable--row {
    padding: 10px;
    border-radius: 8px 0px 0px 8px;
  }

  .adoptechGridTable--row-completed {
    opacity: 0.5;

    .calendarPage--item-column {
      text-decoration: line-through;
    }
  }

  .adoptechGridTable--row:hover {
    background-color: $adoptech-light-grey-09;
    &.adoptechGridTable--row-expired {
      background-color: $adoptech-orange-05;
      opacity: 0.8;
    }
  }
  .adoptechGridTable--row-expired {
    background-color: $adoptech-orange-05;
  }
}

.adoptechDrawer .calendarPageTable--2-columns {
  .adoptechGridTable--header,
  .adoptechGridTable--row {
    grid-template-columns: 2fr 9fr;
  }
}
