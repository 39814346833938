@import './FieldBlocks';

@mixin RHSTableList {
  &--header {
    @include FieldTitle;
    justify-content: space-between;
  }

  &--tableRow {
    border-radius: 8px;
    background: #FFF;
    align-items: center;
    cursor: pointer;

    .fa-trash-can {
      height: 12px;
    }

    margin: 8px 0px;


    padding: 0px 16px;
    height: 30px;
    font-size: 12px;
    border-radius: 4px;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.20);

    &--large {
      height: 56px;
      padding: auto 16px;
      font-size: 14px;
      border-radius: 8px;
      box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.20);
    }
  }

  &--icon, &--icon-disabled {
    display: flex;
    justify-content: center;
  }

  .userAvatar--name {
    font-size: 14px;
  }

  &--tableHeaders {
    margin-top: 8px;
    color: #999995;
    font-size: 12px;
  }

  &--tableHeaders, &--tableRow {
    display: grid;
    grid-template-columns: 34fr 2fr;
    padding: 0px 16px;
  }
}
