@import '../../styles/variables';

.selectAnswer {
  width: 350px;

  .react-select {
    div[class*='menu'] {
      z-index: 11;
    }
  }
}
