@import './../EditEventModal/CalendarAttachments/CalendarAttachments';
@import './../../../styles/variables';

.editCorrectActionModal {
  padding: 8px 8px;
  .modal-content {
    border-radius: 8px;
  }

  @media (min-width: 667px) {
    .modal-dialog {
      max-width: 720px;
    }
  }

  &--fieldRow {
    margin-top: 16px;
    display: flex;
  }

  &--field {
    width: 100%;
  }

  &--field:not(:first-of-type) {
    padding-left: 20px;
  }

  .adoptechButton.adoptechButton-primary {
    text-transform: none;
  }

  .adoptechButtonContainer .btn.adoptechButton {
    &.completedButton, &.pendingButton {
      height: 27px;
      border-radius: 8px;
      padding: 0px 10px;
      color: $adoptech-primary-white;
      min-width: auto;
    }

    &.completedButton {
      background: $adoptech-green;
      border-color: $adoptech-green;
    }

    &.pendingButton {
      background: $adoptech-light-grey-02;
      border-color: $adoptech-light-grey-02;
    }
  }

  &--deleteButton {
    margin-left: 8px;
  }

  @include CalendarAttachments;
}
