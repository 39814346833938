@import 'app/javascript/src/styles/variables';

.userAvatar {
  position: relative;
  display: flex;
  align-items: center;

  &--circle {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;

    &__small {
      font-size: 18px;
      margin-right: 7px;

      .userAvatar--initials {
        font-size: 8px;
      }
    }

    &__medium,
    &__tag {
      font-size: 22px;
      margin-right: 7px;

      .userAvatar--initials {
        font-size: 10px;
      }
    }

    &__large {
      font-size: 36px;
      margin-right: 9px;

      .userAvatar--initials {
        font-size: 12px;
      }
    }
  }

  &--initials {
    position: absolute;
  }

  &--name {
    color: $adoptech-dark-grey-03;

    &__small,
    &__large {
      font-size: 15px;
    }

    &__medium {
      font-size: 16px;
    }
  }

  &--email {
    font-size: 12px;
    font-weight: 300;
  }

  &--tag {
    font-size: 16px;
  }

  &--hiddenText &--circle__tag {
    margin: 0;
  }
}
