/* stylelint-disable max-nesting-depth */
@import '../../styles/variables.scss';

.adoptechTable {
  margin-left: -20px;
  margin-right: -20px;

  &-modal {
    margin-left: 0;
    margin-right: 0;

    max-height: 40vh;
    overflow: auto;

    table.adoptechTable--wrapper {
      thead {
        th {
          border: none;
          padding-bottom: 5px;
          vertical-align: bottom;
          font-size: 14px;
          font-weight: normal;
        }
      }

      tr {
        cursor: pointer;

        td:first-child,
        th:first-child {
          width: 35px;
          padding-left: 40px;
        }

        td:last-child,
        th:last-child {
          padding-right: 40px;
        }
      }

      tbody {
        tr {
          background-color: $adoptech-light-grey-07;
        }
      }
    }

    &.smallPadding {
      table.adoptechTable--wrapper {
        tr {
          td:first-child,
          th:first-child {
            padding-left: 8px;
          }
  
          td:last-child,
          th:last-child {
            padding-right: 8px;
          }
        }
      }
    }
  }

  table.adoptechTable--wrapper {
    border-bottom: 1px solid $adoptech-light-grey-04;
    margin-bottom: 0;

    td:last-child,
    th:last-child {
      width: 1px;
    }

    th:first-child {
      padding-left: 20px;
    }

    td:first-child {
      padding-left: 20px;
    }

    .table td:last-child {
      padding-right: 20px;
    }

    td {
      color: $adoptech-dark-grey-03;
      font-size: 15px;
      border-top: 1px solid $adoptech-light-grey-04;

      &.adoptechTable--checkbox {
        vertical-align: top;

        label {
          padding: 0;
          padding-left: 20px;
        }
      }

      &.adoptechTable--clickableCell {
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    thead th {
      border-top: 0;
      border-bottom: 0;
      color: $adoptech-dark-grey-07;
      font-size: 14px;
      font-weight: normal;
    }

    td,
    th {
      padding-bottom: 11px;
      padding-top: 11px;
      padding-right: 20px;
      vertical-align: middle;
      white-space: nowrap;
    }
  }

  &--actions {
    display: flex;

    > * {
      cursor: pointer;
    }

    > *:not(:first-child) {
      margin-left: 1rem;
    }

    > .hidden {
      visibility: hidden;
    }

    &.disabled {
      opacity: 0.3;

      .iconButton {
        cursor: not-allowed;
      }
    }
  }

  &--small {
    width: 25%;
    overflow-wrap: anywhere;
  }

  &-hasMenu {
    table.adoptechTable--wrapper td {
      padding-bottom: 5px;
      padding-top: 5px;
    }
  }
}
