@import '../../../styles/variables';

.agreementClauseHeader {
  &--field {
    opacity: 1;
    transition: opacity 1s;
  }

  &--title {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 16px;

    > div {
      padding-right: 10px;
    }

    &-leftOut {
      .agreementClauseHeader {
        &--field {
          opacity: 0.2;
        }
      }
    }
  }

  &--categoryProgress {
    align-items: center;
    display: flex;
    height: 100%;
    width: 350px;
  }

  &--categoryProgressAmount {
    width: 40px;
  }

  &--categoryProgressBar {
    background-color: $adoptech-light-grey-07;
    height: 16px;
    margin: 0 10px;
    width: fill-available;
    max-width: 240px;
  }

  &--leaveOutToggle {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 20px 0;
  }
}
