@import '../../styles/variables';

.mainMenuItem {
  font-weight: bold;
  &--children {
    max-height: 300px;
    opacity: 1;
    transition: max-height 0.25s, opacity 0.25s;
    transform: scaleY(1);
    transition-duration: 0.25s;
    transition-property: transform;

    &-collapsed {
      display: none;
      max-height: 0;
      transform: scaleY(0);
      transition-duration: 0.25s;
      transition-property: transform;
    }
  }

  &--item {
    background: $adoptech-dark-grey-04;
    border: none;
    cursor: default;
    display: flex;
    font-size: 15px;
    padding: 7px 0 6px 1rem;
    width: 100%;

    &:focus {
      outline: none;
    }

    &:enabled {
      &:hover {
        background: $adoptech-dark-grey-04-02;
        color: $adoptech-light-grey-06-02;
      }

      &:focus-visible {
        outline: 1px solid $adoptech-dark-grey-04-02;
        color: $adoptech-light-grey-06-02;
      }
    }

    &-dark {
      color: $adoptech-dark-grey-09;
    }

    &-disabled {
      color: $adoptech-dark-grey-05;
    }

    &-highlight {
      background: $adoptech-dark-grey-05;
      color: $adoptech-light-grey-06;
    }

    &-pointer {
      cursor: pointer;
    }

    &-small {
      color: $adoptech-light-grey-06-02;
      font-size: 0.75rem;
    }

    &-toggle {
      display: flex;
      justify-content: space-between;
      margin: 12px 0px 6px 0px;
      padding: 0px 1rem;
    }
  }

  &--chevron {
    font-size: 16px;
    transition-duration: 0.25s;

    &-collapsed {
      transform: rotate(-180deg);
    }
  }

  &--icon {
    font-size: 18px;
    margin-left: -5px;
    margin-right: 8px;
    text-align: center;
    width: 26px;
  }

  &--label {
    align-self: center;
  }

  &--toggle {
    font-size: 20px;
  }
}
