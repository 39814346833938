.adoptechOverflowLine {
  &--wrapper {
    overflow: hidden;
    position: relative;
  }

  &--wrapper.overflow:before {
    content: '';
    transition: width 300ms linear;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    z-index: 2;
  }

  &--wrapper.overflow.scroll:before {
    width: 32px;
  }

  &--wrapper.overflow:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 100%;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 80%);
  }

  &--content {
    white-space: nowrap;
    display: inline-block;
    transition: margin-left 500ms linear;
  }
}
