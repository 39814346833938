@import '../../styles/variables';

.recentlyCreatedAssessmentsGrid {
  margin-top: 0px;

  td:not(.recentlyCreatedAssessmentsGrid--actions) {
    width: 15%;
  }

  &--nameHeader {
    width: 40%;
  }

  &--row {
    cursor: pointer;
    padding-top: 0px;
  }

  table {
    td.recentlyCreatedAssessmentsGrid {
      &--cell {
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }
  }
}
