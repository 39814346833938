@import '../../styles/variables';

.radioButtonsAnswer {
  &--button {
    margin-bottom: 16px;
    margin-left: 5px;
    margin-right: 25px;
  }

  &--other {
    display: flex;

    input {
      height: 35px;
      margin-left: 20px;
      width: 300px;
    }
  }

  &--otherError {
    color: $adoptech-red;
    padding-left: 20px;
  }

  &--otherTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &--buttons {
    display: flex;
    flex-direction: column;
    color: $adoptech-dark-grey-05;
  }

  &--buttonsClosedQuestion {
    @extend .radioButtonsAnswer--buttons;
    display: inline-block;
  }

  &--buttonsClosedQuestion > div {
    display: inline-block;
  }
}
