@import '../../../styles/variables';

.signAgreementModal {
  .modal-content {
    border-radius: 8px;
    font-size: 14px;
  }

  &--imageContainer {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 130px;
  }

  &--canvasWrapper {
    position: relative;
  }

  &--mainText {
    padding-top: 26px;
    margin-bottom: 20px;
  }

  &--canvasText {
    font-size: 12px;
    margin-bottom: 10px;
  }

  &--actionBtn {
    z-index: 999;
    position: absolute;
    top: 0px;
    right: 0px;
    border: 1px solid $adoptech-light-grey-01;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 27px;
    border-radius: 3px;
    cursor: pointer;
    &:active {
      border-color: $adoptech-dark-grey-09;

      svg {
        color: $adoptech-dark-grey-06;
      }
    }

    svg {
      color: $adoptech-dark-grey-07;
      margin: 0
    }
  }

  &--actionBtnAlt {
    background-color: $adoptech-dark-grey-07;
    border-color: $adoptech-dark-grey-07;

    svg {
      color: $adoptech-light-grey-01;
    }

    &:active {
      border-color: $adoptech-dark-grey-07;
      background-color: $adoptech-dark-grey-08;

      svg {
        color: $adoptech-light-grey-01;
      }
    }
  }
}