@import './../variables';

@mixin ControlIconMixin {
  &--greenTick, &--greyTick, &--redTick {
    font-size: 20px;
    margin-right: 16px;
  }

  &--greenTick {
    color: $adoptech-dark-green;
  }

  &--greyTick {
    color: $adoptech-light-grey-04;
  }

  &--redTick {
    color: $adoptech-red;
  }
}
