@import '../../styles/variables';

.adoptechReactSelect {
  &--label {
    font-size: 14px;
    color: $adoptech-dark-grey-07;
    margin-bottom: 2px;
    display: block;
  }

  &.adoptech-error {
    background-color: $adoptech-red-background;
    border: solid 1px $adoptech-red;
  }

  &--isDisabled {
    cursor: not-allowed;
    .adoptechReactSelect--prefix__value-container {
      opacity: 0.5;
    }
  }

  &--checklist-option {
    [class$='-option']:active {
      background-color: $adoptech-light-grey-06;
    }
  }
}
