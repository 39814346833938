@import '../../styles/variables';

.checklistTemplateItem {
  display: flex;
  border-bottom: 1px solid $adoptech-light-grey-01;
  padding: 20px;
  align-items: center;
  justify-content: space-between;

  &--question {
    color: $adoptech-dark-grey-03;
    font-size: 16x;

    display: flex;
    flex-direction: column;
  }

  &--questionText {
    margin-bottom: 5px;
  }
}
