@import '../../../styles/variables';
@import '~bootstrap/scss/bootstrap';
@import '../../../styles/StickyHeader.scss';

.riskCategoryPage {
  &--registerRisks {
    min-height: 205px;
    @include media-breakpoint-up(xxl) {
      min-height: 355px;
    }
  }

  .adoptechTextInput-search {
    width: 170px;
    height: 30px;
    margin: 0px 10px;

    @include media-breakpoint-up(xxl) {
      min-width: 300px;
      margin: 0px 22.5px 0px 14.5px;
    }
  }

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 22px;

    .btn.adoptechButton--with-large-icon img {
      margin-right: 16px;
    }
  }

  &--title {
    min-width: fit-content;
  }

  &--3-columns {
    .adoptechGridTable--header,
    .adoptechGridTable--row {
      grid-template-columns: 4fr 6fr 2fr;
      margin-top: 0px
    }

    .noDataText {
      margin-left: 68px;
      margin-top: 12px;
      color: $adoptech-dark-grey-05;
    }

    .adoptechGridTable--row-button {
      text-align: end;
      .btn.adoptechButton--with-large-icon {
        min-width: 93px;
      }
    }

    .adoptechGridTable--row:hover {
      background-color: $adoptech-light-grey-09;
    }

    .adoptechGridTable--header {
      border-bottom: 1px solid $adoptech-light-grey-01;
    }
  }
}

.riskCategoryFilter {
  margin-left: auto;
  display: flex;
  align-items: center;
  overflow: hidden;

  &--title {
    font-size: 14px;
    color: $adoptech-dark-grey-07;
  }

  &--categoryFilter {
    display: flex;
    border: 1px solid $adoptech-light-grey-01;
    border-radius: 4px;
    margin-left: 8px;
  }
  &--categoryFilterItem {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    height: 30px;
    font-size: 14px;
    white-space: nowrap;
    cursor: pointer;
    z-index: 2;

    &:not(:last-of-type) {
      border-right: 1px solid $adoptech-light-grey-01;
    }

    &.selected {
      background-color: $adoptech-dark-grey-07;
      color: white;
    }
  }
}