@import '../../../../styles/variables';

.trusthubRequestDocumentModal {
  .modal-dialog {
    min-width: 588px;
  }
  color: #333335;

  .modal-content {
    border-radius: 8px;
  }

  &--header {
    display: flex;
    padding: 32px 32px 0;
    justify-content: space-between;
    align-items: center;

    .fa-xmark {
      cursor: pointer;
      width: 16x;
      height: 16px;
      color: #666665
    }
  }

  &--body {
    padding: 16px 32px ;
  }

  &--headerTitle {
    color: #333335;
    font-weight: 300;
    font-size: 28px;
  }

  &--search {
    .adoptechTextInput-search input {
      padding-right: 30px;
      border-radius: 4px;
      border: 1px solid #ccc;
      background: #FFF;
      height: 40px;

      &:focus {
        outline: 2px solid #999995;
      }
    }
  }

  &--divider {
    width: 524px;
    height: 1px;
    background: #DDD;
    margin-top: 24px;
  }

  &--footer {
    display: flex;
    justify-content: space-between;
    padding: 32px;
  }

  &--sectionTitle {
    color: #333335;
    font-size: 18px;

    margin-top: 16px;
    margin-bottom: 16px;
  }

  &--divider + div {
    margin-top: 8px;
  }

  &--documents {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &--documentCheckboxSection {
    display: flex;
    gap: 16px;
    align-items: center;
    max-width: 80%;
  }

  &--documentTitle.editMode {
    max-width: 100%;
  }

  &--document {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.20);

    width: 100%;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;

    &:hover {
      cursor: pointer;
    }

    .fa-lock-keyhole {
      height: 16px;
    }

    .adoptechCheckbox {
      width: 20px;

      display: flex;
      align-items: center;
      height: 32px;

      .emptyLabel { visibility: hidden; }

      input:not(:checked) + label {
        &::before, &::after {
          border: 1px solid $adoptech-light-grey-01;
          background-color: $adoptech-primary-white;
        }
      }

      input:checked + label {
        &::before, &::after {
          background-color: $adoptech-dark-grey-05;
        }

        &::before {
          border: none;
        }
      }
    }
  }

  &--documentTitle {
    max-width: 60%;
    overflow-wrap: break-word;
  }

  .fa-circle-check {
    font-size: 20px;
    color: $adoptech-green;
  }

  .fa-clock {
    font-size: 20px;
    color: $adoptech-dark-grey-06;
  }
}
