@import '../../styles/variables';

.policyCategoryRow {
  &--footer {
    align-items: center;
    background-color: $adoptech-light-grey-08;
    display: flex;
    font-size: 15px;
    min-height: 60px;
    padding: 10px 180px 10px 20px;
    width: 100%;
  }

  &--options {
    margin-left: 20px;
  }

  &--incompleteWarningModal {
    .modal-content {
      height: auto;
      min-width: inherit;
    }
  }
}
