@import '../../../../../../styles/variables';
@import '../../../../mixins/TrustHubPage';

.trusthubUserAdminCompanyPage {
  &--header {
    @include TrustHubPageHeader();
  }

  &--panel {
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 14px;
  }

  &--panelInfo {
    font-size: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &--buttons {
    display: flex;
    gap: 8px;
    .adoptechButton-white {
      font-size: 14px;
    }
  }

  &--panelSectionRow {
    display: flex;
    flex-direction: row;
  }

  &--panelSectionRowColumn {
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex: 1;
    align-items: center;

    div:nth-of-type(1) {
      color: #999995;
    }

    div:nth-of-type(2) {
      color: #333335;
    }
  }

  &--panelSection {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &--panelTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &--panelDivider {
    background: #E8E8E8;
    height: 1px;
    width: 100%;
  }

  &--panelSectionTitle {
    font-weight: bold;
  }

  .adoptechReactSelect {
    width: 320px;
  }
}
