@import '../../styles/variables';

.assessmentQuestions {
  position: relative;
  z-index: 504;

  &--header {
    display: flex;
    padding: 20px 20px 4px;
    color: $adoptech-dark-grey-07;
    font-size: 14px;
    font-weight: 400;
  }

  &--footer {
    padding: 18px;
    height: 58px;
  }
}
