@import '../../styles/variables.scss';

.generateChecklistManagersModal {
  .modal-dialog {
    max-width: 720px;
    width: 720px;

    .modal-body {
      height: 350px;
    }
  }

  &--guidance {
    font-size: 14px;
  }

  &--header {
    display: flex;
    flex-direction: column;
  }

  &--status {
    font-weight: 400;
    font-size: 16px;
    padding: 16px 0;
  }

  &--subHeader {
    font-weight: 400;
    font-size: 15px;
    margin-top: 5px;
  }

  &--title {
    font-size: 18px;
    font-weight: 400;
    margin-top: 15px;
  }

  label {
    font-size: 14px;
    color: $adoptech-dark-grey-07;
    margin-bottom: 2px;
    display: block;
  }

  .adoptechTable {
    max-height: 25vh;
  }

  /* TODO: fix stylelint */
  /* stylelint-disable */
  table {
    tbody {
      .adoptechCheckbox {
        label {
          color: $adoptech-dark-grey-03;
          font-size: 15px;
          padding-left: 40px;
        }
      }
    }
  }
  /* stylelint-enable */

  &--staffMembers {
    margin-bottom: 20px;
  }

  .adoptechTextArea textarea {
    height: 200px;
  }

  &--skipLink {
    color: $adoptech-dark-grey-05;
    cursor: pointer;
    font-size: 14px;
    margin-top: 15px;
    text-decoration: underline;

    &:hover {
      color: $adoptech-dark-grey-02;
    }

    &:focus {
      color: $adoptech-dark-grey-02;
      outline: 1px dashed $adoptech-dark-grey-07;
    }
  }
}
