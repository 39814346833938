@import '../../styles/variables';

.editOwnerModal {
  .modal-dialog {
    max-width: 480px;
  }

  label {
    margin-top: 10px;
  }

  input {
    margin-bottom: 5px;
  }
}
