@import '../../styles/variables.scss';

.customizeClauseWarningModal {
  &--subtitle {
    font-size: 15px;
    color: $adoptech-dark-grey-03;
    margin-bottom: 20px;
  }

  label {
    font-size: 14px;
  }

  .modal-footer {
    margin-top: 20px;
  }
}
