.peopleTable {

  &--row {
    &:hover {
      background-color: #f6f6f6;
      cursor: pointer;

      box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.1);
    }
    display: flex;
    height: 56px;
    padding: 12px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #DDD;
    background: #FFF;

    display: grid;
    grid-template-columns: 4fr 1fr 2fr 1fr auto;
    gap: 20px;

    &:first-child {
      border-top: 1px solid #DDD;
    }

    .fa-cloud-arrow-down {
      cursor: pointer;
    }
  }

}
