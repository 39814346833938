@import '../../styles/variables';

.adoptechModal {
  .modal-content {
    border-radius: 3px;
  }

  &.rounded {
    .modal-content {
      border-radius: 8px;
      box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.15);
    }
  }

  .modal-header {
    border-bottom: 0;
    color: $adoptech-dark-grey-04;
    font-size: 30px;
    font-weight: 300;
    padding: 30px 40px;
    padding-bottom: 12px;
  }

  .modal-body {
    padding: 0 40px;
    color: $adoptech-dark-grey-03;
  }

  .modal-footer {
    border-top: 0;
    display: flex;
    justify-content: space-between;
    padding: 30px 40px;
  }

  .modal-footer > * {
    margin: 0;
  }

  &--subtitle {
    color: $adoptech-dark-grey-05;
  }
}
