@import '../../../styles/variables';

.reportsPreviewPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 30px;

  &--back {
    color: $adoptech-primary-orange;
    cursor: pointer;

    display: flex;
    align-items: center;
    font-size: 4rem;
  }

  &--column {
    margin-left: 10px;
    margin-right: 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .btn {
      margin-left: 10px;
    }

    &:first-of-type {
      overflow: hidden;
      max-height: 150px;
    }
  }

  &--columnData {
    color: $adoptech-dark-grey-04;
    font-size: 16px;
    overflow: hidden;
  }

  &--columnHeader {
    color: $adoptech-dark-grey-07;
    font-size: 14px;
  }

  &--info {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
  }

  &--mainContent {
    display: flex;
    height: 100%;
    margin: 10px;
  }

  &--progressBar {
    background-color: $adoptech-orange-02;
    height: 10px;
  }

  &--downloadColumn {
    margin-left: auto;

    svg {
      margin-right: 7px;
    }

    .adoptechButtonContainer .btn.adoptechButton {
      border: 1px solid $adoptech-dark-grey-08;
    }
  }
}
