@import '../../styles/variables';

.editProfileFields {
  &--error {
    color: $adoptech-red;
    display: flex;
    justify-content: center;

    &-hidden {
      display: none;
    }
  }

  &--field {
    width: 100%;

    ::placeholder {
      color: $adoptech-dark-grey-05;
    }
    input {
      height: 40px;

      border-radius: 4px;
      background: #F5F5F5;
      border: 1px solid #F5F5F5;
    }

    @include media-breakpoint-up(xs) {
      &:nth-of-type(2) {
        padding-left: 20px;
      }
    }
  }

  &--fieldRow {
    display: flex;
    padding-bottom: 16px;

    @include media-breakpoint-down(xxs){
      row-gap: 16px;
      flex-direction: column;
    }
  }

  &--fields {
    flex-grow: 1;

    .PhoneInput {
      border-radius: 4px;
      background: #F5F5F5;
      border: 1px solid #F5F5F5;
      padding-left: 8px;
    }

    .PhoneInputCountrySelectArrow {
      display: none;
    }
  }

  &--label {
    color: $adoptech-dark-grey-07;
    font-size: 14px;
  }
}
