.reviewTestModal {

  &--modal {
    .modal-dialog {
      min-width: 545px;
    }

    .modal-content {
      border-radius: 8px;
    }
  }

  &--body {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .adoptechTextAreaInternalLabel-container {
      border-radius: 4px;
      border: 1px solid #ddd;
      background: #fff;
    }
  }

  &--footer {
    display: flex;
    justify-content: space-between;
  }

  &--statusButtons {
    display: flex;
    gap: 16px;
  }

  &--commentTextarea label, &--uploadEvidenceLabel {
    color: #666665;
    font-size: 14px;
  }

  &--commentTextArea > div:first-child {
    border-radius: 4px;
    border: 1px solid #DDD;
    background: #FFF;
  }
}

