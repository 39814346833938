@import '../../../styles/variables';

.policyEditor {
  padding-top: 30px;

  &--back {
    color: $adoptech-primary-orange;
    cursor: pointer;
    font-size: 4rem;
    display: flex;
    align-items: center;
  }

  &--spinner {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  &--summary {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 13;
    margin-left: -10px;
    padding-left: 10px;
    margin-right: -10px;
    padding-right: 10px;
  }

  &--summaryTile {
    width: 100%;
    background-color: $adoptech-primary-white;
    margin-bottom: 18px;
  }

  &--lockedModal {
    .modal-content {
      height: fit-content;
      min-width: 300px;
    }
  }

  .adoptechAccordionCard {
    box-shadow: none;
    margin-bottom: 10px;

    .card-header {
      height: 45px;
      box-shadow: 1px 3px 5px $adoptech-light-grey-03;
    }

    &--angleIcon {
      font-size: 35px;
    }
  }

  .greenTick {
    color: $adoptech-green;
    font-size: 20px;
  }

  .greyTick {
    color: $adoptech-light-grey-05;
    font-size: 20px;
  }
}
