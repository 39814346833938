@import '../../../../../styles/variables';
@import './../../mixins/RHSTableList';

.complianceControlRisksList {
  @include RHSTableList();

  &--noRisks {
    font-size: 12px;
    padding-top: 8px;
    a {
      color: #212529;
      text-decoration: none;
    }
  }
}
