@import '../../styles/variables.scss';

@mixin applyFieldWidth($width) {
  [class*='--fields'] > div, [class$='--selectedBackground']{
    width: $width;
  }
}

.adoptechSliderSwitcher {
  &--2-fields {
    $field-width: 80px;
    width: $field-width * 2;

    @include applyFieldWidth($field-width);
  }

  &--3-fields {
    $field-width: 100px;
    width: $field-width * 3;
    @include applyFieldWidth($field-width);
  }

  $border-radius: 20px;
  $inline-offset: 10px;

  height: 2.5rem;
  background: $adoptech-light-grey-06;
  border-radius: $border-radius;
  cursor: pointer;
  position: relative;

  &--fields {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &--field {
    display: flex;
    align-items: center;
    margin-left: $inline-offset;
    margin-right: $inline-offset;
    justify-content: center;
    opacity: 0.5;
    height: 100%;
    &-selected {
      opacity: 1;
    }
  }

  &--selectedBackground {
    height: 85%;
    z-index: 1;
    justify-content: center;
    border-radius: $border-radius;
    background: $adoptech-primary-white;
    top: calc($border-radius / 7);
    transition: left 0.25s ease-out;
    position: absolute;
  }


  &--label {
    z-index: 2;
    text-transform: uppercase;
    font-size: 14px;
  }
}
