@import '../../../../styles/variables';

.paymentPlanCard {
  width: 342px;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  @include media-breakpoint-down(lg) {
    width: auto
  }

  &--top-panel {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    background: #F5F5F5;
    padding-top: 34px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    height: 216px;
  }

  &--top-panel-title {
    color: $adoptech-dark-grey-03;
    font-weight: bold;
    font-size: 32px;
  }

  &--top-panel-price {
    color: $adoptech-orange-08;
    font-weight: bold;
    font-size: 23px;
    line-height: 28px;
  }

  &--top-panel-period {
    color: $adoptech-dark-grey-03;
    font-size: 14px;
  }

  &--discounts-text {
    font-style: italic;
    display: flex;
    justify-content: center;
    font-size: 14px;
    margin-bottom: 8px;
  }

  &--top-panel-summary {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #333335;
    padding: 0 41px;
    min-height: 32px;
  }

  &--main-panel {
    position: relative;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    background: $adoptech-primary-white;
    padding: 28px 32px;
    height: 520px;
    display: flex;
    flex-direction: column;

    p {
      color: #333335;
      font-size: 14px;
      font-weight: bold;
    }

    ul {
      &:first-of-type {
        min-height: 216px;
      }
      color: #333335;
      font-size: 14px;
      padding-left: 0;
      padding-inline-start: 12px;
    }

    li {
      margin-bottom: 12px;
      padding-left: 8px;
    }

    li::marker {
      // TODO: Find way how to import fortawesome from CSS
      content: url('images/icons/checkIcon.svg');
    }

    .paymentPlanCard--footer {
      margin-top: auto;
    }
    .adoptechButtonContainer, .adoptechButton {
      width: 100%;
    }
  }

  &--active {
    position: relative;
    border-radius: 8px;
    border: 2px solid #FF621E;
    box-shadow: 1px 1px 16px 0 rgba(0, 0, 0, 0.20);

    .adoptechButtonContainer {
      border: 1px solid #FF621E;
      border-radius: 4px;
    }
  }

  &--activeBadge {
    text-transform: uppercase;
    display: flex;
    padding: 4px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 30px;
    background: #FF621E;
    color: #F2F2F2;
    font-size: 12px;
    position: absolute;
    top: 8px;
    right: 12px;;
  }
}
