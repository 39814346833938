@import '../../styles/variables';

.mainMenuFooter {
  background: $adoptech-dark-grey-03;
  color: $adoptech-dark-grey-08;
  cursor: default;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  height: 2.5rem;
  padding: 1rem;
}