@import '../../styles/variables';

.frameworksPills {
  display: flex;
  column-gap: 8px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: $adoptech-primary-white;

  &--framework {
    background-color: $adoptech-aqua-blue;
    padding: 5px 10px;
    border-radius: 30px;
    white-space: nowrap;
  }

  &--iso_27001 {
    background-color: $adoptech-mid-blue;
  }

  &--soc_2 {
    background-color: $adoptech-dark-green;
  }
  
  &--cyber_essentials {
    background-color: $adoptech-green-cyan;
  }

  &--demo, &--bespoke {
    background-color: $adoptech-orange-01;
  }
}
