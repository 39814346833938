@import '../../styles/variables';

.notifications {

  .dropdown-menu {
    width: 770px;
    overflow-y: auto;
    padding: 14px 0px 8px;
    min-height: 500px;
    box-shadow: $adoptech-box-shadow;

    &.noData {
      min-height: 85px;
    }

    &::after {
      background: white;
      content: '';
      height: 12px;
      width: 12px;
      right: 12px;
      top: -7px;
      border-left: $adoptech-light-grey-05 solid 1px;
      border-top: $adoptech-light-grey-03 solid 1px;
      position: absolute;
      transform: rotate(45deg);
    }
  }

  div:not(:last-child) a {
    border-bottom: $adoptech-light-grey-05 solid 1px;
  }

  .iconButton {
    align-items: center;
    display: flex;
    font-size: 26px;
    height: 100%;
    justify-content: center;
    color: $adoptech-dark-grey-05;

    &:hover {
      color: $adoptech-dark-grey-07;
    }
  }

  &:not(.notifications-disabled) {
    &:hover {
      cursor: pointer;
    }
  }
  > div {
    height: 38px;
    width: 38px;
  }

  &--counter {
    background-color: $adoptech-primary-orange;
    color: $adoptech-dark-grey-04;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: 12px;
    position: absolute;
    top: 0;
    right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--empty {
    font-size: 16px;
    padding: 16px;
    text-align: center;
    color: $adoptech-dark-grey-04;
  }
}
