@mixin clausesList() {
  .adoptechAccordionCard {
    box-shadow: none;
    margin-bottom: 16px;

    .card-header {
      height: 45px;
      box-shadow: 1px 1px 3px $adoptech-light-grey-03;
    }

    &--angleIcon {
      font-size: 35px;
    }
  }

  .greenTick {
    color: $adoptech-green;
    font-size: 20px;
  }

  .greyTick {
    color: $adoptech-light-grey-05;
    font-size: 20px;
  }
}