@import '../../styles/variables';

.assessmentCompletedResponses {
  margin-bottom: 24px;
  margin-top: -28px;

  &--body {
    width: 100%;
  }

  &--header {
    color: $adoptech-dark-grey-05;
    margin-bottom: 4px;
    margin-left: 20px;
  }

  &--panelContent {
    align-items: center;
    color: $adoptech-dark-grey-03;
    display: flex;
    font-size: 20px;
    width: 100%;
  }

  &--noResponse {
    margin: 20px;
    font-size: 20px;
    color: $adoptech-dark-grey-03;
  }

  &--stateIcon {
    color: $adoptech-green;
    font-size: 20px;
    margin-right: 16px;
  }

  &--vendorName {
    width: 50%;
  }

  &--lastModified {
    align-items: center;
    display: flex;
    font-size: 16px;
    margin-right: 100px;
  }

  &--lastModifiedDate {
    width: 90px;
  }
}
