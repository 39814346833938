@import '../../styles/variables';

.assessmentsTemplateTilesGrid {
  &--grid {
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin-top: 20px;

      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }
}
