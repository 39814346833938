@import '../../../styles/variables';

@mixin checkCircleIcon {
  .svg-inline--fa {
    font-size: 20px;

    &.fa-circle {
      color: $adoptech-light-grey-05;
    }

    &.fa-circle-check {
      color: $adoptech-green;
    }
  }
}

.riskRegisterTreatment {

  &--treatmentStrategyModify,
  &--treatmentStrategySimple {
    .adoptechReactSelect {
      width: 100%;
    }
  }

  &--strategyRow {
    margin-top: 20px;

    .adoptechRadioButton {
      height: 42px;

      label {
        &::before,&::after {
          margin-top: 7px;
        }
      }
    }

    .adoptechReactSelect {
      width: 100%;
    }
  }

  &--controlsRow {
    border-bottom: 1px solid $adoptech-light-grey-03;
    cursor: pointer;

    .frameworksPills {
      overflow: auto;
    }

    & > div:last-of-type {
      text-align: end;
    }
  }

  &--controlsRowTitle {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &--tasksRow,
  &--controlsRow,
  &--tasksHeader,
  &--controlsHeader {
    grid-template-columns: 8fr 3fr 2fr 0.5fr;
  }

  &--actionRow, &--actionsHeader {
    grid-template-columns: 8fr 1fr 1fr 2fr
  }

  &--tasksRow,
  &--actionRow,
  &--controlsRow {
    border-bottom: 1px solid $adoptech-light-grey-03;
    display: grid;

    align-items: center;
    padding: 8px 0;

    @include checkCircleIcon()
  }

  &--tasksHeader,
  &--controlsHeader,
  &--actionsHeader {
    border-bottom: 1px solid $adoptech-light-grey-03;
    display: grid;
    margin-top: 30px;
    padding-bottom: 10px;
  }

  &--controlSlug,
  &--actionSlug {
    color: $adoptech-dark-grey-04;
    background-color: $adoptech-light-grey-03;
    display: inline-block;
    padding: 0 14px;
    border-radius: 14px;
    margin-right: 10px;
  }

  &--taskSlug {
    color: $adoptech-dark-grey-04;
    background-color: $adoptech-light-grey-07;
    display: inline-block;
    padding: 0 14px;
    border-radius: 14px;
    margin-right: 10px;
  }

  &--treatmentStrategyModify &--taskSlug {
    margin-left: 40px;
  }

  &--fieldRow {
    display: flex;
    align-items: center;
    & > div {
      width: 360px;

      &:not(:first-of-type) {
        padding-left: 20px;
      }
    }
  }
}
