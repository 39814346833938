@import '../../styles/variables';

.shareModal {
  min-width: 520px;
  .modal-dialog {
    max-width: 575px;
  }

  &--label {
    color: $adoptech-dark-grey-07;
    font-size: 14px;
  }

  &--message {
    textarea {
      color: $adoptech-dark-grey-05;
    }
  }

  &--usersAndPermissions {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 20px;
  }

  &--userAutocomplete {
    width: 100%;
  }

  .react-tags {
    min-width: 300px;
  }

  &--permissionSelect {
    width: 300px;
    margin-left: 20px;
  }

  &--getLink {
    padding: 16px 40px;
    background-color: $adoptech-light-grey-07;
    color: $adoptech-dark-grey-05;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--copyLink {
    color: $adoptech-primary-orange;
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
    width: 100px;
    margin-left: 80px;

    &:active {
      color: $adoptech-logo-orange;
    }
  }

  &--getLinkInfo {
    font-size: 14px;
  }

  &--getLinkTitleIcon {
    background-color: $adoptech-light-grey-04;
    border-radius: 50%;
    padding: 5px;
    font-size: 30px;
  }

  &--getLinkTitle {
    display: flex;
    align-items: center;
  }

  &--getLinkTitleText {
    margin-left: 8px;
    font-size: 24px;
  }
}
