@import '../../styles/variables';

.confirmationModal {
  padding: 8px 8px;

  &.adoptechModal .modal-content {
    border-radius: 8px;
  }

  &.adoptechModal .modal-footer {
    padding: 20px 40px 30px;
  }

  &--subject {
    border: none;
    border-radius: 4px;
    color: $adoptech-dark-grey-03;
    font-size: 16px;
    font-weight: 400;
    margin-top: 19px;
    resize: none;
    width: 100%;
  }

  &--description {
    font-size: 16px;
    .hasSubject & {
      font-size: 14px;
    }
  }

  .adoptechButtonContainer .btn.adoptechButton-primary {
    text-transform: none;
  }

  .modal-header {
    color: $adoptech-dark-grey-03;
    font-size: 28px;
  }

  textarea:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}
