@import '../mixins/TrustHubSection.scss';

.companyDetailsSection {
  @include TrustHubSection();

  &--companyName {
    font-size: 28px;
  }

  &--companyNameRow {
    display: flex;
    justify-content: space-between;
  }
  &--companyNameColumn {
    display: flex;
    gap: 16px;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    max-width: 85%;
  }

  &--companyEmailRow {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
  }

  &--companyEmail, &--companyDescription {
    font-size: 14px;
  }

  &--noData {
    .companyDetailsSection--companyName,
    .companyDetailsSection--companyEmailRow,
    .companyDetailsSection--companyDescription {
      opacity: 0.5;
    }
  }
}

.companyDetailsSection--editMode {
  input {
    border-radius: 4px;
    font-size: 14px;
    width: 300px;
  }
  .companyDetailsSection--companyActions {
    display: flex;
    gap: 16px;

    .adoptechButtonContainer .btn.adoptechButton {
      min-width: 75px;
    }
  }

  .companyDetailsSection--companyNameAndEmail {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
