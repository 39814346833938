@import '../../styles/variables';
@import '../../styles/mixins/DrawerTabs.scss';

.registerReviewDrawer {
  @include DrawerTabs();

  &--fieldRow {
    display: flex;
  }

  &--fieldRow:not(:first-of-type) {
    padding-top: 16px;
  }

  &--field {
    width: 100%;
  }

  &--field:not(:first-of-type) {
    padding-left: 20px;
  }

  .card {
    box-shadow: none;
    .card-body.withBorderBottom {
      border-bottom: none;
    }
  }
}
