@mixin StatusField {
  gap: 16px;
  &-icon {
    display: flex;
    gap: 8px;
    align-items: center;

    color: #ccc;
    &.green {
      color: #6CAA45;
    }

    &.red {
      color: #e55f4e;
    }
  }
}
