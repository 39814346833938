@import '../../../../src/styles/variables.scss';

.passwordStrength {
  width: 100%;
  margin: 16px 0;

  &--advice {
    padding-right: 20px;
  }

  &--adviceWarning {
    color: $adoptech-red;
    font-weight: bold;
  }

  &--row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
  }

  &--bar {
    background-color: $adoptech-light-grey-07;
    border-radius: 5px;
    height: 10px;
    overflow: hidden;

    .progressBar {
      border-radius: 5px;
    }

    .progressBar--progress-10 {
      background-color: $adoptech-red;
    }

    .progressBar--progress-25 {
      background-color: $adoptech-primary-orange;
    }

    .progressBar--progress-50 {
      background-color: $adoptech-yellow;
    }

    .progressBar--progress-75 {
      background-color: $adoptech-green;
    }

    .progressBar--progress-100 {
      background-color: $adoptech-dark-green;
    }
  }

  &--strength {
    color: $adoptech-dark-grey-05;
    font-size: 14px;
    margin-right: 5px;
  }

  &--text {
    color: $adoptech-dark-grey-07;
    font-size: 14px;

    &-10 {
      color: $adoptech-red;
    }

    &-25 {
      color: $adoptech-primary-orange;
    }

    &-50 {
      color: $adoptech-yellow;
    }

    &-75 {
      color: $adoptech-green;
    }

    &-100 {
      color: $adoptech-dark-green;
    }
  }

  &--info {
    color: $adoptech-primary-orange;
    cursor: pointer;
    font-size: 14px;
    margin-left: 5px;
    text-decoration: underline;
    text-align: right;

    &:focus {
      color: $adoptech-dark-grey-04;
      outline: 1px dashed $adoptech-dark-grey-07;
    }
  }
}

.modal-open .popover {
  max-width: 500px;
}
