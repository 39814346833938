@import '../variables';
@mixin DrawerTabs {
  nav {
    top: 80px;
    z-index: 1001;
    background-color: $adoptech-light-grey-05;
    border: none;
    margin-left: 0;

    .nav-item {
      &.disabled {
        opacity: 0.6;
      }
    }

    a {
      position: relative;
      color: $adoptech-dark-grey-05;

      &::after {
        content: '';
        display: none;
        position: absolute;
        height: 2px;
        width: 80%;
        background-color: $adoptech-dark-grey-05;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    a:hover {
      &::after {
        display: block;
        background-color: $adoptech-dark-grey-07;
      }
    }

    a.active::after {
      display: block;
    }
  }
}
