@import '../../styles/variables';
$card-header-z-index: 10;

.adoptechAccordionCard {
  position: relative;

  &--error {
    background-color: $adoptech-red-background;
    border: solid 1px $adoptech-red;
  }

  &--doneButton {
    text-align: right;
    position: absolute;
    bottom: 10px;
    right: 20px;
  }

  &--angleIcon {
    color: $adoptech-dark-grey-07;
    font-size: 48px;
    transition-duration: 0.2s;
    transition-property: transform;
  }

  &--tooltip {
    background-color: #f1881f;
    color: #fff;
    font-size: 16px;
    justify-content: center;
    padding: 10px 30px;
    position: absolute;
    margin-left: 10px;
    top: -6px;
    width: 197px;
    right: 66px;
    z-index: $card-header-z-index + 1;
    box-shadow: 0 0 8px #00000029;
    border-radius: 2px;
  }

  &--tooltip-pointer {
    margin-left: 154px;
    margin-top: 18px;
    overflow: hidden;
    position: absolute;
    width: 26px;
    height: 27px;
    z-index: -1;

    &::after {
      background-color: $adoptech-primary-orange;
      bottom: 0;
      box-shadow: 0 0 8px #00000029;
      content: '';
      height: 49px;
      left: -29px;
      top: -9px;
      position: absolute;
      transform: rotate(45deg);
      width: 44px;
    }
  }

  &--tooltip-button {
    margin-top: 10px;
    cursor: pointer;
    text-align: center;
  }

  // Long selectors for overwriting bootstrap styles
  // It's better to not put all other styles to long selectors in order to not slow down the performance
  .card {
    overflow: initial;
    border: none;

    .card-body {
      padding: 0;
      position: relative;

      &--centered {
        padding-right: 1.25rem;
        padding-left: 1.25rem;
        padding-bottom: 10px;
      }

      &.withBorderBottom {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      }
    }

    .card-header {
      background-color: $adoptech-primary-white;
      margin-bottom: 0;
    }
  }
}

.card {
  transition-duration: 0.2s;
  transition-property: margin;

  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-duration: 0.2s;
  }

  .adoptechAccordionCard--angleIcon {
    &.small--icon {
      width: 18px;
    }
  }
  &.expanded {
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 4px 12px $adoptech-box-shadow-light;

    .noBorderBottom {
      border-bottom: none;
    }

    .readOnly {
      &:hover {
        cursor: not-allowed
      }

      pointer-events: none;
      user-select: none;
    }

    .positionInitial {
      position: initial;
    }

    > .card-header {
      > .adoptechAccordionCard--angleIcon {
        transform: rotate(180deg);
      }
    }
  }

  &.noMargin {
    margin: 0;
  }

  .card-header {
    height: 72px;
    font-size: 20px;
    cursor: pointer;
    color: $adoptech-dark-grey-03;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: $card-header-z-index;
  }

  .react-datepicker-popper {
    z-index: $card-header-z-index + 1;
  }

  // card in card
  .card {
    .card-header {
      top: 72px;
      z-index: $card-header-z-index - 1;
    }

    &.expanded {
      &:not(:last-of-type) {
        margin-bottom: 0;
      }
    }
  }
}
