.manageBillingModal {
  .modal-dialog {
    width: 1124px;
    max-width: 80%;
  }

  .modal-footer {
    justify-content: flex-end;
  }
}
