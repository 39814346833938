.risksStartNewDrawer {
  width: 700px;

  &--label {
    margin-bottom: 8px;
  }

  &--fieldRow:not(:first-of-type) {
    margin-top: 24px;
  }
}
