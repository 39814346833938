@import '../../styles/variables';
@import '../../styles/mixins/DropdownMenuItem.scss';
@import '../../styles/StickyHeader.scss';

.registryHeader {
  $row-height: 32px;

  &--header {
    display: flex;
    align-items: center;
    gap: 16px;

    height: 80px;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  &--actionsRaisedColumn {
    .lozenge {
      margin-left: 0px;
      min-width: 24px;
      height: 24px;
      width: fit-content;
      background-color: $adoptech-dark-grey-08;

      font-size: 14px;
      color: $adoptech-primary-white;
      display: flex;
      align-items: center;
    }
  }

  &--actionsRaisedRow {
    display: flex;
    align-items: center;
    column-gap: 8px;
    .btn {
      line-height: 1rem;
      height: $row-height;
    }
  }

  &--columnLabel {
    color: $adoptech-dark-grey-05;
    font-size: 14px;
  }
  &--ownerColumn, &--reviewDateColumn, &--actionsRaisedColumn {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    label { display: none; }
    input#adoptechDatePicker--datePicker {
      height: $row-height;
      border-radius: 4px;
    }

    .userAvatar {
      svg {
        width: 18px;
      }
      .userAvatar--initials {
        font-size: 9px;
      }
    }

    .adoptechDatePicker--dateContainer {
      height: $row-height;

      .react-datepicker-wrapper {
        border: none;
      }

      .adoptechDatePicker--selector {
        top: -26px;
        margin: 0 10px 0 0;
      }
    }

    .react-select {
      height: $row-height;

      .adoptechReactSelect--prefix__control {
        height: $row-height;
        min-height: auto;
        background-color: $adoptech-primary-white;
        border: 1px solid $adoptech-light-grey-01;
        border-radius: 4px;

        color: $adoptech-dark-grey-05;

        .adoptechReactSelect--prefix__value-container {
          height: 28px;
          position: relative;
          padding: 0px 5px;
        }
        .adoptechReactSelect--prefix__single-value {
          display: flex;
          align-items: center;
          top: auto;
          position: absolute;
          transform: none;
          height: 28px;
        }

        .adoptechReactSelect--prefix__indicator {
          height: 28px;
          display: flex;
          align-items: center;
        }

        .adoptechReactSelect--prefix__indicators {
          height: 28px;
        }

        .adoptechReactSelect--prefix__indicator-separator {
          display: none;
        }
      }
    }
    input {
      background-color: $adoptech-primary-white;
    }
  }

  &--ownerColumn {
    min-width: 200px;
  }


  &--divider {
    height: 53px;
    border-right: 1px solid $adoptech-light-grey-04;
  }

  &--lastReviewedText, &--nextReviewText {
    height: $row-height;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  &--headerButtons {
    display: flex;
    margin-left: auto;
    row-gap: 10px;
    column-gap: 10px;
    padding-top: 1rem;

    @include media-breakpoint-down(xl) {
      column-gap: auto;
      row-gap: auto;
    }

    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
    }
  }

  &--reportsButton {
    @include dropdownMenuItems($adoptech-dark-grey-05, $adoptech-dark-grey-08);
  }
  &--addButton {
    @include dropdownMenuItems($adoptech-primary-orange, $adoptech-orange-09);
  }
  &--review-status {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid;
    &-awaiting-review {
      border-color: $adoptech-orange-01;
      color: $adoptech-orange-01;
    }

    &-reviewed {
      border-color: $adoptech-dark-green;
      color: $adoptech-dark-green;
    }
  }

}

.sticky-header {
  @include stickyHeader(1);
  background-color: #F5F5F5;
}
