@import '../../../styles/variables';

.riskRegisterEditor {
  padding-top: 30px;

  &--summary {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 13;
    margin-bottom: 18px;
    padding-top: 7px;
  }

  .card-body>hr {
    margin: 20px -20px;
  }

  &--back {
    color: $adoptech-primary-orange;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 4rem;
  }

  .adoptechButtonContainer {
    width: auto;
  }

  .adoptechTextArea {
    width: 100%;
  }

  p {
    margin-top: 20px;
  }

  label[for='riskInherentRiskLikelihood'],
  label[for='riskInherentRiskConsequence'],
  label[for='riskResidualRiskLikelihood'],
  label[for='riskResidualRiskConsequence'] {
    max-width: fit-content;
  }

  &--riskScore label{
    font-size: 14px;
    color: $adoptech-dark-grey-07;
  }

  &--name {
    font-weight: 600;
    color: $adoptech-dark-grey-04;
  }

  &--fieldRowCheckboxGroup {
    margin-top: 16px;
    margin-bottom: 24px;
  }

  &--fieldRowCheckbox {
    &:not(:first-of-type) {
      margin-top: 16px;
    }
  }

  &--summaryBody {
    display: flex;
    justify-content: space-between;
    margin: 10px;

    &>div {
      display: flex;
      align-items: center;
    }

    .adoptechButtonContainer,
    .meatballMenu {
      margin-left: 10px;
      margin-right: 20px;
    }
  }

  &--summaryDescription {
    margin-left: 10px;
    margin-right: auto;
  }

  &--progressBar {
    background-color: $adoptech-orange-02;
    height: 10px;
  }

  &--fieldRow {
    display: flex;

    &>div {
      width: 360px;

      &:not(:first-of-type) {
        padding-left: 20px;
      }
    }
  }

  .adoptechAccordionCard {
    margin-bottom: 10px;

    .card-header {
      box-shadow: 1px 3px 5px $adoptech-light-grey-03;
      font-size: 15px;
      height: 45px;

      .svg-inline--fa {
        font-size: 20px;
        color: $adoptech-light-grey-05;

        &.fa-circle-check {
          color: $adoptech-green;
        }
      }
    }

    .card-body {
      color: $adoptech-dark-grey-05;
      font-size: 14px;
      padding: 20px 20px 10px;

      .riskRegisterEditor--guidance {
        color: $adoptech-dark-grey-05;
        font-weight: 400;
        padding: 8px 0;
      }

      .riskRegisterEditor--title {
        color: $adoptech-dark-grey-03;
        font-weight: 700;
      }
    }

    &--angleIcon {
      font-size: 35px;
    }
  }

  .autoSaveToast {
    bottom: 70px;
    position: fixed;
    right: calc(50% - 90px);
    z-index: 30;

    .toast {
      border: 1px solid $adoptech-dark-grey-07;
    }

    .toast-body {
      background-color: $adoptech-dark-grey-07;
      font-size: 18px;
      color: $adoptech-primary-white;
    }
  }
}

#confidentiality-tooltip, #availability-tooltip, #integrity-tooltip {
  .tooltip-inner {
    max-width: 100%;
  }
}
