.addSupplierSearchScreen {
  .modal-dialog {
    min-width: 720px;
  }

  &--field {
    margin-top: 24px;
  }

  &--badge {
    border-radius: 10px;
    background: #F2F2F2;
    width: 40px;
    display: flex;
    justify-content: center;
  }

  &--noResults {
    background: #FAFAFA;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0px 16px;
  }

  &--noResultsTitle {
    margin-bottom: 16px;
    margin-top: 24px;
    font-weight: bold;
  }

  .adoptechGridTable {
    margin-top: 25px;
    &--header, &--row {
      grid-template-columns: auto 130px;
    }

    &--row {
      &:hover, &.selected {
        background-color: #F2F2F2;
        cursor: pointer;

        [class$='--badge'] {
          background: #fff;
        }
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}
