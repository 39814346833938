@import '../../../styles/variables';
@import '~bootstrap/scss/bootstrap';

.reportsList {
  box-shadow: 1px 1px 5px #d8d8d8;
  border-radius: 4px;
  &--5-columns {
    .adoptechGridTable--header,
    .adoptechGridTable--row {
      grid-template-columns: 5fr 2fr 2fr 2fr 1fr;
      margin-top: 5px;
    }

    .adoptechGridTable--row {
      padding: 17px 16px;
      font-size: 14px;
      color: $adoptech-dark-grey-05;
      padding: 8px 16px;
      line-height: 18px;
      height: 48px;
    }
    .adoptechGridTable--row:hover {
      background-color: $adoptech-light-grey-09;
    }
  }

  .adoptechGridTable--header {
    margin-bottom: 0.5rem;
  }

  &--actions {
    display: flex;
    justify-content: space-between;
    color: $adoptech-dark-grey-07;

    svg {
      min-width: 22px;
      min-height: 15px;
    }
  }

  &--actionsHeader {
    display: flex;
    justify-content: center;
  }

  &--badge {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 10px;
    height: 24px;
    background: $adoptech-dark-grey-08;
    border-radius: 30px;
    color: $adoptech-primary-white;
    width: fit-content;
  }
}
