@import '../../../../../styles/variables.scss';
@import '../../mixins/FieldBlocks.scss';
@import './../../mixins/StatusField';

.complianceEditTaskForm {
  @include FieldBlocks;
  overflow-y: auto;

  input, textarea {
    &:disabled {
      cursor: not-allowed;
    }
  }

  &--control-check-header {
    @include FieldTitle;
    @include StatusField;
  }
}
