@import '../../styles/variables';

.inProgressPolicies {
  padding-bottom: 3rem;

  &--header {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 2fr;
  }

  &--title {
    align-items: center;
    color: $adoptech-dark-grey-05;
    display: inline-flex;
    font-size: 24px;
    padding-right: 30px;
  }

  &--upload {
    align-items: baseline;
    color: $adoptech-dark-grey-07;
    display: flex;
    font-size: 14px;
  }

  &--uploadButton {
    padding-left: 20px;
  }
}
