@import '../../styles/variables';

.profileMenu {
  background-color: $adoptech-primary-white;
  box-shadow: 0 3px 6px $adoptech-box-shadow-color;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  min-width: 160px;
  opacity: 1;
  position: absolute;
  right: 5px;
  top: 45px;
  z-index: 505;

  a {
    color: $adoptech-dark-grey-07;

    &:hover {
      color: $adoptech-dark-grey-05;
      text-decoration: none;
    }
  }

  &--email {
    background-color: $adoptech-light-grey-07;
    align-items: center;
    display: flex;
    height: 50px;
    padding: 0 15px;
  }

  &--link {
    align-items: center;
    border-top: 1px solid $adoptech-light-grey-06;
    color: $adoptech-dark-grey-07;
    display: flex;
    height: 35px;
    padding: 0 20px;
    white-space: nowrap;

    &:hover {
      color: $adoptech-dark-grey-05;
      text-decoration: none;
    }
  }
}
