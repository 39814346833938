@import './../../styles/mixins/AdoptechInputGreyBackgrund';

.adoptechFileInput {
  @include AdoptechInputGreyBackground;
  cursor: pointer;

  img {
    max-height: 22px;
  }

  &.no-gap {
    .adoptechFileInput--labelWithPreview {
      gap: initial;
    }
  }

  &--labelWithPreview {
    display: flex;
    gap: 80px;

    > div:first-child {
      min-width: 120px;
    }
  }
}

