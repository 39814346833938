@import '../../styles/variables';

.pageHeader {
  background-color: $adoptech-light-grey-06-02;
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  padding: 0 40px;
  height: 60px;

  &--label {
    align-self: center;
    color: $adoptech-dark-grey-05;
    display: flex;
    font-size: 28px;
  }

  &--toolbarArea {
    align-self: center;
    display: flex;
  }

  &--searchArea {
    min-width: 600px;
    @include media-breakpoint-down(xl) {
      min-width: auto;
    }
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    min-height: 100px;
    overflow: auto;
    top: 0;
  }

  &--menu {
    display: flex;
  }

  &--toolbar {
    display: flex;
    font-size: 24px;
    justify-content: space-between;
    width: 250px;
    align-items: center;
    min-width: 140px;
  }

  &--icon {
    cursor: pointer;
    min-width: 24px;
    opacity: 0.7;
  }
}
