.trustHubUserAdminVendorUserActivities {
  &--panel {
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 14px;
  }

  &--panelInfo {
    font-size: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &--panelTitle {
    display: flex;
    justify-content: space-between;
  }

  &--2-columns {
    height: 250px;
    overflow-y: auto;
  }

  &--tableRow, &--tableHeader {
    display: grid;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 8px;
    grid-template-columns: 8fr 4fr;
  }

  &--panelTable {
    margin-top: 24px;
    width: 50%;
  }

  &--tableRow {
    font-size: 14px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.20);
    height: 38px;
    align-items: center;
  }
}
