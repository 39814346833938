@import '../../../styles/variables';

.pestelSection {
  margin-top: 2rem;
  color: $adoptech-dark-grey-05;

  &--pestelTypeSection {
    display: flex;
    margin-top: 1.5rem;
    width: 100%;
    flex-direction: column;
  }

  &--pestelHeader {
    display: flex;
    width: 100%;
    background-color: $adoptech-primary-white;
  }

  &--itemTitle {
    font-size: 22px;
    width: 100%;
  }

  &--itemTypeHeader {
    padding-left: 0.9rem;
  }

  &--description {
    font-size: 16px;
    padding-bottom: 1.5rem;
  }

  &--noDataText {
    padding-top: 40px;
    font-size: 16px;
  }

  .adoptechGridTable--row {
    height: 48px;
    column-gap: 56px;
    font-size: 14px;
    color: #666665;
    padding: 8px 16px;
    line-height: 18px;
  }

  .adoptechTable--actions {
    flex-direction: row-reverse;
  }

  &--4-columns {
    .adoptechGridTable--header,
    .adoptechGridTable--row {
      grid-template-columns: 1fr 7fr 1fr 0.5fr;
      margin-top: 5px;
    }
    .adoptechGridTable--row:hover {
      background-color: $adoptech-light-grey-09;
    }
  }

  .adoptechGridTable--row > div {
    padding: 10px 0;
  }

  .meatballMenu--button .iconButton {
    font-size: 25px;
  }
}
