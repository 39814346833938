.addSupplierConnectionScreen {
  .modal-dialog {
    min-width: 720px;
  }

  &--body {
    display: flex;
    gap: 16px;
    flex-direction: column;
  }
  span.adoptechTextInput-error {
    display: none;
  }
}
