.statusBadge {
  border-radius: 10px;
  background: #FFF6EB;
  color: #F1881F;
  display: flex;
  height: 20px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: fit-content;

  &.approved {
    background: #EEFBE5;
    color: #6CAA45;
  }
  &.rejected {
    background: #FEF3F2;
    color: #EA5542;
  }
}

