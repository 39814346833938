@import '../../styles/variables';

.checklistGroupItem {
  border-bottom: 1px solid $adoptech-light-grey-01;
  padding: 20px 20px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &--question {
    color: $adoptech-dark-grey-03;
    font-size: 16px;

    display: flex;
    flex-direction: column;

    width: 80%;
  }

  &--questionText {
    margin-bottom: 5px;
  }

  &--questionNote {
    margin-top: 20px;
  }

  &--answers {
    display: flex;
    align-items: center;
    padding-left: 25px;
    flex: 1 0;
    justify-content: space-between;
  }

  &--na {
    padding-right: 10px;
  }

  .lastUpdatedInfo {
    justify-content: flex-start;
    margin-top: 10px;
    font-size: 14px;

    &--lastUpdatedAt,
    &--lastUpdatedBy {
      font-size: 14px;
    }
  }
}
