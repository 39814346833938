@import './../../styles/variables';

.globalToast {
  position: fixed;
  z-index: $adoptech-max-z-index;

  &--with-undo-button {
    .messageToast {
      right: calc(50% - 250px);
      width: 500px;

      div:first-of-type {
        max-width: 500px;
      }
    }

    &--undo {
      align-self: center;
      display: flex;
      flex-direction: column;
      margin: 0 2rem;
    }
  }
}
