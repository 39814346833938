@import '../../../styles/variables';

.editLegislationDrawer {
  width: 770px;

  &--formContainer {
    display: flex;
    flex-direction: column;
  }

  &--label {
    color: $adoptech-dark-grey-07;
    margin: 0px;
  }

  &--fieldRow {
    display: flex;
  }

  &--fieldRow:not(:first-of-type) {
    padding-top: 16px;
  }

  &--field {
    width: 100%;
  }

  &--field:not(:first-of-type) {
    padding-left: 20px;
  }
}
