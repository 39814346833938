@import '../../../styles/variables';

.auditsSummaryTile {
  display: flex;
  flex-direction: column;
  width: 100%;

  &--card-wrapper {
    padding: 20px;
  }

  &--close {
    margin-right: 10px;
  }

  &--column {
    margin-left: 10px;
    margin-right: 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .btn {
      margin-left: 10px;
    }

    &-inline {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: auto;
    }

    &:first-of-type {
      overflow: hidden;
      max-height: 150px;
    }
  }

  &--columnData {
    color: $adoptech-dark-grey-04;
    font-size: 18px;
    overflow: hidden;
  }

  &--columnHeader {
    color: $adoptech-dark-grey-07;
    font-size: 16px;
  }

  &--complete {
    margin-right: 10px;
  }

  &--completeIcon {
    margin-right: 10px;
  }

  &--info {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
  }

  &--mainContent {
    display: flex;
    height: 100%;
    margin: 10px;
  }

  &--preview {
    margin-right: 10px;
  }

  &--previewIcon {
    margin-right: 10px;
  }

  &--progressBar {
    background-color: $adoptech-orange-02;
    height: 10px;
  }
}
