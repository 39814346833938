@import '../../styles/variables';

.addPaymentMethodModal {
  &--label {
    color: $adoptech-dark-grey-07;
    font-size: 14px;
  }

  &--section {
    margin-bottom: 1rem;
  }

  &--row {
    display: flex;
    flex-direction: row;
    & > div {
      max-width: 50%;
      flex: 50%;
    }
  }
}
