@import '../../../styles/variables';

.policyOverview {
  .nextReviewDateHint {
    color: $adoptech-dark-grey-05;
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    top: 110%;
    left: 0px;
    font-size: 14px;
    svg {
      height: 14px;
      width: 14px;
    }
  }


  // remove dup with EditAgreementForm.scss:
  &--section-label {
    font-weight: bold;
    padding-top: 1rem;

    &:first-of-type {
      padding-top: 0;
    }
  }

  &--section-labels {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--section-labels:not(:first-child) {
    margin-top: 25px;
  }

  &--section-badge {
    background-color: $adoptech-orange-01;
    color: $adoptech-primary-white;
    padding: 3px 10px 3px 10px;
    font-size: 15px;
    border-radius: 9px;
  } 

  &--2-columns {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .adoptech-datepicker2,
    .adoptech-select2-container {
      flex: 0 0 calc(48%);
    }
  }
}
