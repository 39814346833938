@import '../../styles/variables';

.adoptechCheckbox {
  input[type='checkbox'] {
    display: none;

    &:checked + label::after {
      transform: rotate(45deg) scale(1);
    }
  }

  label {
    display: inline-block;
    position: relative;
    padding: 0 (20px + 20px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;

    &::before,
    &::after {
      background-color: $adoptech-dark-grey-07;
      position: absolute;
      content: '';
    }

    &::before {
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border: 2px solid $adoptech-dark-grey-07;
      border-radius: 12%;
    }

    &::after {
      top: 3px;
      left: 7px;
      width: 6px;
      height: 12px;
      border: solid $adoptech-primary-white;
      border-width: 0 3px 3px 0;
      transform: scale(0);
    }
  }

  &--disabled {
    label:hover {
      cursor: not-allowed;
    }
  }

  &--white-black-background {
    input:not(:checked) + label {
      &::before, &::after {
        border: 1px solid $adoptech-light-grey-01;
        background-color: $adoptech-primary-white;
      }
    }

    input:checked + label {
      &::before, &::after {
        background-color: $adoptech-dark-grey-05;
      }

      &::before {
        border: none;
      }
    }
  }

  &--white-background {
    label {
      &::before,
      &::after {
        background-color: $adoptech-primary-white;
        position: absolute;
        border: 1px solid #ccc;
        content: '';
      }

      &::after {
        border: solid $adoptech-dark-grey-07;
        border-width: 0 3px 3px 0;
      }
    }
  }

  &--small-label-padding {
    label {
      padding: 0 25px;
      &::before {
        top: 1px;
      }
    }
  }

  &--small {
    label::after {
      top: 4px;
      left: 6px;
      width: 5px;
    }

    label::before {
      top: 3px;
      width: 16px;
      height: 16px;
    }

    label {
      padding: 2px 25px;
    }
  }
}
