@import '../../styles/variables';

.iconButton {
  cursor: pointer;
  padding: 0 5px;
  font-size: 19px;

  &:focus {
    outline: none;
  }
}

.iconButton.iconButton--disabled {
  color: $adoptech-dark-grey-08;
  cursor: not-allowed;
}
