.editClientForm {

  &--title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  &--fields {
    display: flex;
    gap: 16px;
    flex-direction: column;
  }

  &--approvedBadge {
    display: flex;
    height: 20px;
    padding: 0px 8px;
    align-items: center;
    gap: 6px;
    border-radius: 10px;
    background: #EEFBE5;
    font-size: 12px;
    margin-top: 8px;
    font-size: 12px;
    justify-content: flex-start;
    width: fit-content;
  }
}
