@import '../../styles/variables';

.completedAssessmentsGridOut {
  margin-top: 16px;

  td:not(.completedAssessmentsGridOut--actions) {
    width: 15%;
  }

  &--nameHeader {
    width: 40%;
  }

  table {
    td.completedAssessmentsGridOut {
      &--cell {
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }
  }

  &--row {
    cursor: pointer;
  }
}
