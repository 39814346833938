@import '../../styles/variables';

.policyTour {
  display: flex;
  justify-content: center;

  .modal-dialog {
    max-width: 900px;
  }

  .modal-content {
    height: 665px;
  }

  &--title {
    color: $adoptech-dark-grey-04;
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 12px;
  }

  &--title,
  &--footer {
    padding: 0 40px;
  }

  &--text {
    color: $adoptech-dark-grey-05;
  }

  &--image {
    flex: 1;
    background-color: $adoptech-light-grey-07;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -40px;
    margin-right: -40px;

    img {
      width: 95%;
    }
  }
}
