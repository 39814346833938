@import '../../../styles/variables';
@import '../../../styles/StickyHeader.scss';

.sticky-header {
  @include stickyHeader(1);
  background-color: #F5F5F5;
}

.calendarHeader {
  $row-height: 32px;

  &--header {
    display: flex;
    align-items: center;
    gap: 16px;

    height: 80px;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  &--columnLabel {
    color: $adoptech-dark-grey-05;
    font-size: 14px;
  }

  &--divider {
    height: 53px;
    border-right: 1px solid $adoptech-light-grey-04;
  }
  &--viewModeSelector {
    width: 170px;
    font-size: 14px;
  }

  &--ownerSwitcher {
    height: 32px;
    width: 160px;
    border-radius: 4px;
    background:  #E8E8E8;
    font-size: 14px;
    display: flex;
    align-items: center;

    .active {
      transition: background 0.3s;
      background: white;
      border-radius: 4px;
      width: 82px;
      height: 26px;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    div:nth-of-type(2).active {
      width: 76px;
    }

    div:nth-of-type(1) {
      margin-left: 4px;
    }

    div {
      padding-left: 9px;
      padding-right: 9px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &--addColumn {
    margin-left: auto;
  }

  &--showCompletedColumn {
    height: 53px;
    font-size: 14px;
    min-width: fit-content;
    .calendarHeader--columnLabel {
      height: 21px;
    }

    .adoptechCheckbox {

      display: flex;
      align-items: center;
      height: 32px;

      label {
        padding: 0 0 0 33px;
      }

      input:not(:checked) + label {
        &::before, &::after {
          border: 1px solid $adoptech-light-grey-01;
          background-color: $adoptech-primary-white;
        }
      }

      input:checked + label {
        &::before, &::after {
          background-color: $adoptech-dark-grey-05;
        }

        &::before {
          border: none;
        }
      }
    }
  }

  &--startPeriodColumn, &--endPeriodColumn {
    font-size: 14px;
    width: 130px;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    label { display: none; }
    input#adoptechDatePicker--datePicker {
      height: $row-height;
      border-radius: 4px;
    }

    .adoptechDatePicker--dateContainer {
      height: $row-height;

      .react-datepicker-wrapper {
        border: none;
      }

      .adoptechDatePicker--selector {
        top: -26px;
        margin: 0 10px 0 0;
      }
    }

    input {
      background-color: $adoptech-primary-white;
    }
  }
}
