@import '../../styles/variables';

.enable2FAModal {
  .modal-dialog {
    max-width: 650px;
    width: 650px;
  }

  .modal-body {
    height: 170px;
  }

  .subheader {
    color: $adoptech-dark-grey-03;
    font-size: 14px;
  }

  &--infoMessage {
    color: $adoptech-dark-grey-05;
    font-size: 14px;
    text-align: center;

    &--error {
      color: $adoptech-red;
    }

    &--hidden {
      opacity: 0;
    }
  }
}
