@import '../../styles/variables';

.fileSelectAnswerGrid {
  margin-bottom: 20px;

  &--icon {
    > a {
      color: $adoptech-dark-grey-05;
    }
  }

  &--row {
    &-centered {
      text-align: center;
    }
  }
}

.adoptechTable .adoptechTable--wrapper .fileSelectAnswerGrid--icon {
  font-size: 20px;
}
