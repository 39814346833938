@import '../../../../styles/variables';
.trusthubSettingsHS {
  width: 720px;

  .adoptechHS--drawer--formContainer {
    padding-bottom: 0px;
  }
}

.trusthubSettingsDrawer {
  &--fieldRow {
    display: flex;
  }

  &--fieldLabel {
    padding-bottom: 8px;
  }

  &--fieldRow:not(:first-of-type) {
    padding-top: 16px;
  }

  &--field {
    width: 100%;
  }

  &--field:not(:first-of-type) {
    padding-left: 20px;
  }

  .adoptechTextInput2-error {
    border-radius: 4px;
  }
}
