@import '../../styles/variables';

.adoptechTextInput {
  color: $adoptech-dark-grey-05;
  display: flex;
  flex-direction: column;

  &.red-field { // used on sign-up flow with error badge
    background: $adoptech-red-background;
    color: $adoptech-red;

    input {
      background: $adoptech-red-background;
      color: $adoptech-red;
      border: none;

      &:focus {
        border: none;
      }
    }
    ::placeholder {
      color: $adoptech-red;
    }
  }

  &-error {
    font-size: 14px;
    color: $adoptech-red;
    margin-bottom: 2px;
  }

  input {
    height: 40px;
    color: $adoptech-dark-grey-05;

    &:focus {
      border: 1px solid $adoptech-dark-grey-08;
    }

    &.adoptechTextInput-error {
      background-color: $adoptech-red-background;
      border-color: $adoptech-red;
      color: $adoptech-red;
    }

    &.adoptechTextInput-large {
      height: 56px;
      font-size: 18px;
    }
  }

  &:hover,
  &:focus-within {
    svg {
      color: $adoptech-dark-grey-03;
    }
  }

  svg {
    font-size: 18px;
    color: $adoptech-dark-grey-03;
  }

  &-iconContainer {
    position: absolute;
    z-index: 1;
    display: flex;
    align-self: flex-end;
    align-items: center;
    height: 100%;
    padding-right: 10px;
  }

  &-search {
    input {
      background-color: $adoptech-primary-white;
      border-color: $adoptech-light-grey-01;
      border-radius: 4px;
      padding: 8px 17px;
      font-size: 14px;
      height: 32px;

      &:hover,
      &:focus {
        border-color: $adoptech-dark-grey-07;
      }
    }

    svg {
      color: $adoptech-light-grey-01;
    }

    &:hover,
    &:focus-within {
      svg {
        color: $adoptech-dark-grey-07;
      }
    }
  }

  &Container {
    &-withIcon {
      position: relative;
      input {
        padding-right: 30px;
      }
    }
  }

  label {
    font-size: 14px;
    color: $adoptech-dark-grey-07;
    margin-bottom: 2px;
    display: block;
  }

  &-suggestions {
    margin-top: 10px;
    background-color: $adoptech-light-grey-07;
    border: 1px solid $adoptech-dark-grey-08;
    border-radius: 4px;
    position: absolute;
    width: 665px;
    z-index: 2;
  }

  &-suggestion {
    cursor: pointer;
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 6px;
  }

  &-suggestion:hover {
    background-color: $adoptech-light-grey-03;
  }

  &.white-small input {
    background: white;
    height: 32px;
    border-radius: 4px;
  }
}

.error-badge { // used on sign-up flow
  margin-top: 12px;
  display: flex;
  align-items: center;
  width: max-content;
  padding: 4px 8px;
  gap: 16px;
  height: 30px;
  background: $adoptech-red-05;
  border-radius: 8px;
  font-size: 12px;
  color: $adoptech-red-06;
  margin-bottom: 12px;
}
