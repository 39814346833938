@import '../../styles/variables';

.assessmentSummary {
  padding-top: 30px;

  &--summary {
    display: flex;
  }

  &--back {
    color: $adoptech-primary-orange;
    cursor: pointer;
    font-size: 32px;
    padding-right: 18px;

    @include media-breakpoint-up(xxl) {
      margin-left: -31px;
    }
  }

  &--summaryTile {
    width: 100%;
  }

  &--tabs {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    color: $adoptech-dark-grey-05;

    &--tab {
      align-items: center;
      display: flex;
      align-items: flex-start;
      padding: 8px 20px 0;
      position: relative;
      font-size: 16px;
      cursor: pointer;

      &.selected {
        &:hover {
          cursor: default;
        }
      }

      &.selected &-text {
        &::after {
          border-bottom: 4px solid $adoptech-dark-grey-06;
          content: '';
          display: block;
          margin: 0 auto;
          padding-top: 4px;
          width: 100%;
        }
      }

      &-lozenge {
        margin-top: -7px;
      }
    }
  }
}
