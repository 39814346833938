@import '../../styles/variables';

.assessmentsTemplates {
  margin-top: 2rem;
  margin-bottom: 48px;

  &--title {
    color: $adoptech-dark-grey-05;
    font-size: 24px;
    padding-right: 30px;
  }

  &--header {
    display: flex;
    flex-wrap: wrap;
  }

  &--tiles {
    display: flex;
    flex-wrap: wrap;
  }
}
