@import '../../styles/variables';

.dropZone,
.dropZoneFiles {
  &--icon {
    color: $adoptech-light-grey-05;
    font-size: 20px;
    max-height: 100px;
  }

  &--preview {
    max-height: 50px;
    width: auto;
  }

  &--prompt,
  &--fileDescription,
  &--fileName,
  &--fileSize {
    color: $adoptech-dark-grey-05;
    font-size: 13px;
  }

  &--file {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
  }
}

.dropZone {
  height: 100%;

  &--hitArea {
    align-items: center;
    background-color: $adoptech-light-grey-07;
    border: 1px $adoptech-light-grey-04 dashed;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 20px;
    border-radius: 3px;
    min-height: 180px;

    &-smallHeight {
      min-height: auto;
    }

    &-dragging,
    &:hover {
      background-color: $adoptech-orange-03;
      border: 1px solid $adoptech-orange-01;

      .dropZone--icon {
        color: $adoptech-orange-01;
      }
    }
    &-dotted {
      background-color: white;
      border: 1px dashed #ccc;
    }
  }

  &--label {
    color: $adoptech-dark-grey-07;
    font-size: 14px;
  }

  &--prompt {
    margin-top: 1rem;
    max-width: 400px;
    text-align: center;
  }

  &--error {
    @extend .dropZone--prompt;
    color: $adoptech-red;
    white-space: pre-line;
    margin: 0;
  }

  img {
    max-width: 100%;
  }
}

.dropZoneFiles {
  &--removeIcon {
    margin-left: auto;
    cursor: pointer;
  }

  &--preview {
    margin-left: auto;
    cursor: pointer;
  }

  &--fileIcon {
    padding-right: 10px;
  }

  &--file {
    align-items: start;
    padding-top: 4px;
  }

  &--fileName {
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: center;
  }

  &--placeholder {
    margin-top: 16px;
    color: #666665;
    font-size: 14px;
  }
}
