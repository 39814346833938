@import '../../../styles/variables';

.agreementQuestion {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $adoptech-light-grey-04;
  padding: 20px;
  padding-bottom: 10px;
  transition: opacity 1s;

  &--disabled {
    opacity: 0.3;

    & * {
      pointer-events: none;
    }
  }

  &--answerArea {
    font-size: 16px;
    padding-bottom: 10px;

    .checkboxesAnswer--boxes .adoptechCheckbox {
      margin: 10px 0;
    }

    .adoptechRadioButton {
      margin: 10px 0;
    }
  }

  &--explanatoryText {
    color: $adoptech-dark-grey-05;
    font-size: 14px;
    margin-bottom: 1rem;
  }

  &--explanatoryText > span p {
    margin: 0;
  }

  & .lastUpdatedInfo {
    font-size: 11px;
    height: 20px;

    &--at,
    &--by {
      font-size: 12px;
      margin-left: 12px;
    }
  }

  &--questionText {
    color: $adoptech-dark-grey-03;
    font-size: 16px;
    font-weight: bold;

    p {
      margin-bottom: 8px;
    }
  }

  // answers input full width
  .textBasedAnswer input {
    width: 100%;
    height: 32px;
    font-size: 14px;
  }
}
