@import '../../../../styles/variables';
.pageTabs {
  margin-top: 16px;
  font-size: 16px;
  padding: 8px 4px 0px 0px;

  display: flex;
  gap: 16px;
  color: #333335;
  border-bottom: 1px solid #ddd;
  margin-bottom: 8px;

  &--tab {
    cursor: pointer;
    opacity: 0.6;
    &:hover, &.active {
      opacity: 1;
      color: #444445;
      font-weight: bold;
      border-bottom: 3px solid $adoptech-orange;
    }
  }
}


.pageTabs--actions{
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}
