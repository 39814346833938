@import '../../../styles/variables';

.testResult--payload {
  pre {
    padding: 10px;
    background-color: $adoptech-light-grey-07;
    border: 1px solid $adoptech-light-grey-04;
    border-radius: 2px;
  }
}

.testResult--comment {
  padding-bottom: 10px;
}
