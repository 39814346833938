.fileSelectAnswer {
  margin-top: 20px;

  &--buttons {
    margin-bottom: 20px;

    .adoptechButton {
      margin-right: 20px;
    }
  }
}
