@import './../../../styles/variables';

.suppliersPage {
  &--tableHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &--filterButtons, &--actionsButtons {
    display: flex;
    gap: 8px;
    justify-content: space-between;
  }

  .adoptechFlexTable {
    .adoptechGridTable--header,
    .adoptechGridTable--row {
      grid-template-columns: 200px 150px 200px repeat(4, 200px);
    }

    // outdated because we don't use drag to scroll
    // .adoptechGridTable--divider {
    //   left: 150px;
    // }
  }
}


