.trustHubUserAdminDocumentPermissions {
  &--panel {
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 14px;
  }

  &--panelInfo {
    font-size: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &--panelTitle {
    display: flex;
    justify-content: space-between;

    .adoptechButtonContainer .btn.adoptechButton-primaryOrange {
      border-radius: 4px;
    }
  }

  &--4-columns {
    height: 250px;
    overflow-y: auto;
  }

  &--tableRow, &--tableHeader {
    display: grid;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 8px;
    grid-template-columns: 10fr 7fr 7fr 2fr;
  }

  &--panelTable {
    margin-top: 24px;
  }

  &--tableRow {
    font-size: 14px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.20);
    height: 38px;
    align-items: center;

  }

  &--action {
    &:hover {
      cursor: pointer;
    }
  }

  &--name {
    text-decoration: underline;
  }
}
