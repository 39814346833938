@import '../../styles/variables';

.completeChecklists {
  padding-top: 2rem;

  &--row {
    cursor: pointer;
  }

  &--header {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 2fr;
  }

  &--title {
    align-items: center;
    color: $adoptech-dark-grey-05;
    display: inline-flex;
    font-size: 24px;
    padding-right: 30px;
  }

  &--table--cell {
    width: 20%;
  }

  &--table--cell--username {
    width: 23%;
  }
}
