@import '../../../../styles/variables';
@import '../../mixins/TrustHubPage.scss';

.trusthubUserPage {
  @include TrustHubPage();

  &--header {
    @include TrustHubPageHeader()
  }
}

