@import '../../styles/variables';

.resendAssessmentConfirmationModal {
  &--instructions {
    color: $adoptech-dark-grey-03;
    font-size: 15px;
  }

  &--vendorName {
    color: $adoptech-dark-grey-04;
    font-size: 18px;
    margin-top: 12px;
  }

  &--email {
    color: $adoptech-dark-grey-03;
    font-size: 20px;
    margin-top: 12px;
  }

  &--label {
    color: $adoptech-dark-grey-05;
    font-size: 14px;
    margin-top: 16px;
  }
}
