@import '../../styles/variables';

.outgoingAssessmentHeader {
  width: 100%;

  &--label {
    color: $adoptech-dark-grey-07;
    font-size: 14px;
  }

  &--name {
    color: $adoptech-dark-grey-04;
    font-size: 18px;
  }

  &--panel {
    display:flex;
    justify-content: space-between;
    padding: 1rem;
  }

  &--rhs {
    align-self: center;
  }
}