@import '../../../styles/variables.scss';

.policyTiles {
  margin-bottom: 30px;

  &--title {
    align-items: center;
    color: $adoptech-dark-grey-05;
    display: inline-flex;
    font-size: 24px;
    padding-right: 30px;
    margin-bottom: 1rem;
  }

  @include media-breakpoint-down(xl) {
    & > .d-flex {
      flex-wrap: wrap;
    }
  }
}
