@import '../../../styles/variables';

.auditsEditForm {
  &--label {
    color: $adoptech-dark-grey-07;
    margin: 0;
  }

  &--fieldRow {
    align-items: baseline;
  }

  &--fieldRow:not(:first-of-type) {
    margin-top: 16px;
  }
}