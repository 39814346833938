@import 'app/javascript/src/styles/variables';

.userBadge {
  height: 38px;
  position: relative;
  width: 38px;

  :hover {
    cursor: pointer;

    .profileMenu {
      display: inline-block;
    }
  }

  &--circle {
    align-items: center;
    color: $adoptech-dark-grey-05;
    display: flex;
    font-size: 30px;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;

    &-hovered {
      color: $adoptech-dark-grey-07;
    }
  }

  &--initials {
    align-items: center;
    color: $adoptech-light-grey-05;
    display: flex;
    font-size: 14px;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;

    &-hovered {
      color: $adoptech-dark-grey-01;
    }
  }
}
