@import '../../../styles/variables';

.calendarHS {
  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--counter {
    .lozenge {
      background: $adoptech-light-grey-01;
    }
    font-size: 20px;
  }
}
