@import '../../styles/variables';

.confirmSendInvitationModal {
  .modal-dialog {
    width: 460px;
  }

  &--emailAddress {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}
