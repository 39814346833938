@import '../../../styles/variables';
@import './CalendarPageTable.scss';
@import '../../../styles/StickyHeader.scss';
@import '../../../styles/mixins/DropdownMenuItem.scss';

.calendarPage {
  @include dropdownMenuItems($adoptech-primary-orange, $adoptech-orange-09);

  &--header {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    &.scrolling {
      padding-bottom: 20px;
    }
    @include stickyHeader(2);
  }
  &--date-filter {
    font-size: 24px;
    display: flex;

    img {
      cursor: pointer;
    }
  }

  &--date-filter-text {
    font-size: 24px;
    margin-left: 16px;
    margin-right: 16px;
    color: $adoptech-dark-grey-05;
    text-align: center;
    width: 180px;
  }
  &--headerButtons {
    display: flex;
  }

  .adoptechButtonContainer .btn.adoptechButton-white {
    color: $adoptech-dark-grey-05;
  }
  &--filters-btn {
    margin-right: 16px;
  }

  .adoptechTextInput-search {
    margin-bottom: 16px;

    .adoptechTextInput-iconContainer {
      z-index: initial;
    }
  }

  .sortableTableHeader:first-of-type {
    .sortableTableHeader--label {
      margin-left: 22px;
    }
  }

  &--monthName {
    margin-left: 10px;
  }
}
