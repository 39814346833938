@import '../../styles/variables.scss';

.documentLibraryModal {
  .modal-content {
    height: 90vh;
    overflow: auto;
  }

  .modal-dialog {
    max-width: 95%;
    @include media-breakpoint-up(lg) {
      max-width: 80%;
    }
  }

  .modal-body {
    padding: 0;
    overflow: auto;
  }

  .modal-header {
    background-color: $adoptech-light-grey-06-02;
    color: $adoptech-dark-grey-05;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }

  .modal-footer {
    border-top: 1px solid #DDD;
  }

  &--tabs {
    background-color: $adoptech-light-grey-06-02;
    color: $adoptech-dark-grey-05;
    display: flex;
    font-weight: normal;
  }

  &--tab {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 8px 20px 0;
    position: relative;
    font-size: 16px;
    cursor: pointer;

    &.selected {
      &::after {
        border-bottom: 4px solid $adoptech-dark-grey-05;
        content: '';
        display: block;
        margin: 0 auto;
        padding-top: 2px;
        width: 100%;
        cursor: default;
      }

      &:hover {
        cursor: default;
        background-color: $adoptech-light-grey-05;
        color: $adoptech-dark-grey-05;
      }

      &:not(.selected) {
        color: $adoptech-dark-grey-07;
      }
    }

    &:hover {
      background-color: $adoptech-light-grey-03;
      color: $adoptech-dark-grey-07;
    }
  }
}
