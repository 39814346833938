.contractsPage {
  &--tableHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &--filterButtons, &--actionsButtons {
    display: flex;
    gap: 8px;
    justify-content: space-between;
  }

  .adoptechFlexTable {
    .adoptechGridTable--header,
    .adoptechGridTable--row {
      grid-template-columns: 150px 150px 400px repeat(4, 200px);
    }
  }
}
