@import '../../../styles/variables.scss';

.addNewPolicy {
  color: $adoptech-dark-grey-05;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  &--policyBoxContainer {
    padding: 20px 30px 30px;
    border-bottom: 1px solid $adoptech-light-grey-01;
    overflow: auto;
    flex: 1;
  }

  &--policyBoxHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
  }

  &--policyBoxHeader:not(:first-of-type) {
    margin-top: 32px;
  }

  &--greenTick {
    font-size: 20px;
    color: $adoptech-green;

    &.absolute {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  &--policyBoxes {
    display: grid;
    grid-gap: 24px 12px;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    grid-auto-rows: min-content;
  }

  &--policyItem {
    padding: 30px 16px 16px;
    height: 196px;
    cursor: pointer;
    box-shadow: 0px 4px 12px $adoptech-box-shadow-light;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    position: relative;

    &.disabled {
      cursor: initial;
      background-color: $adoptech-light-grey-06;
    }
  }

  &--policyItemCategoryName {
    color: $adoptech-dark-grey-07;
    font-size: 11px;
  }

  &--policyItemName {
    color: $adoptech-dark-grey-05;
    font-size: 16px;
    font-weight: bold;
    flex: 1;
  }

  &--policySelectButton {
    text-align: center;
    height: 32px;

    .btn.adoptechButton {
      padding: 0;
      border-radius: 4px;

      &:hover {
        background-color: $adoptech-light-grey-07;
        color: $adoptech-dark-grey-05;
        border: 1px solid $adoptech-light-grey-01;
      }
    }
  }

  &--buttons {
    display: flex;
    justify-content: space-between;
    padding: 40px 30px 30px;
  }

  &--existingPolicyText {
    font-size: 18px;
  }
}

.policyDescriptionModal {
  .modal-content {
    height: 90vh;
    overflow: auto;
  }

  .modal-dialog {
    max-width: 95%;
    @include media-breakpoint-up(lg) {
      max-width: 80%;
    }
  }

  .modal-body {
    padding: 30px;
    border-bottom: 1px solid $adoptech-light-grey-01;
    border-top: 1px solid $adoptech-light-grey-01;
    overflow: auto;
  }

  .modal-header {
    padding: 20px 40px 25px;
  }

  &--title {
    font-size: 30px;
    font-weight: 300;
    color: $adoptech-dark-grey-05;
  }

  &--descriptionTitle {
    font-size: 18px;
    padding-bottom: 10px;
    font-weight: 600;
    color: $adoptech-dark-grey-05;
  }

  &--summaryAsHtml {
    font-size: 16px;
  }

  &--descriptionAsHtml {
    font-size: 14px;
  }
}
