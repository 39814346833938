@import '../../styles/variables.scss';

.documentLibraryDescriptionModal {
  .modal-content {
    height: 90vh;
    overflow: auto;
  }

  .modal-dialog {
    max-width: 95%;
    @include media-breakpoint-up(lg) {
      max-width: 80%;
    }
  }

  .modal-body {
    padding: 30px;
    border-bottom: 1px solid $adoptech-light-grey-01;
    border-top: 1px solid $adoptech-light-grey-01;
    overflow: auto;
  }

  .modal-header {
    padding: 20px 40px 25px;
  }

  &--title {
    font-size: 30px;
    font-weight: 300;
    color: $adoptech-dark-grey-05;
  }

  &--descriptionTitle {
    font-size: 18px;
    padding-bottom: 10px;
    font-weight: 600;
    color: $adoptech-dark-grey-05;
  }

  &--summaryAsHtml {
    font-size: 16px;
  }

  &--descriptionAsHtml {
    font-size: 14px;
  }
}
