@import '../../styles/variables';

.toggleSwitch {
  align-items: center;
  display: flex;
  padding: 12px 0;

  &--label {
    color: $adoptech-dark-grey-07;
    cursor: pointer;

    &:nth-child(1) {
      margin-right: 10px;
    }

    &:nth-child(3) {
      margin-left: 10px;
    }


    &-disabled {
      color: $adoptech-light-grey-01;
      cursor: not-allowed;
    }
  }

  &--switch {
    align-items: center;
    display: flex;
  }
}
