@import '../../styles/variables';

.vendorRegisterRow {
  &--name {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &--owner {
    display: flex;
    .userAvatar--circle {
      margin: 0 9px;
    }
  }
  &--status {
    text-align: center;
  }
  &--greenStatus {
    color: $adoptech-green-03;
    font-size: 20px;
  }
  &--greyStatus {
    color: $adoptech-light-grey-01;
    font-size: 20px;
  }
  &--statusText {
    color: $adoptech-dark-grey-05;
    font-size: 12px;
    line-height: 14px;
  }
  &--overdueAlert {
    font-weight: bold;
    color: $adoptech-red-06;
    .fa-clock {
      margin-right: 6px;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
}
