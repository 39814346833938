@import '../../../styles/variables';

.agreementClauseQuestions {
  &--footer {
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 15px;
    width: 100%;
    height: 58px;
  }

  &--options {
    margin-left: 20px;
  }
}
