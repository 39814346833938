@import './../../../../../styles/variables';

.adoptechHS--drawer.complianceEditTaskDrawer {
  width: 770px;
  background-color: #f5f5f5;
  overflow-y: auto;

  &.adoptechDrawer--open.onTop {
    z-index: 556;
  }
}

