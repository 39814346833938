@import '../../../styles/variables';

$sectionFontSize: 14px;
$sectionPadding: 10px;
$headerColor:  #666665;

.policyReadRequestsSection {
  &--requestsHeader {
    cursor: pointer;
    padding-top: $sectionPadding;
    padding-bottom: $sectionPadding;


    font-size: $sectionFontSize;
  }

  &--section-description {
    padding-bottom: $sectionPadding;
    font-size: $sectionFontSize;
  }
}

.policyTeamsSelector {
  font-size: $sectionFontSize;
  &--info {
    padding-top: $sectionPadding;
    padding-bottom: $sectionPadding;
    display: flex;
  }

  .lozenge {
    font-size: 14px;
    &:first-of-type {
      margin-left: 0px;
    }
  }

  &--selectedTeams {
    padding-bottom: $sectionPadding;
  }

  &--subHeader {
    font-size: $sectionFontSize;
  }
}
