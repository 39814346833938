@import '../../../styles/variables';

.policyVersionSection {
  $headerColor:  #666665;
  $sectionFontSize: 14px;
  font-size: 14px;
  &--subHeader {
    font-size: $sectionFontSize;
  }
  &--versionNumber, &--liveDate, &--approvedBy {
    display: flex;
    padding-bottom: 10px;
  }
}
