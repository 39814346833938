@import '../../../styles/variables';
@import '../../../styles/mixins/DropdownMenuItem.scss';

.reportsHeader {
  box-shadow: 1px 1px 5px #d8d8d8;
  border-radius: 4px;
  &--header {
    display: flex;
    align-items: center;
    gap: 16px;

    flex-wrap: wrap;
    min-height: 90px;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  &--titleColumn {
    .lozenge {
      margin-left: 0px;
      width: fit-content;
      background-color: $adoptech-dark-grey-08;
    }
  }

  &--titleColumn, &--filterByTypeColumn {
    font-size: 14px;
    color: #666665;
    display: flex;
    column-gap: 8px;
    white-space: nowrap;
    align-items: center;
    label { display: none; }

    .react-select, .adoptechDatePicker--dateContainer {
      height: 30px;
    }

    .adoptechReactSelect {
      min-width: 200px;
    }

    .react-datepicker-wrapper {
      border: none;
    }

    input {
      background-color: $adoptech-primary-white;
    }
  }

  &--search {
    flex-grow: 1;

    .adoptechTextInput-search input:hover, .adoptechTextInput-search input:focus {
      border-color: #ccc;
    }
  }

  &--divider {
    height: 100%;
    border-right: 1px solid $adoptech-light-grey-04;
  }

  &--addButton {
    width: 136px;
    // make button width eq to dropdown width
    .adoptechButtonContainer, .adoptechButton {
      width: 100%;
    }
    @include dropdownMenuItems($adoptech-primary-orange, $adoptech-orange-09);

    .dropdown-item {
      padding: 0.25rem 16px;
    }
  }
}
