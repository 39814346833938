@import './../../styles/mixins/AdoptechInputGreyBackgrund';

.adoptechColourInput {
  @include AdoptechInputGreyBackground;

  &--wrapper {
    display: flex;
    gap: 32px;
  }

  &--listWrapper {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .fa-pencil {
    cursor: pointer;
  }

  &.hasError {
    border: 1px solid #e55f4e;
  }


  .adoptechTextInput2 {
    border-radius: 4px;
    height: 60px;
  }

  .adoptechTextInputContainer {
    width: 68px;
    height: 24px;
    background: white;

    #colour-input {
      background: white;
      border-radius: 4px;
      border: 1px solid #D2D2D2;
      padding: 3px;
    }
  }
}
