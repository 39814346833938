@import '../../styles/variables.scss';

.assessmentSummaryExportModal {
  .modal-dialog {
    max-width: 600px;
    width: 600px;

    .modal-body {
      padding: 0;
    }
  }

  &--subheader {
    font-weight: 400;
    font-size: 16px;
    padding: 0 40px;
  }

  label {
    font-size: 14px;
    color: $adoptech-dark-grey-07;
    margin-bottom: 2px;
    display: block;
  }

  &--table {
    max-height: 40vh;
    overflow: auto;
  }

  &--selectTitleRow {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 40px;
  }

  &--progress {
    height: 46px;
    padding: 30px 40px 0;

    .progress-bar {
      background-color: $adoptech-primary-orange;
    }
  }
}
