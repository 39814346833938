@import './../mixins/TrustHubPage';

.trusthub-top-bar {
  @include TrustHubPageHeader();

  height: 96px;
  background: #F2F2F2;
  padding: 32px 80px;

  &--backSectionLogo img {
    max-height: 42px;
  }

  &--info {
    justify-content: space-between;
    gap: initial;
    width: 100%;
  }
}
