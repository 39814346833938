@import '../../styles/variables';

.enable2FAForm {
  &--error {
    color: $adoptech-red;
    display: flex;
    justify-content: center;

    &-hidden {
      display: none;
    }
  }

  &--field {
    width: 100%;

    input {
      border: 1px solid $adoptech-light-grey-04;
      background-color: $adoptech-light-grey-07;
      color: $adoptech-dark-grey-05;
      font-size: 18px;
      height: 44px;
      padding-left: 10px;
      width: 100%;

      &:focus {
        outline: 1px solid $adoptech-dark-grey-08;
      }

      &::placeholder {
        color: $adoptech-dark-grey-09;
      }
    }

    &:nth-of-type(2) {
      padding-left: 20px;
    }
  }

  &--fieldRow {
    display: flex;
    padding-bottom: 16px;
  }

  &--fields {
    flex-grow: 1;
  }

  &--label {
    color: $adoptech-dark-grey-07;
    font-size: 14px;
    margin-bottom: 7px;
    margin-top: 14px;
  }
}
