@import '../../../styles/variables';
@import '../../../styles/mixins/DropdownMenuItem.scss';

.vendorRegister {
  &--sticky {
    position: sticky;
    z-index: 2;
    top: 0;
    background-color: $adoptech-light-grey-10;
    padding-top: 16px;
  }

  &--divider {
    height: 38px;
    border-right: 1px solid $adoptech-light-grey-04;
  }

  &--header {
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 16px;
    background-color: $adoptech-primary-white;
    grid-template-columns: $adoptech-header-grid-template-columns;
    margin-bottom: 16px;
    border-radius: 8px;
  }

  .checkbox-show-inactive label {
    padding: 0 0 0 28px;
    align-items: center;
    color: $adoptech-dark-grey-05;
    font-size: 15px;
  }

  .adoptechGridTable--header {
    position: sticky;
    z-index: 2;
    top: 150px;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #ffffff 50%);
    padding: 0 16px 8px;
    .sortableTableHeader:first-of-type {
      right: auto;
    }
  }

  &--title {
    display: inline-flex;
    align-items: center;
    color: $adoptech-dark-grey-05;
    font-size: 16px;

    .lozenge {
      background-color: $adoptech-dark-grey-08;
      font-size: 14px;
      padding: 0 12px;
    }
  }

  &--headerButtons {
    display: flex;
    gap: 10px;
    margin-left: auto;
  }

  &--reportsButton {
    @include dropdownMenuItems($adoptech-dark-grey-05, $adoptech-dark-grey-08);
  }

  &--addButton {
    @include dropdownMenuItems($adoptech-primary-orange, $adoptech-orange-09);
  }

  &--search {
    padding: 16px 16px 8px;
    background-color: $adoptech-primary-white;
    border-radius: 8px 8px 0 0;
  }

  &--list {
    padding: 0 16px 16px;
    background: $adoptech-primary-white;
    border-radius: 0 0 8px 8px;
  }

  &--7-columns {
    .adoptechGridTable--header,
    .adoptechGridTable--row {
      grid-template-columns: 8fr 3fr 3fr 2fr 3fr 3fr 2fr;
    }
    .adoptechGridTable--row:hover {
      background-color: $adoptech-light-grey-09;
    }
    .adoptechGridTable--row.adoptechGridTable--alert:hover {
      background-color: $adoptech-red-07;
    }

    .sortableTableHeader {
      .sortableTableHeader--icon {
        margin-left: 5px;
      }
      &:nth-of-type(7) {
        display: flex;
        justify-content: space-evenly;
        .sortableTableHeader--header {
          padding-left: 16px;
        }
      }
    }
  }

  .adoptechGridTable--row > div {
    padding: 8px 0;
  }

  .meatballMenu--button .iconButton {
    font-size: 25px;
  }
}
