@import '../../styles/variables.scss';

.sentAttestationsTable {
  .adoptechTable-modal .adoptechTable--wrapper {
    .policyNameField { // uses AdoptechOverflowLine
      max-width: 270px;
      width: 270px;
    }

    .emailField {
      max-width: 270px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .attestedStatus {
      display: flex;
      align-items: center;
    }
  }

  &--noDataText {
    display: flex;
    justify-content: center;
  }

  .adoptechTable {
    max-height: 50vh;
    overflow: auto;

    .table-responsive {
      overflow-x: visible;
    }

    thead {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: #f2f2f2;
    }
  }
}
