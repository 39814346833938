@import '../../../styles/variables';
@import './ClausesList.scss';

.agreementEditor {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 30px;

  &--back {
    color: $adoptech-primary-orange;
    cursor: pointer;

    display: flex;
    align-items: center;
    font-size: 4rem;
  }

  &--footer {
    margin-top: 16px;

    .panel {
      width: 100%;
    }
  }

  &--signatories {
    font-size: 16px;
    margin-top: 16px;
  }

  &--signatory {
    margin-bottom: 8px;
  }

  &--signatoriesTiles {
    display: flex;
    flex-direction: row;

    div & > * {
      &:last-child {
        margin-right: 0;
      }

      height: 220px;
      margin-right: 18px;
    }
  }

  &--spinner {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  &--summary {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 13;
    margin-left: -10px;
    padding-left: 10px;
    margin-right: -10px;
    padding-right: 10px;
  }

  &--summaryTile {
    width: 100%;
    margin-bottom: 18px;
    background-color: $adoptech-primary-white;
  }

  &--lockedModal {
    .modal-content {
      height: fit-content;
      min-width: 300px;
    }
  }

  &--header {
    display: flex;
    padding: 20px 20px 4px;
    color: $adoptech-dark-grey-07;
    font-size: 14px;
    font-weight: 400;
  }

  @include clausesList();

  .adoptechAccordionCard {
    box-shadow: none;
    margin-bottom: 16px;

    .card-header {
      height: 45px;
      box-shadow: 1px 1px 3px $adoptech-light-grey-03;
    }

    .card, .card.expanded {
      box-shadow: 1px 1px 4px rgb(0 0 0 / 25%);
    }
  }
}
