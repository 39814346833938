@import '../../../styles/variables';

.frameworkRequirementCategories {
  .adoptechAccordionCard {
    margin-bottom: 10px;
  }

  .card.expanded {
    background: transparent;
    box-shadow: none;
    margin-bottom: 0;
    margin-top: 0;
  }

  .card-header {
    height: 48px;
    font-size: 16px;
    border-radius: 8px;
    box-shadow: 0 0 8px $adoptech-light-grey-03;
  }

  .card-body {
    font-size: 16px;
  }

  &--categoryHeader {
    display: flex;
    width: 100%;
    align-items: center;
    div:last-of-type {
      margin-left: auto;
    }
  }

  &--categoryCompleted {
    margin-right: 32px;
  }

  .frameworkRequirementCategories--category-body.card-body {
    margin-top: 10px;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 0 8px $adoptech-light-grey-03;
    .card {
      background: transparent;
      .fa-angle-down {
        font-size: 24px;
      }
      .card-header, .card-body {
        border: none;
        box-shadow: none;
        background: transparent;
      }
      .card-body {
        padding: 0 0 28px 56px;
      }
    }
    .card.expanded, .card:hover {
      background-color: $adoptech-light-grey-08;
    }
  }

  &--header {
    [class$='--greenTick']:hover {
      color: $adoptech-green-03;
    }
  }

  &--greenTick {
    color: $adoptech-green;
  }

  &--greyTick {
    color: $adoptech-light-grey-04
  }

  &--title {
    padding: 0 16px;
    font-weight: 700;
  }

  &--actions {
    margin-top: 16px;
    display: flex;
    gap: 16px;
  }
}
