.addSupplierManualScreen {
  .modal-dialog {
    min-width: 720px;
  }

  &--modes {
    display: flex;
    gap: 24px;
    margin-top: 24px;
  }

  &--mode {
    cursor: pointer;
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid #D2D2D2;
    background: #FFF;

    &.active {
      border: 2px solid #FF621E;
    }
  }

  &--modeIcon {
    border-radius: 44px;
    background: #FAFAFA;
    display: flex;
    width: 88px;
    height: 88px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    svg {
      width: 40px;
      height: 40px;
      color: #FF621E;
    }
  }

  &--modeTitle {
    color: #1C1E2E;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  &--modeText {
    text-align: justify;
  }
}
