@import '../../../styles/variables';

.frameworkGuidance {
  &--guidance {
    margin: 16px auto;
    padding: 16px;
    border: 1px solid $adoptech-light-grey-04;
    border-radius: 8px;
    background-color: $adoptech-primary-white;
    max-width: 66%;
    max-height: 380px;
    overflow-y: scroll;
  }
}
