@import '../../styles/variables';

.messageToast {
  position: fixed;
  right: calc(50% - 175px);
  top: calc(50% - 45px);
  width: 350px;
  z-index: 999;

  .toast {
    border: 0;
    transition: all 0.5s ease-out;

    &:not(.show) {
      transform: translateY(50px);
    }
  }

  .toast-body {
    background-color: $adoptech-primary-orange;
    color: $adoptech-primary-white;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    justify-content: center;
    min-height: 90px;
    padding: 0;
  }

  &-success {
    .toast-body {
      background-color: $adoptech-dark-green;
    }
  }

  &-warning {
    .toast-body {
      background-color: $adoptech-red;
    }
  }

  &-large {
    width: 500px;

    .toast-body {
      width: 500px;
    }
  }

  &--body {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
  }

  &--close {
    cursor: pointer;
    z-index: 1;
  }

  &--closeHeader {
    display: flex;
    justify-content: flex-end;
  }

  &--content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 20px 0;
    text-align: center;

    &-shiftUp {
      margin-top: -16px;
    }
  }
}
