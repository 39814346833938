@import '../../../styles/variables';
@import '~bootstrap/scss/bootstrap';

.legalRegister {
  &--padding-right-xs {
    padding-right: 0.25rem;
  }

  .adoptechGridTable--header,
  .adoptechGridTable--row {
    grid-template-columns: 32px 240px 16px 80px 8fr 1fr;
    column-gap: 56px;
    font-size: 14px;
    color: $adoptech-dark-grey-05;
    padding: 8px 16px;
    line-height: 18px;

    @include media-breakpoint-down(lg) {
      column-gap: 10px;
      grid-template-columns: 1fr 3fr 1fr 2fr 2fr;
    }
  }

  .adoptechGridTable--header {
    margin-bottom: 8px;
    padding-bottom: 0px;
  }
  &--summary, &--name {
    overflow: hidden;
    max-height: 100%;
  }
  .adoptechGridTable--row {
    &.disabled {
      cursor: not-allowed;
    }
    height: 48px;
  }

  .adoptechTable--actions {
    flex-direction: row-reverse;
  }

  .sortableTableHeader--icon {
    margin-left: 4px;
    width: 0.5rem;
  }

  .adoptechGridTable--row:hover {
    background-color: $adoptech-light-grey-09;
  }

  .adoptechGridTable--header .sortableTableHeader:first-of-type {
    right: 0rem;
  }

  &--searchRow {
    display: flex;
    align-items: center;
    input {
      height: 38px;
    }
    margin-bottom: 16px;

    .lozenge {
      width: fit-content;
      background-color: $adoptech-dark-grey-08;
      height: 24px;

      font-size: 14px;
      color: $adoptech-primary-white;
      display: flex;
      align-items: center;
    }

    .adoptechTextInput {
      flex-grow: 1;
      margin-left: 16px;

      input {
        margin-left: 0px;
      }
    }
  }

  &--registryTitle {
    display: flex;
  }
  .userAvatar--name {
    font-size: 14px;
    color: $adoptech-dark-grey-05;
  }
}
