@import '../../styles/variables';

.externalSignatoryForm {
  &--label + div {
    margin-top: 8px;
  }

  &--body {

    & > * {
      &:last-child {
        margin-bottom: 0;
      }
      margin-bottom: 8px;
    }

    margin-bottom: 40px;
  }

  &--form-error {
    display: block;
    margin-top: -8px;
    color: $adoptech-red;
    font-size: 14px;
  }

  &--description {
    margin-bottom: 20px;
  }

  &--controls {
    display: flex;
    justify-content: space-between;
  }
}
