@import '../../styles/variables.scss';

.approvalManagementModal {
  .modal-dialog {
    max-width: 720px;
    width: 720px;

    .modal-body {
      padding: 0;
    }
  }

  &--header {
    display: flex;
    flex-direction: column;
  }

  &--status {
    font-weight: 400;
    font-size: 16px;
    padding: 16px 0;
  }

  &--subHeader {
    font-weight: 400;
    font-size: 15px;
    margin-top: 5px;
  }

  &--title {
    font-size: 18px;
    font-weight: 400;
    margin-top: 15px;
  }

  label {
    font-size: 14px;
    color: $adoptech-dark-grey-07;
    margin-bottom: 2px;
    display: block;
  }

  /* TODO: fix stylelint */
  /* stylelint-disable */
  table.adoptechTable--wrapper {
    tbody {
      .adoptechCheckbox {
        label {
          color: $adoptech-dark-grey-03;
          font-size: 15px;
          padding-left: 40px;
        }
      }
    }
  }

  /* stylelint-enable */
  &--staffMembers {
    margin-bottom: 20px;
  }

  &--staffMembers label,
  &--selects,
  &--message {
    padding: 0 40px;
  }

  &--skip {
    display: flex;
    align-items: center;
  }

  &--skipLink {
    color: $adoptech-dark-grey-05;
    font-size: 14px;
    padding-left: 15px;
  }

  &--close {
    font-size: 26px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 7px;
  }
}
