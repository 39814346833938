@import '../../../styles/variables';

.riskRegistryLibrary {
  &--headerButtons {
    margin-left: auto;
  }

  &--list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  &--listHeader {
    font-weight: bold;
    font-size: 16px;
    margin: 16px 0px;
  }

  &--tileCounter {
    margin-left: auto;
    font-weight: normal;
    font-size: 16px;
  }

  .adoptechTile--large {
    @include media-breakpoint-up(xxl) {
      width: 268px;
    }
  }
}
