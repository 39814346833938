@import '../../styles/variables';

.progressBar {
  background-color: $adoptech-light-grey-06;
  height: 100%;

  &--progress {
    background-color: $adoptech-orange-01;

    &-full {
      background-color: $adoptech-green;
    }
  }

  &--radius {
    border-radius: 5px;
  }
}
