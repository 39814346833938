@import '../../styles/variables';

.checkboxesAnswer {
  &--boxes {
    color: $adoptech-dark-grey-05;
    display: flex;
    flex-direction: column;

    input {
      margin-right: 25px;
    }

    > *:not(:last-child) {
      margin-bottom: 12px;
    }

    .adoptechCheckbox {
      margin: 16px 0;
    }
  }

  &--selectAll {
    font-style: italic;
  }
}
