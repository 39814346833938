@import '../../styles/variables';
.react-tags {
  display: block;
  position: relative;
  padding: 0px 10px 0 10px;
  background-color: $adoptech-light-grey-07;
  border: 1px solid $adoptech-light-grey-04;
  border-radius: 2px;
  font-size: 1em;
  line-height: 1.2;
  cursor: text;
  align-items: center;
}

.react-tags.is-focused {
  border-color: $adoptech-dark-grey-08;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: flex;
  box-sizing: border-box;
  margin: 5px 6px 3px 0;
  padding: 4px 12px;
  border: none;
  border-radius: 14px;
  color: $adoptech-primary-white;
  background-color: $adoptech-dark-grey-07;
  font-size: inherit;
  line-height: inherit;

  &:focus {
    outline: none;
  }

  &:active {
    background-color: $adoptech-dark-grey-06;
  }

  &:hover {
    .react-tags__selected-tag:after {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

.react-tags__selected-tag:after {
  content: '\2715';
  font-size: 10px;
  background-color: $adoptech-light-grey-01;
  color: $adoptech-dark-grey-07;
  margin-left: 8px;
  margin-top: 1px;
  border-radius: 50%;
  padding: 3px 5px;
  font-weight: 600;
  display: inline-block;
}

.react-tags__selected-tag-name {
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-tags__search {
  display: inline-block;
  padding: 7px 2px;
  margin-bottom: 3px;
  margin-top: 3px;
  max-width: 100%;
}

.react-tags__search-input {
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 200;
  background-color: $adoptech-light-grey-07;
}

.react-tags__suggestions ul {
  width: 100%;
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background-color: $adoptech-light-grey-07;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  padding: 10px 12px;
  transition: background-color 0.1s;
}

.react-tags__suggestions li mark {
  padding: 0;
  font-weight: 600;
  background: none;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background-color: $adoptech-light-grey-05;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}
