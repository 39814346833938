@import '../../../styles/variables';

.auditsReviewHistoryDropdown {
  display: inline-block;

  .dropdown-menu {
    padding: 0;
  }

  &--noData {
    padding: 20px;
    min-width: 250px;
  }

  &--title {
    font-size: 20px;
    color: $adoptech-dark-grey-05;
    padding: 10px 20px;
    border-bottom: $adoptech-dark-grey-09 solid 1px;
  }

  &--result {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
  }

  &--date {
    font-weight: 200;
  }

  &--auditor-name {
    margin-right: 20px;
  }

  &--comments-title {
    font-weight: 200;
  }

  &--comments {
    margin: 5px 0;
    padding: 5px 10px;
    border: $adoptech-light-grey-01 solid 1px;
    border-radius: 5px;
    background-color: $adoptech-light-grey-07;
  }

  &--item.dropdown-item {
    font-size: 14px;
    color: $adoptech-dark-grey-05;
    padding: 10px 20px;
    border-bottom: $adoptech-dark-grey-09 solid 1px;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: $adoptech-light-grey-09;
    }
  }

  & > &--button.dropdown-toggle::after {
    display: none;
  }

  &.show > &--button.dropdown-toggle:active {
    outline: none;
    box-shadow: none;
    border-color: $adoptech-light-grey-01;
  }

  &.show > &--button.dropdown-toggle:focus {
    outline: none;
    box-shadow: none;
    border-color: $adoptech-light-grey-01;
  }

  &.show > &--button.dropdown-toggle {
    background-color: $adoptech-light-grey-02;
  }

  &.show > .dropdown-menu {
    box-shadow: $adoptech-box-shadow;
  }

  &.show > .dropdown-menu::before {
    background: white;
    content: '';
    height: 12px;
    position: absolute;
    transform: rotate(45deg);
    width: 12px;
  }

  &.show > .dropdown-menu[x-placement^=bottom]::before {
    top: -7px;
    border-left: $adoptech-light-grey-05 solid 1px;
    border-top: $adoptech-light-grey-03 solid 1px;
  }

  &.show > .dropdown-menu[x-placement^=top]::before {
    bottom: -7px;
    border-bottom: $adoptech-light-grey-05 solid 1px;
    border-right: $adoptech-light-grey-03 solid 1px;
  }

  &.show > .dropdown-menu[x-placement$=start]::before {
    left: 24px;
  }

  &.show > .dropdown-menu[x-placement$=end]::before {
    right: 24px;
  }
}
