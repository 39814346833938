@import '../../styles/variables';

.notification-item {
  align-items: center;
  font-size: 14px;
  padding: 0px 16px;

  &--content {
    display: flex;
    justify-content: space-between;
  }

  &--message {
    max-width: 80%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    font-size: 100%;
  }

  &--button {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  &--footer {
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    margin-top: 2px;
  }

  .adoptechButton.btn {
    border-radius: 50px;
    min-width: 50px;
    padding: 0px 16px;
    margin-left: 8px;
    font-size: 14px;
  }

  &.dropdown-item {
    white-space: normal;
    padding: 10px 20px;

    &:active {
      background-color: $adoptech-dark-grey-09;
    }

    &:hover {
      background-color: $adoptech-light-grey-09;
    }
  }
}
