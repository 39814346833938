@mixin DraggableBackground {
  border: 3px dashed #333333;
}

.controlsSection {
  &.editMode {
    margin-top: 32px;
  }

  &--tables {
    display: flex;
    gap: 32px;

    > * {
      flex: 1 0 0;
    }
  }

  &--tableHeader {
    color: #444445;
    font-size: 16px;
    padding-bottom: 16px;
    font-weight: 600;
  }

  &--tablePanel {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #CCC;
    background: #FAFAFA;

    flex-direction: column;
    gap: 16px;
  }

  &--tablePanelHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &--controlsTemplates, &--controlsCategories {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  &--control {
    background: #fff;

    &.selected {
      border: 1px solid #666;
    }

    &.disabled {
      opacity: 0.4;
      border: 1px solid #CCC;
      background: #fff;
      pointer-events: none;
    }
    .adoptechCheckbox {
      width: 25px;
      height: 20px;
    }

    &.hoverable {
      &:hover {
        background: linear-gradient(to right, #f2f2f2, #fff);
        cursor: pointer;
      }
    }

    &.draggable {
      cursor: grabbing;
      @include DraggableBackground;
    }
  }

  &--table {
    max-height: 800px;
    overflow-y: scroll;
  }

  .accordion .card-header {
    &.draggable {
      background: #333333;
      color: #FFFFFF;
      border-color: #333333;
      box-shadow: 0 0 10px 2px #333333;
    }
  }

  .fa-pencil {
    padding-left: 15px;

    &:hover {
      cursor: pointer;
    }
  };

  .fa-trash {
    color: red;
    margin-left: auto;

    &:hover {
      cursor: pointer;
    }
  }

  .controlDescriptionActions {
    margin-left: auto;
    display: flex;
    gap: 8px;
  }
}
