@import '../../styles/variables';

.intercomIcon {
  &--baseStyle {
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}
