@import '../../../../../styles/variables';

.trusthubUserAdminAddDocumentPermissionDrawer, .trusthubUserAdminAddDocumentsList {
  display: flex;
  flex-direction: column;

  &--label {
    margin-bottom: 16px;
  }

  .card {
    border: 1px solid $adoptech-light-grey-02;
    box-shadow: none;
  }

  .adoptechAccordionCard--angleIcon {
    display: none;
  }

  .select-doc {
    margin-left: 2.5rem;
  }

  .select-all-docs, .select-doc {
    margin-top: 0.5rem;
  }

  .adoptechCheckbox label {
    padding: 0 30px
  }
}
