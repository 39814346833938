@import '../../../styles/variables';

.paymentLandingPage {
  margin: 0px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 32px 3px 32px;
  gap: 16px;
  isolation: isolate;
  height: 100%;
  min-height: 563px;
  border-radius: 8px;

  &--title {
    font-weight: 700;
    font-size: 32px;
    line-height: 34px;
    margin-bottom: 16px;
    color: $adoptech-dark-grey-04;
  }
  &--description {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    color: $adoptech-dark-grey-05;
  }
  &--planInfo {
    font-weight: 400;
    font-size: 11px;
    line-height: 24px;
    color: $adoptech-dark-grey-06;
  }
  &--image {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: -40px;
    z-index: -1;
    /* height: 100%; */
    flex-direction: column-reverse;
    align-items: center;
    & > img {
      max-height: 400px;
    }
  }
  &--button {
    margin-top: -150px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    &--above-image {
      margin-top: 0px;
      align-items: flex-start;

      [class*='--planInfo'] {
        margin-top: 10px;
      }
    }

    .adoptechButtonContainer .btn.adoptechButton-primary:hover,
    .adoptechButtonContainer .btn.adoptechButton-primary {
      background-color: $adoptech-logo-orange;
    }
  }
}
