@import '../../../../../styles/variables.scss';
@import '../../mixins/FieldBlocks.scss';
@import './../../mixins/StatusField';


.complianceEditControlForm {
  @include FieldBlocks;
  overflow-y: auto;
  width: 100%;

  .adoptechTextInput2, .adoptech-select2-container, .adoptechTextAreaInternalLabel-container {
    background: white;
  }

  .adoptech-select2-container--label {
    @include FieldTitle;
    color: #212529;
  }

  .adoptechTextAreaInternalLabel label {
    @include FieldTitle;
    left: 0;
    font-size: 14px;
    padding-bottom: 10px;
  }

  .adoptech-select2-container {
    padding-left: 0;
  }

  .userAvatar--name__medium {
    font-size: 14px;
  }

  .adoptechTextAreaInternalLabel textarea {
    padding-left: 0;
    &:disabled {
      border-radius: 4px;
      border: 1px solid #ccc;
      padding: 16px;
      margin-top: 8px;
    }
  }

  label[for='control-treatment-plan'] {
    @include FieldTitle;
    font-size: 14px;
    left: 0;
  }

  &--control-header {
    @include FieldTitle;
    @include StatusField;
  }

  &--reviewRow {
    display: flex;
    gap: 8px;
    align-items: center;

    &:nth-of-type(2) {
      margin-top: 12px;
      margin-bottom: 16px;
    }
  }

  &--treatmentPlanHeader {
    display: flex;
    justify-content: space-between;
  }

  .treatmentPlanRow {
    display: flex;
    gap: 4px;
    align-items: center;

    svg {
      position: initial;
      padding-bottom: 10px;
      color: #999995;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .adoptechButtonContainer .btn.adoptechButton {
    &-disabled, &:disabled {
      cursor: not-allowed;
    }
  }

  &--field {
    width: 100%;

    .adoptechButton--content {
      font-size: 14px;
    }

    .adoptechTextArea textarea {
      height: auto;
    }

    .adoptechDatePicker--dateContainer {
      height: 36px;
    }

    input {
      width: 100%;
    }
  }

  &--fieldRow {
    display: flex;
    padding: 0 1rem 0.8rem 1rem;
  }

  &--label {
    font-weight: 500;
    color: #666665;
    margin: 0;
  }

  &--header-label {
    color: $adoptech-dark-grey-04;
    font-size: 20px;
    padding-bottom: 0.5rem;
  }

  &--headerMenu {
    display: flex;
    justify-content: space-between;
  }

  &--status {
    > input {
      color: $adoptech-dark-grey-08;
    }
  }

  &--requiredLabel {
    color: $adoptech-dark-grey-07;
    font-size: 12px;
    margin-left: 1rem;
  }

  &--reasonForExclusionField .adoptechTextAreaInternalLabel {
    border-radius: 4px;
  }

  .adoptech-error {
    border: solid 1px $adoptech-red;
  }
  input, textarea {
    &:disabled {
      cursor: not-allowed;
    }
  }
}
