@import './../mixins/TrustHubSection';
@mixin ControlCategoryRow {
  .accordion .card-header {
    top: 0px;
    border-radius: 4px;
    background: #F2F2F2;
    display: flex;
    height: 40px;
    padding: 8px 8px 8px 16px;
    color: #444445;
    font-size: 16px;

    &.dark {
      background: #666665;
      color: white;

      svg {
        color: white;
      }
    }
  }

  .card .card-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 8px;
  }

  .card.expanded {
    box-shadow: none;
  }
}

@mixin ControlRow {
  &--control {
    border-radius: 4px;
    border: 1px solid #CCC;
    padding: 8px 16px;

    &.droppable {
      border-bottom: 3px solid #333333;
    }
  }
  &--controlInfoRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .fa-circle-play {
      width: 24px;
      height: 24px;
    }
  }

  &--controlNameBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    white-space: nowrap;
    overflow: hidden;
  }

  &--controlDescriptionActions {
    margin-left: auto;
    display: flex;
    gap: 8px;
  }

  &--controlDescription {
    font-size: 12px;
  }

  &--controlFormRow {
    margin-top: 8px;
    display: flex;
    gap: 8px;
    flex-direction: column;
  }
}
.controlsSection {
  @include TrustHubSection();

  margin-top: 32px;
  &--categories {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  @include ControlCategoryRow();
  @include ControlRow();
}
