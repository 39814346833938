@import '../../styles/variables';

.lastUpdatedInfo {
  align-items: center;
  color: $adoptech-dark-grey-07;
  display: flex;
  font-size: 15px;
  justify-content: center;
  height: 26px;
  width: 100%;

  &--columns {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &--at {
    color: $adoptech-dark-grey-05;
    font-size: 16px;
    margin-left: 16px;
  }

  &--by {
    color: $adoptech-dark-grey-05;
    font-size: 16px;
    margin-left: 16px;
  }

  &--lhs {
    display: flex;
  }

  &--rhs {
    display: flex;
    padding-right: 2rem;
  }
}
