.adoptechFlexTable {
  &--tableWrapper {
    // use tableWrapper to avoid padding conflict with dragToScroll x relative position
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
    padding: 24px;
    margin-top: 24px;

    &.smallTheme {
      margin-top: 0px;
      padding: 0px;
      border-radius: 0px;

      .adoptechGridTable--row {
        cursor: auto;
      }

      svg {
        &:hover {
          cursor: pointer
        }
      }
      a {
        color: black;
        text-decoration: underline;
      }

      &.emptyTheme {
        margin-top: 0px;
        padding: 24px;
        border-radius: 8px;
      }
    }
  }

  &--table {
    overflow: hidden;
  }

  &.notDraggable {
    .adoptechFlexTable--table {
      overflow: auto;
    }
    .adoptechGridTable--row {
      margin-bottom: 0px;
      border-radius: 0px;
    }

    .adoptechGridTable--header .sortableTableHeader:first-of-type {
      right: auto;
    }
  }
}
