@import '../../../styles/variables';
@import '../../../styles/StickyHeader';
@import '../../../styles/mixins/DropdownMenuItem.scss';
@import 'bootstrap';

.auditsPage {
  &--header {
    display: grid;
    align-items: center;
    grid-template-columns: 8fr 5fr 2fr;
    @include stickyHeader(2);
  }

  &--title {
    display: inline-flex;
    align-items: center;
    color: $adoptech-dark-grey-05;
    font-size: 24px;
    margin: 2rem 0;
  }

  &--filter {
    justify-self: end;
    display: flex;
    align-items: center;
    padding-right: 10px;
    span {
      margin-left: 10px;
    }
  }

  &--headerButton {
    justify-self: end;
  }

  &--addButton {
    @include dropdownMenuItems($adoptech-primary-orange, $adoptech-orange-09);
  }

  &--6-columns {
    .adoptechGridTable--header,
    .adoptechGridTable--row {
      grid-template-columns: 4fr 4fr 4fr 3fr 3fr 1fr;
      margin-top: 5px;
    }
    .adoptechGridTable--row:hover {
      background-color: $adoptech-light-grey-09;
    }
  }

  .adoptechGridTable--header {
    margin-bottom: 0.5rem;
    @include stickyHeader(1, 6rem);
  }
}
