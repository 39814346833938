@import '../../styles/variables';

.dashboardTile {
  background-color: $adoptech-primary-white;
  border-radius: 8px;
  box-shadow: 0px 4px 16px #00000026;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 180px;
  margin-bottom: 1rem;
  margin-right: 30px;
  padding: 0;
  width: 240px;

  &--dualMetric {
    align-items: center;
    border-bottom: 1px solid $adoptech-light-grey-04;
    display: flex;
    height: 40px;
    justify-content: space-between;
    padding: 0 16px;

    &-noBorder {
      border-bottom: none;
    }
  }

  &--footer {
    align-items: center;
    background-color: $adoptech-light-grey-08;
    border-bottom: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: none;
    border-right: none;
    border-top: 1px solid $adoptech-light-grey-04;
    color: $adoptech-primary-orange-high-contrast;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: space-between;
    padding: 0 20px;
    text-align: left;

    &:enabled {
      &:focus {
        outline: 1px solid $adoptech-dark-grey-09;
      }
    }

    &:hover {
      background-color: $adoptech-primary-orange;
      color: $adoptech-primary-white;
    }
  }

  &--footerIcon {
    font-size: 20px;
  }

  &--header {
    align-items: center;
    border-bottom: 1px solid $adoptech-light-grey-04;
    display: flex;
    padding: 9px 16px;
    width: 100%;
  }

  &--icon {
    color: $adoptech-dark-grey-07;
    font-size: 20px;
  }

  &--iconContainer {
    align-items: center;
    background-color: $adoptech-light-grey-07;
    border-radius: 20px;
    display: flex;
    height: 40px;
    justify-content: center;
    margin-right: 10px;
    width: 40px;
  }

  &--metrics {
    color: $adoptech-dark-grey-05;
    display: table;
    flex-grow: 1;
    font-size: 15px;
  }

  &--singleMetric {
    display: table-cell;
    font-size: 40px;
    height: 81px;
    text-align: center;
    vertical-align: middle;
  }

  &--singleText {
    font-size: 14px;
    text-align: left;
    display: flex;
    padding: 10px 20px;
    align-items: center;
  }

  &--singleMetricLabel {
    font-size: 16px;
  }

  &--singleMetricValue {
    font-size: 22px;
  }

  &--singleMetricWithLabel {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  &--title {
    color: $adoptech-dark-grey-05;
    font-size: 20px;
    font-weight: 700;
  }
  &--medium {
    height: 190px;
  }

  @include media-breakpoint-down(xxl) {
    height: auto;
  }

  @include media-breakpoint-down(xl) {
    margin-right: auto;
  }

  &--light-orange {
    .dashboardTile--footer {
      color: $adoptech-primary-orange;
      &:hover {
        color: $adoptech-primary-white;
        .dashboardTile--footerLabel {
          color: $adoptech-primary-white;
        }
      }
    }
  }
}
