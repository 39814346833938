@import '../../styles/variables';

.adoptechTextAreaInternalLabel {
  display: flex;
  flex-direction: column;
  position: relative;
  background: $adoptech-light-grey-09;

  &--rounded {
    border-radius: 4px;
  }

  label {
    left: 7px;
    color: $adoptech-dark-grey-07;
    position: relative;
    font-size: 13px;
    user-select: none;
    pointer-events: auto;
    margin-bottom: 0px;
  }

  &:focus-within {
    border: 1px solid $adoptech-dark-grey-08;
  }
  &-error:focus-within {
    border: 1px solid $adoptech-red;
  }
  &-container {
    position: relative;
    cursor: text;
  }

  &-error {
    border: 1px solid $adoptech-red;
  }

  &-errorText {
    color: $adoptech-red;
    margin-bottom: 5px;
    font-size: small;
  }

  &-error-message {
    display: block;
    font-size: 11px;
    opacity: 0.5;
    color: $adoptech-red;
  }

  textarea {
    position: relative;
    background: none;
    border: none;
    width: 100%;
    padding-top: 0px;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  svg {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: $adoptech-dark-grey-07;
  }

  .labelWithButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-buttons {
      display: flex;
      gap: 8px;
    }
    svg {
      position: initial;
    }
  }
}
