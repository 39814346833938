@import '../../styles/variables';

.completedAssessments {
  margin-top: 2rem;

  &--header {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 2fr;
  }

  &--title {
    align-items: center;
    color: $adoptech-dark-grey-05;
    display: inline-flex;
    font-size: 24px;
    padding-right: 30px;
  }
}
