@import '../../../styles/variables';

.agreementsTextEditorFooter {
  align-items: center;
  display: flex;
  font-size: 14px;
  margin: 16px;

  &--button {
    flex-grow: 1;
    text-align: right;
  }

  &--label {
    color: $adoptech-dark-grey-07;
    margin-right: 10px;
  }

  &--lastUpdatedAt {
    color: $adoptech-dark-grey-05;
  }

  &--lastUpdatedBy {
    color: $adoptech-dark-grey-05;
    margin-right: 10px;
  }
}