@import '../mixins/TrustHubSection.scss';
@import '../../../styles/variables';

.certificatesSection {
  @include TrustHubSection();

  &--noContent a {
    color: black;
    text-decoration: underline;
  }

  &--documents {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }

  &--documentLogo {
    width: 72px;
    height: 72px;
  }
}
