@import '../../styles/variables.scss';

.completeDocumentAuditModal {
  .modal-dialog {
    max-width: 600px;
    width: 600px;

    .modal-body {
      padding: 0;
    }
  }

  &--header {
    display: flex;
    flex-direction: column;
  }

  &--properties {
    font-weight: 400;
    font-size: 16px;
    margin-top: 1rem;

    > div {
      display: flex;

      > div:first-of-type {
        width: 150px;
      }
    }
  }

  &--staffMembers {
    margin-top: 10px;
  }

  label {
    font-size: 14px;
    color: $adoptech-dark-grey-07;
    margin-bottom: 2px;
    display: block;
  }

  table {
    thead {
      th {
        border: none;
        padding-bottom: 5px;
        vertical-align: bottom;
        font-size: 14px;
        font-weight: normal;
      }
    }

    tr {
      td:first-child,
      th:first-child {
        width: 35px;
        padding-left: 30px;
      }

      td:not(:first-of-type),
      th:not(:first-of-type) {
        text-align: center;
      }
    }

    tbody {
      tr {
        background-color: $adoptech-light-grey-07;
      }
    }
  }

  &--staffMembers label {
    padding: 0 30px;
  }
}
