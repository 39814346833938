@import '../../styles/variables';

.approvedStatus {
  &--icon {
    font-size: 20px;
    margin-right: 10px;

    &-green {
      color: $adoptech-green;
    }
  }
}
