@import '../../styles/variables';

.adoptech-tooltip--orange {
  .tooltip-inner {
    min-width: 280px;
    text-align: left;
    font-size: 0.8rem;

    background: $adoptech-orange-01;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }

  &.bs-tooltip-bottom {
    .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
      border-bottom-color: $adoptech-orange-01;
    }
  }
}

.adoptech-tooltip--large {
  .tooltip-inner {
    min-width: fit-content;
    max-width: 1000px;

    text-align: left;
    padding: 15px;
  }
}

.adoptech-tooltip--middle {
  .tooltip-inner {
    min-width: 400px;
  }
}
