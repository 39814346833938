@import '../../styles/variables';

.mainMenuItems {
  background: $adoptech-dark-grey-04;
  color: $adoptech-light-grey-06-02;
  flex-grow: 1;
  font-size: 12px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}