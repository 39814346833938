@import './../../../styles/mixins/DrawerFormStyles';

.editContractForm {
  @include DrawerFormStyles();
  .react-datepicker-popper {
    z-index: 2;
  }
  &--documentLinksContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 8px;

    a { color: black; }
  }

  &--documentLinkName {
    display: flex;
    align-items: center;
    gap: 8px;

    .linkText {
      text-decoration: underline;
    }
    svg {
      cursor: pointer;
    }
    .fa-trash-can  {
      margin-left: auto;
    }
  }

  &--title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .documentLinksTable {
    .adoptechFlexTable {
      .adoptechGridTable--header,
      .adoptechGridTable--row {
        grid-template-columns: 150px auto 16px;
      }
    }
  }

  .linkedServiceTable {
    .adoptechFlexTable {
      .adoptechGridTable--header,
      .adoptechGridTable--row {
        grid-template-columns: 150px 150px auto;
      }
    }
  }
}
