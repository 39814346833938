@import '../../styles/variables';
.pdfPreviewCard {
  &--preview {
    margin: 35px 70px 35px 70px;
    box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
  }

  &--preview-header {
    background-color: $adoptech-light-grey-10;
    padding: 15px;
    color: $adoptech-dark-grey-05;
    font-size: 18px;
  }
}