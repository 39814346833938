@import '../../../styles/variables';
@import './../../../features/compliance/controls/mixins/RHSTableList';
@import './../../../features/compliance/controls/mixins/FieldBlocks';

.reviewHistoryItems {
  padding: 16px 1rem;

  &--title {
    font-size: 20px;
    padding-bottom: 14px;
  }

  &--historyItem {
    font-size: 14px;
    display: flex;
    padding: 8px 16px;
    flex-direction: column;

    border-radius: 8px;
    background: $adoptech-primary-white;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.20);
    margin-bottom: 8px;
  }

  &--commentTitle {
    margin-bottom: 8px;
    color: $adoptech-dark-grey-03;
  }

  &--historyComment, &--emptyComment {
    margin-bottom: 16px;
    color: $adoptech-dark-grey-05;
  }

  &--historyItemFooter {
    color: $adoptech-dark-grey-07;
    font-size: 10px;
    display: flex;
    gap: 12px;
    justify-content: center;

    > div:nth-of-type(1) ~ div {
      color: $adoptech-dark-grey-05;
    }
  }

  &--emptyComment {
    color: $adoptech-dark-grey-07;
    font-size: 14px;
  }


  &--noCommentsMode {
    @include RHSTableList;
    @include FieldBlocks;
    &--tableRow {
      display: flex;
      padding: 8px 16px;
      height: 38px;
      gap: 12px;
      color: #999995;
      cursor: initial;
    }

    padding: 24px;

    &--header {
      margin-bottom: 16px;
    }

  }


}
