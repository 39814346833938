@import '../../styles/variables';

.editProfileDrawer {
  width: 770px;

  .editProfileDrawer--deleteLink {
    color: $adoptech-red;
    cursor: pointer;
    text-decoration: underline;
    margin-left: 0.25rem;
  }
}
