@import '../../styles/variables';
@import 'react-datepicker/dist/react-datepicker.css';

.datepicker--wrapper {
  cursor: pointer;
  label {
    cursor: pointer;
  }

  &-disabled, &-disabled input {
    cursor: not-allowed;
  }
}

#adoptechDatePicker {
  &--datePicker {
    width: 100%;
  }
}
.adoptech-error {
  border: solid 1px $adoptech-red;
}
.adoptechDatePicker {
  &--dateContainer {
    display: flex;
    height: 2.4rem;
    flex-direction: column;

    .react-datepicker {
      width: 593px;
      height: auto;
      padding: 20px 5px 10px 20px;
      box-shadow: 0px 0.8rem 2.4rem $adoptech-dark-grey-05;
    }

    &.singleColumn {
      .react-datepicker {
        width: fit-content;
        padding-right: 20px;
      }

      .react-datepicker__navigation--next {
        margin-right: 0px;
      }
    }
  }
  &--selector {
    position: relative;
    align-self: flex-end;
    margin: 0 14px 0 0;
    top: -28px;
    color: $adoptech-dark-grey-09;
  }
  &--buttonsColumn {
    display: flex;
    flex-direction: column;
    margin-top: 2.2rem;
    padding: 0 1.8rem 0 1.8rem;
  }
  &--buttonsColumn .adoptechButtonContainer .btn.adoptechButton {
    width: 100%;
    padding: 0.3rem;
    font-size: 0.9rem;
    border-radius: 0.25rem;
    margin-top: 1.3rem;
  }
  &--dateContainer .react-datepicker__day--keyboard-selected {
    background-color: $adoptech-orange-01;
  }
  &--dateContainer .react-datepicker__day--selected {
    background-color: $adoptech-orange-01;
  }
  &--dateContainer
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker__triangle {
    left: 572px;
  }
  &--dateContainer .react-datepicker__day-name {
    margin: 0.5rem;
    color: $adoptech-dark-grey-07;
  }

  &--dateContainer .react-datepicker__navigation--next {
    margin-right: 260px;
    margin-top: 20px;
  }
  &--dateContainer .react-datepicker__navigation--previous {
    margin-left: 25px;
    margin-top: 20px;
  }
  &--dateContainer .react-datepicker__current-month {
    margin-bottom: 10px;
    color: $adoptech-dark-grey-06;
  }
  &--dateContainer .react-datepicker__input-container,
  .react-datepicker__input-container input {
    width: 100%;
    border: none;
  }

  &--dateContainer .react-datepicker-wrapper {
    background-color: #ddd;
    display: flex;
    width: 100%;
    border: 1px solid $adoptech-light-grey-04;
    border-radius: 0.3rem;
  }
  &--dateContainer .react-datepicker__header {
    background-color: $adoptech-primary-white;
  }
  &--dateContainer .react-datepicker__day {
    margin: 0.5rem;
    font-size: 0.9rem;
    color: $adoptech-dark-grey-04;
  }
  &--dateContainer .react-datepicker__day--disabled {
    color: $adoptech-dark-grey-09;
    cursor: not-allowed;
  }
}
