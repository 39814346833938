.editDocumentModal {

  &--modal {
    .modal-dialog {
      min-width: 590px;
    }

    .modal-content {
      border-radius: 8px;
    }
  }

  &--documentsTree {
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #D2D2D2;
    height: 320px;
    overflow-y: scroll;
  }

  &--search {
    input::placeholder {
      opacity: 1;
    }
  }

  &--body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &--document {
    padding-left: 16px
  }

  &--footer {
    display: flex;
    justify-content: space-between;
  }
}
