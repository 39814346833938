@import '../../styles/variables.scss';

.adoptechTile {
  &--large {
    height: 282px;
    width: 300px;

    @include media-breakpoint-up(xxl) {
      width: 365px;
    }

    justify-content: initial;
    .adoptechTile--tileHeader {
      height: 72px;
    }

    .adoptechTile--tileDescription {
      font-size: 14px;
    }

    .adoptechTile--tileAction {
      height: 44px;
      margin-top: auto;
    }
  }

  &--medium {
    height: 245px;
    width: 300px;

    @include media-breakpoint-up(xxl) {
      width: 365px;
    }

    justify-content: initial;
    .adoptechTile--tileHeader {
      height: 72px;
    }

    .adoptechTile--tileDescription {
      font-size: 14px;
    }

    .adoptechTile--tileAction {
      height: 44px;
      margin-top: auto;
    }
  }
  width: 240px;
  background-color: $adoptech-primary-white;
  border-radius: 8px;
  display: flex;
  box-shadow: 0px 4px 16px #00000026;
  flex-direction: column;
  justify-content: space-between;

  &--tileHeader {
    display: flex;
    padding: 9px 16px;
    align-items: center;
    border-bottom: 1px solid $adoptech-light-grey-04;
    color: $adoptech-dark-grey-05;
    font-size: 20px;
    font-weight: 700;
  }

  &--tileHeaderIcon {
    align-items: center;
    background-color: $adoptech-light-grey-07;
    border-radius: 20px;
    display: flex;
    height: 40px;
    justify-content: center;
    margin-right: 10px;
    width: 40px;
    color: $adoptech-dark-grey-07;
  }

  &--tileDescription {
    font-size: 12px;
    text-align: left;
    color: $adoptech-dark-grey-07;
    padding: 10px 20px;
  }

  &--tileAction {
    align-items: center;
    background-color: $adoptech-light-grey-08;
    border-bottom: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: none;
    border-right: none;
    border-top: 1px solid #ddd;
    color: $adoptech-primary-orange;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: space-between;
    padding: 0 20px;
    text-align: left;

    &:hover {
      background-color: $adoptech-primary-orange;
      color: $adoptech-primary-white;
    }

    &Icon {
      font-size: 20px;
    }
  }

  &--greenTick {
    margin-left: auto;
    color: $adoptech-green;
  }
}
