@import './../../../../../mixins/RHSTableList';
@import './../../../../../../../../styles/variables';
@import './../../../../../mixins/StatusField';

.editTestResultsList {
  @include RHSTableList();

  &--results-header {
    @include StatusField;
  }

  &--header {
    margin-top: 14px;
  }

  &--tableRow {
    cursor: initial;

    padding: 8px 16px;
    height: auto;
  }

  &--tableHeaders, &--tableRow {

    &.three-columns {
      grid-template-columns: 6fr 4fr 1fr;
    }

    &.four-columns {
      grid-template-columns: 1fr 6fr 4fr 1fr;
    }

    &.five-columns {
      grid-template-columns: 1fr 6fr 4fr 4fr 1fr;
    }
  }

  &--excludeIcon, &--includeIcon {
    cursor: pointer;
    margin-left: auto;
  }

  &--evidences {
    font-size: 10px;
  }
}



