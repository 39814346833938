@import '../../../styles/variables';

.subscriptionRequiredModal {
  .modal-header {
    color: #000;
    font-size: 28px;
    line-height: normal;
    display: flex;
    text-align: center;
  }

  .modal-content {
    height: 650px;
  }

  &--body {
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-size: 16px;
  }

  &--orangeText {
    color: #FF621E;
    font-weight: bold;
  }

  &--footer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #999995;
    align-items: center;
  }

  &--manageSubscriptionLink {
    cursor: pointer;
  }

  &--centeredButton {
    display: flex;
    justify-content: center;
  }
}
