@import '../../styles/variables';

.confirmModal {
  padding: 8px 8px;

  &--description {
    font-size: 16px;
  }
  &--body {
    margin-top: 16px;
  }
}
