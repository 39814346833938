@import '../../styles/variables';

.deleteProfileModal {
  .modal-dialog {
    max-width: 490px;
    width: 490px;
  }

  &--text {
    padding-bottom: 20px;
  }
}
