@import '../../styles/variables';

.policyClauseHeader {
  &--field {
    opacity: 1;
    transition: opacity 1s;
  }

  &--title {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 15px;

    > div {
      padding-right: 10px;
    }

    &-leftOut {
      .policyClauseHeader {
        &--field {
          opacity: 0.2;
        }
      }
    }
  }

  &--leaveOutToggle {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 20px 0;
  }
}
