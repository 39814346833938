@import '../../styles/variables';

.assessmentTemplateTile {
  &--button {
    display: flex;
    flex-wrap: wrap;
  }
  &--container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-items: flex-end;
  }
}
