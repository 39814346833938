@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins/DrawerTabs.scss';

.adoptechHS--drawer.complianceEditControlDrawer {
  width: 770px;
  background-color: #f5f5f5;
  overflow: visible; // if change to hidden check datepicker

  @include DrawerTabs();

  &.adoptechDrawer--open.onTop {
    z-index: 556;
  }
}

