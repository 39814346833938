@import '../../../styles/variables';
@import './../controls/mixins/ComplianceTableHeaders';

.testLogPage {
  &--sticky {
    position: sticky;
    z-index: 2;
    padding-top: 16px;
    top: 0;
    background-color: #fff;
  }

  &--list-header {
    background: $adoptech-primary-white;
    border-radius: 8px 8px 0 0;

    .adoptechTextInput-search {
      margin-bottom: 10px;
    }
  }

  &--header {
    align-items: center;
    display: flex;
    gap: 16px;
    justify-content: space-between;

    border-radius: 5px;
    box-shadow: 0px 0px 5px #e2e2e2;
    background-color: #fff;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;

    #testLogPageCategorySelect {
      width: 200px;
    }

    .adoptechReactSelect {
      min-width: 200px;
      font-size: 14px;
    }

    .adoptechPager {
      flex-grow: 1;
      justify-content: flex-end;
    }

    @include ComplianceTableHeaders;
  }

  &--title {
  }

  &--divider {
    height: 38px;
    border-right: 1px solid $adoptech-light-grey-04;
  }

  &--list {
    background: $adoptech-primary-white;
    border-radius: 0 0 8px 8px;
    padding: 8px;
    margin-bottom: 10px;
    &-heading {
      font-size: 16px;
      color: $adoptech-dark-grey-03;
      padding: 10px 10px 0;
    }

    &-heading, &-row {
      align-items: center;
      display: grid;
      grid-template-columns: 2fr 3fr 3fr 9fr 1fr;
      margin-bottom: 10px;
    }

    &-row {
      cursor: pointer;
      background: $adoptech-primary-white;
      padding: 8px;

      font-size: 14px;
      height: 48px;
      border-radius: 8px;
      box-shadow: 0 0 8px #d8d8d8;
      margin-bottom: 10px;
    }
  }

  &--statusCompliant {
    color: $adoptech-dark-green;
    font-size: 20px;
  }

  &--statusFailed {
    color: $adoptech-red;
    font-size: 20px;
  }

  &--statusNone {
    color: $adoptech-light-grey-05;
    font-size: 20px;
  }
}
