@import '../../styles/variables.scss';

.adoptech-datepicker2 {
  display: flex;
  flex-direction: column;
  position: relative;
  background: $adoptech-light-grey-09;
  padding-left: 7px;

  &--rounded {
    border-radius: 4px;
  }
  .react-datepicker-wrapper {
    border: none;
    background-color: inherit;
  }

  input{
    border: none;
    background-color: inherit;
    padding: 0px;
  }

  &:focus-within {
    border: 1px solid $adoptech-dark-grey-08;
  }

  label {
    color: $adoptech-dark-grey-07;
    font-size: 13px;
    position: relative;
    top: 2px;
  }

  .adoptechDatePicker--dateContainer {
    height: 2rem;
  }

  &--datepicker-icon {
    position: absolute;
    right: 10px;
    bottom: 11px;
    opacity: 0.5;
    pointer-events: none;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    * {
      cursor: not-allowed;
    }
  }

  &.adoptech-error {
    border: solid 1px $adoptech-red;
  }
}
