
@import './../../mixins/FieldBlocks';
@import './../../../../../styles/variables';
@import './../../mixins/StatusField';

.adoptechHS--drawer.editTestDrawer {
  width: 770px;
  background-color: #f5f5f5;
  overflow-y: auto;

  &.adoptechDrawer--open.onTop {
    z-index: 556;
  }

  .editTestForm {
    @include FieldBlocks;

    &--fieldRow {
      margin-top: 24px;
      display: flex;
      align-items: center;
    }

    &--fieldColumn {
      display: flex;
      flex: 1;
      gap: 8px;
    }

    &--fieldCategory, &--fieldOwner, &--fieldFrequency {
      min-width: 250px;
    }

    &--toggleBadgeRow {
      display: flex;
      gap: 16px;
    }


    &--toggleBadge {
      padding: 8px;
      height: 32px;
      border-radius: 4px;
      font-weight: bold;
      display: flex;
      gap: 8px;
      align-items: center;
      &.red {
        background: #ea554240;
        color: #EA5542;
      }

      &.green {
        background: #DAE9D0;
        color: #88BA68;
      }
    }
    &--results-header {
      @include FieldTitle;
      @include StatusField;
      gap: 32px;
    }

    &--nextRunDate-header {
      @include FieldTitle;
      gap: 16px;
      padding: 8px 0px;
    }
    &--overdueIcon {
      color: $adoptech-red;
    }

    &--run-review {
      margin-left: auto;
    }
  }
}

