@import '../../../styles/variables';

.auditsAreasForm {
  user-select: none;

  &--checkBoxes {
    display: flex;
    margin: 10px 0 20px;
  }

  &--checkQuestions {
    border: 1px $adoptech-light-grey-02 solid;
    border-radius: 5px;
  }

  &--sectionTitle {
    font-size: 20px;
    padding: 14px;
    border-bottom: 1px $adoptech-dark-grey-09 solid;
  }

  &--groupNode, &--questionNode {
    font-size: 14px;
  }

  &--checkSection:not(:last-child) {
    border-bottom: 1px $adoptech-dark-grey-09 solid;
  }

  &--questionSubGroup {
    padding-left: 25px;
  }

  &--questionGroup {
    padding: 16px;
  }

  &--choseNote {
    color: $adoptech-dark-grey-09;
    display: block;
    padding: 10px;
  }
}