@import '../../../styles/variables';
@import './../CompanyInformation/CompanyInformation';


.companyBrandingTable {
  @include CompanySection;

  &--contents {
    gap: 8px;

    &--row {
      display: grid;
      grid-template-columns: auto;
    }

    &--colourSample {
      width: 48px;
      height: 48px;
      border-radius: 4px;
    }

    &--data-media-field {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    &--label {
      display: flex;
      gap: 8px;
      align-items: center;
    }
    $colour-editor-width: 120px;
    .react-colorful {
      width: $colour-editor-width;
      height: 120px;
    }

    .react-colorful__hue-pointer, .react-colorful__pointer {
      width: 15px;
      height: 15px;
    }

    .react-colorful__saturation, .react-colorful__last-control {
      border-radius: 0px;
    }

    &--colourEditor {
      display: flex;
      flex-direction: row;
      gap: 16px;

      &-column {
        display: flex;
        gap: 10px;
        flex-direction: column;
      }
    }
  }

  &--error {
    background-color: $adoptech-red-background;
    border: solid 1px $adoptech-red;
    color: $adoptech-red;
  }
}
