@import '../../styles/variables';

.assessmentResponseLibraryPopup {
  .dropdown-menu {
    border: none;
    padding: 0;
    min-width: 400px;
    max-width: 600px;
  }

  &--by {
    font-weight: bold;
    font-size: 14px;
    color: $adoptech-dark-grey-05;
  }

  &--header {
    display: flex;
    justify-content: space-between;
  }

  &--header2 {
    display: flex;
    justify-content: right;
    margin-bottom: 15px;
  }

  &--prevAnswer {
    font-size: 14px;
    color: $adoptech-dark-grey-07;
  }

  &--lastUsed {
    font-weight: bold;
    font-size: 14px;
    color: $adoptech-dark-grey-05;
  }

  &--button {
    color: $adoptech-dark-grey-05;

    &:hover {
      background-color: $adoptech-light-grey-05;
    }

    &-open {
      background-color: $adoptech-light-grey-05;
    }
  }

  &--pointer {
    height: 10px;
    overflow: hidden;
    position: relative;

    &::after {
      background: white;
      bottom: 25px;
      box-shadow: 0 0 8px #00000029;
      content: '';
      height: 50px;
      position: absolute;
      right: 25px;
      top: -53px;
      transform: rotate(45deg);
      width: 50px;
    }
  }

  &--body {
    background-color: $adoptech-primary-white;
    box-shadow: 0 3px 10px $adoptech-box-shadow-hover;
    border-radius: 3px;
    width: calc(100% + 4px);
    margin-left: -2px;
  }

  &--title {
    font-size: 18px;
    padding: 10px 20px;
    border-bottom: 1px solid $adoptech-light-grey-01;
    color: $adoptech-dark-grey-05;
  }

  &--library {
    padding: 20px 25px;
    max-height: 400px;
    overflow: auto;
  }

  &--libraryEntry {
    padding: 10px 20px 15px;
    background-color: $adoptech-light-grey-07;
    border-radius: 5px;

    &:not(:last-child) {
      border-bottom: 1px solid $adoptech-light-grey-01;
      margin-bottom: 15px;
    }

    &:hover {
      background-color: $adoptech-light-grey-06;
    }

    &:active {
      background-color: $adoptech-light-grey-05;
    }
  }

  .adoptechTable table.adoptechTable--wrapper td {
    white-space: initial;
  }

  .dropdown-menu.show {
    background: transparent;
    border: none;
  }
}
