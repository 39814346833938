@import '../../../../../styles/variables';
@import './../../mixins/RHSTableList';
@import './../../../../../styles/mixins/ControlIconMixin';

.controlCheckControls {
  @include RHSTableList();
  @include ControlIconMixin;

  &--tableHeaders, &--tableRow {
    grid-template-columns: 1fr 8fr 3fr;
  }
}
