@import '../../styles/variables.scss';

.attestationManagementModal {
  .modal-dialog {
    max-width: 720px;
    width: 720px;

    .modal-content {
      min-height: 650px;
    }

    .modal-body {
      padding: 0;
      display: flex;
      flex-direction: column;
    }

    .modal-header {
      padding-bottom: 0px;
    }
  }

  &--teams {
    .card {
      border: initial;
    }
    .attestationManagementModal--teamsList,
    .attestationManagementModal--attestation {
      height: 48px;
    }
  }

  &--subheader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 20px;
  }

  &--title {
    display: inline-block;
    padding-right: 10px;
    height: 25px;
    margin-right: 10px;
    color: $adoptech-dark-grey-03;
    border-right: 1px solid $adoptech-dark-grey-07;
    color: var(--unnamed-color-333335);
    text-align: left;
    font-size: 18px;
    line-height: 23px;
    font-weight: 500;
  }

  &--users {
    max-height: 50vh;
    overflow-y: auto;
  }

  &--readCount {
    display: inline-block;
    color: $adoptech-primary-white;
    font-size: 18px;
    border-radius: 15px;
    padding: 0 8px;
    background-color: $adoptech-orange-01;
  }

  &--selects {
    display: flex;
    justify-content: space-between;
  }

  &--selects:nth-child(3) {
    margin-top: 15px;
  }

  &--teamsList,
  &--attestation {
    height: 48px;
    background-color: $adoptech-light-grey-07;

    .adoptechCheckbox {
      label {
        margin-bottom: 0;
      }
    }
  }

  &--teamsList {
    .adoptechCheckbox {
      padding-left: 5px;

      label {
        color: $adoptech-dark-grey-05;
      }
    }

    svg {
      font-size: 32px;
      color: $adoptech-dark-grey-07;
    }
  }

  &--send-email-notification {
    label {
      padding: 0 30px;
    }
  }

  &--attestation {
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      border-bottom: 1px solid $adoptech-light-grey-04;
    }

    .adoptechCheckbox {
      padding-left: 60px;

      label {
        color: $adoptech-dark-grey-03;
      }
    }
  }

  label {
    font-size: 14px;
    color: $adoptech-dark-grey-07;
    margin-bottom: 2px;
    display: block;
  }

  /* TODO: fix stylelint */
  /* stylelint-disable */
  table {
    tbody {
      overflow: scroll;
      td.attestationManagementModal--teams,
      td.attestationManagementModal--teams:first-child,
      td.attestationManagementModal--teams:last-child {
        padding: 0;
      }

      .adoptechCheckbox {
        label {
          font-size: 15px;
        }
      }
    }
  }
  /* stylelint-enable */

  &--staffMembers label,
  &--selects {
    padding: 0 40px;
  }

  &--message {
    width: 460px;
  }

  &--datepicker {
    width: 160px;
    z-index: 800;
  }

  &--staffMembers {
    margin-bottom: 20px;
    flex: 1;
  }

  .react-datepicker-wrapper,
  .react-datepicker-wrapper input {
    width: 100%;
    height: 40px;
  }

  .adoptechTable-modal .adoptechTable--wrapper {
    th:first-child {
      width: 40%;
    }
  }

  &--subtitle {
    font-size: 16px;
    color: $adoptech-dark-grey-04;
    padding-left: 40px;
    margin-top: 25px;
  }

  &--reminderBtn svg {
    color: $adoptech-orange-01;
  }
}

.attenstationManagementModal {
  .modal-content {
    height: 90vh;
    overflow: auto;
  }

  .modal-dialog {
    max-width: 95%;
    @include media-breakpoint-up(lg) {
      max-width: 80%;
    }
  }

  .modal-body {
    padding: 0;
    overflow: auto;
  }

  .modal-header {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }

  &--tabs {
    display: flex;
    font-weight: normal;
  }

  &--tab {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 8px 20px 0;
    position: relative;
    font-size: 16px;
    cursor: pointer;
    color: $adoptech-dark-grey-07;

    &.selected {
      color: $adoptech-dark-grey-05;
      &::after {
        border-bottom: 4px solid $adoptech-dark-grey-05;
        content: '';
        display: block;
        margin: 0 auto;
        padding-top: 2px;
        width: 100%;
        cursor: default;
      }
    }
  }
}
