@import 'app/javascript/src/styles/variables';

.tableAnswerEditModal2 {
  input {
    width: 100%;
    margin-bottom: 10px;
  }

  .modal-body{
    overflow: auto;
  }

  .modal-content {
    max-height: 95vh;
  }

  .modal-dialog {
    max-width: 75vw;
  }

  &--explanatoryText {
    color: $adoptech-dark-grey-05;
    font-size: 0.9rem;
  }

  &--questionText{
    color: $adoptech-dark-grey-03;
    font-size: 1.125rem;
  }
}
