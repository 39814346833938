@import '../../styles/variables';

.policyQuestions {
  &--hideShowLink {
    margin: 20px 20px 0;
    display: inline-block;
    color: $adoptech-dark-grey-07;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: $adoptech-dark-grey-04;
    }
  }
}
