// used in multiple pages inside trusthub admin + in color header
@mixin TrustHubPageHeader {
  padding: 32px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;

  &--backSection {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &--backSectionTitle {
    color: #666665;
    font-size: 28px;
  }

  &--backSectionLink {
    font-size: 28px;
    color: #F1881F;
    &:hover {
      cursor: pointer;
    }
  }

  a {
    color: #333335;
  }
  &--info {
    display: flex;
    gap: 32px;
    align-items: center;
  }

  &--buttons {
    display: flex;
    gap: 16px;
    align-items: center;

    .toggleSwitch {
      padding: 0px;
    }

    button {
      font-size: 14px;
    }
  }
}

// used in 2 cases:

// trusthub admin layout
// trusthub user page

@mixin TrustHubPage {
  width: 100%;
  background-color: #FAFAFA;
  height: fit-content;
  min-height: 100%;

  &--content {
    padding: 0px 80px;
  }

  &--contentFooter {
    padding: 32px 0px 64px 0px;
    display: flex;
    gap: 8px;
    align-items: center;
    color: #666665;
    div {
      cursor: pointer;
    }
  }
}
