@import '../../styles/variables';

.managePaymentMethodsGrid {
  margin: 0 -40px;

  .adoptechTable--wrapper .managePaymentMethodsGrid--row {
    background-color: $adoptech-primary-white;
    cursor: auto;
  }

  &--actions {
    color: $adoptech-dark-grey-05;
  }

  &--cardType {
    display: flex;
    align-items: center;

    svg {
      margin-right: 12px;
    }
  }

  &--message {
    color: $adoptech-dark-grey-07;
    font-size: 14px;
    text-align: center;
  }
}
