@import '../../styles/variables';

.addFileOrUrlModal {
  .modal-dialog {
    width: 550px;
    max-width: 80%;
  }

  p {
    margin-bottom: 10px;
  }

  &--field {
    width: 100%;

    input {
      width: 100%;
    }
  }

  &--fileUpload-wrapper {
    position: relative;
    width: 100%;
    height: 45px;
    background-color: $adoptech-light-grey-07;
    border: 1px solid $adoptech-light-grey-04;
    border-radius: 2px;
    color: $adoptech-dark-grey-05;
    padding: 5px;
    display: flex;
    align-items: center;
  }

  &--fileUpload-button.btn.adoptechButton {
    border-color: $adoptech-dark-grey-08;
    color: $adoptech-dark-grey-05;
    margin-right: 10px;
    height: 35px;
  }

  &--label {
    color: $adoptech-dark-grey-07;
    font-size: 14px;
  }

  &--prompt {
    color: $adoptech-dark-grey-05;
    padding-bottom: 16px;
  }

  &--row {
    display: flex;
    padding-bottom: 16px;
  }

  &--url {
    &-error {
      color: $adoptech-red;
    }
  }

  &--value {
    height: 21px;

    &-error {
      color: $adoptech-red;
    }
  }
}
