@import '../../../styles/variables';

.startFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 30px 10px;

  &--text {
    font-size: 12px;
    line-height: 14px;
    color: $adoptech-dark-grey-05;
  }
}