@import '../../styles/variables';

.vendorRegisterRiskProfile {
  display: flex;
  align-items: center;

  &--text {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 12px;
  }
  &--line {
    > .progress {
      height: 4px;
      width: 50px;

      > .progress-bar {
        border-radius: 0.25rem;
      }
    }
  }
  &--number {
    font-size: 16px;
    margin-left: 0.5rem;
  }
  &--color-verylow {
    color: $adoptech-green-03;
    .progress-bar {
      background-color: $adoptech-green-03;
    }
  }
  &--color-low {
    color: $adoptech-green;
    .progress-bar {
      background-color: $adoptech-green;
    }
  }
  &--color-medium {
    color: $adoptech-yellow;
    .progress-bar {
      background-color: $adoptech-yellow;
    }
  }
  &--color-high {
    color: $adoptech-orange;
    .progress-bar {
      background-color: $adoptech-orange;
    }
  }
  &--color-critical {
    color: $adoptech-red;
    .progress-bar {
      background-color: $adoptech-red;
    }
  }
}
