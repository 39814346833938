@import '../mixins/TrustHubSection.scss';

.esgSection, .complianceSection {
  @include TrustHubSection();

  &--noContent a {
    color: black;
    text-decoration: underline;
  }

  &--documents {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }

  &--descriptionRowInput {
    display: flex;
    justify-content: space-between;

    div.adoptechTextArea {
      width: 100%;
      textarea {
        background: #FAFAFA;
        border-radius: 4px;
        border: 1px solid #CCC;
      }
    }
  }

  svg.fa-leaf {
    width: 32px;
    height: 32px;
    color: #6CAA45;
  }
}
