@import '../../../../../styles/variables';
@import '../../mixins/RHSTableList.scss';

.complianceControlTasksSimpleList {
  @include RHSTableList();

  &--header {
    margin-top: 28px;
  }

  &--tableHeaders, &--tableRow {
    grid-template-columns: 4fr 30fr 2fr;
  }

  &--greenTick {
    color: $adoptech-dark-green;
    font-size: 16px;
    margin-right: 16px;
  }

  &--greyTick {
    color: $adoptech-light-grey-04;
    font-size: 16px;
    margin-right: 16px;
  }

  &--redTick {
    color: #e55f4e;
    font-size: 16px;
    margin-right: 16px;
  }

  &--addRow {
    padding-top: 1.8rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .adoptechReactSelect {
      width: 100%;
      margin-right: 1rem;
    }
  }
}
