@import './../../../components/DocumentLibraryModal/DocumentLibraryModal';

.documentLibraryHeader {
  color: #444445;
}

.addNewLegislation {
  &--mainColumns {
    display: flex;
    padding: 24px 40px;
    gap: 24px;
  }

  &--section {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &Content {
      display: flex;
      padding: 16px 8px 16px 16px;
      gap: 12px;
      border-radius: 8px;
      border: 1px solid #DDD;
      flex-direction: column;
      overflow-y: scroll;
      max-height: 455px;
    }

    &Title {
      color: #444445;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .selectJurisdictionsSection {

    &--section {
      min-width: fit-content;
    }
    &--sectionContent {
      display: flex;
      flex-direction: column;
    }
  }

  .selectLegislationsSection {
    flex-grow: 1;
  }

  .jurisdictionTemplatesSection {
    &:nth-child(n+2) {
      margin-top: 16px;
    }

    &--header {
      display: flex;
      gap: 16px;

      .hasError {
        color: red;
        border-bottom: 2px solid red;
      }
    }

    &--jurisdictions {
      display: flex;
      gap: 16px;
      margin-top: 16px;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .jurisdictionTemplate {
    display: flex;
    width: 120px;
    height: 160px;
    padding: 12px;

    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 1px 1px 16px 0px rgba(0, 0, 0, 0.20);
    font-size: 14px;

    &--title {
      font-size: 12px;
      color: #999995;
    }

    &--name {
      overflow: hidden;
      max-height: calc(16px * 7);
      line-height: 16px;
    }
  }
}


