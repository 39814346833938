@import '../../styles/variables';

.assessmentPublishedModal {
  .modal-body {
    color: $adoptech-primary-white;
    margin-top: 2rem;
    text-align: center;
  }

  .modal-content {
    background-color: $adoptech-green-03;
    border: none;
    width: 432px;
  }

  .modal-footer {
    .btn.adoptechButton-success {
      color: $adoptech-primary-white;

      &:hover {
        background-color: $adoptech-green-03;
        border-color: $adoptech-primary-white;
      }
    }

    .btn.adoptechButton-inverse {
      color: $adoptech-green-03;

      &:hover {
        background-color: $adoptech-primary-white;
      }
    }
  }
}
