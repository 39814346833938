@import '../../styles/variables.scss';

.adoptech-select2-container {
  display: flex;
  flex-direction: column;
  position: relative;
  background: $adoptech-light-grey-09;
  padding-left: 7px;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  &--rounded {
    border-radius: 4px;
  }

  &--label {
    color: $adoptech-dark-grey-07;
    font-size: 13px;
    position: relative;
    top: 2px;
  }

  &:focus-within {
    border: 1px solid $adoptech-dark-grey-08;
  }

  .react-select {
    .adoptechReactSelect--prefix__control {
      border: none;
      background-color: inherit;

      .adoptechReactSelect--prefix__value-container {
        padding-left: 0px;
      }
    }
  }

  &.adoptech-error {
    border: 1px solid $adoptech-red;
  }
}
