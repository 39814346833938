.editControlFrameworksModal {

  &--modal {
    .modal-dialog {
      width: 390px;
    }
    .modal-content {
      border-radius: 8px;
    }
  }
  &--body {
    display: flex;
    gap: 16px;
    flex-direction: column;
  }


}
