@import '../../styles/variables';

.inProgressPoliciesGrid {
  display: grid;
  flex-wrap: wrap;

  &--categoryHeader {
    width: 150px;
  }

  &--download {
    font-size: 20px;
    margin: 0 30px 0 0;

    &-disabled {
      color: $adoptech-dark-grey-07;
      cursor: not-allowed;
    }
  }

  &--lastEditedHeader {
    width: 150px;
  }

  &--lastEditedByHeader {
    width: 250px;
  }

  &--nameHeader {
    width: 400px;
  }

  &--progressHeader {
    width: 150px;
  }
}
