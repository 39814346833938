@import '../../styles/variables.scss';

.documentLibraryHeader {
  &--titleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 30px 20px;
    border-bottom: 1px solid $adoptech-light-grey-01;
    flex-wrap: wrap;
  }

  &--title {
    font-size: 24px;
    font-weight: 600;
  }

  &--search {
    position: relative;
    width: 325px;
    margin-top: 10px;
    margin-left: auto;

    @include media-breakpoint-up(xl) {
      margin-top: 0;
      width: 325px;
      flex: initial;
    }
  }

  &--searchIcon {
    color: $adoptech-light-grey-01;
    position: absolute;
    right: 11px;
    top: 7px;
    font-size: 18px;
  }

  &--categoryFilter {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left: auto;

    @include media-breakpoint-up(xl) {
      margin-left: 20px;
    }
  }

  &--categoryFilterItem {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    height: 30px;
    font-size: 14px;
    white-space: nowrap;
    cursor: pointer;

    &:not(:last-of-type) {
      border-right: 1px solid #ccc;
    }

    &.selected {
      background-color: $adoptech-dark-grey-07;
      color: white;
    }
  }

  &--categoryName {
    color: $adoptech-dark-grey-05;

    font-size: 20px;
    display: flex;
  }
}
