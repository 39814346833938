@import '../../../styles/variables';

.uploadOwnPolicy {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 30px;

  .adoptechTextInput {
    margin-bottom: 10px;
  }

  &--body {
    display: flex;
    flex-grow: 1;
  }

  &--fileName {
    font-weight: 700;
    padding: 1rem 0;
  }

  &--footer {
    display: flex;
    justify-content: space-between;
    padding: 40px 0 30px;
  }

  &--header {
    color: $adoptech-dark-grey-05;
    font-size: 18px;
    padding: 25px 0 10px;
  }

  &--subTitle {
    font-size: 15px;
    color: $adoptech-dark-grey-05;
    margin-bottom: 15px;
  }

  &--label,
  .adoptechReactSelect--label {
    color: $adoptech-dark-grey-07;
    font-size: 14px;
  }

  &--leftBody {
    flex-basis: 0;
    flex-grow: 1;
    margin-right: 12px;
  }

  &--rightBody {
    flex-basis: 0;
    flex-grow: 1;
    margin-left: 12px;
  }

  &--documentTypeSelect, &--documentCategorySelect {
    margin-bottom: 20px;
  }
}
