@import '../../styles/variables';

.policyProgress {
  display: flex;

  &--percentage {
    width: 0%;
    padding-right: 2.2em;
  }
  &--bar {
    display: flex;
    width: 100%;
    margin: 0 0 0 8px;
    background-color: $adoptech-light-grey-07;
  }

  &--bar > div {
    width: 0;
    transition: width 1s ease-out;
  }

  &--green > div {
    background-color: $adoptech-green;
  }
  &--orange > div {
    background-color: $adoptech-orange;
  }
}
