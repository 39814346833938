.policyGrid {

  &--5-columns {
    .adoptechGridTable--header,
    .adoptechGridTable--row {
      grid-template-columns: 8fr 3fr 1fr 6fr 3fr;

      > div:last-child {
        justify-self: flex-end;
      }
      .policyProgress {
        width: 70%;
      }
    }
    .adoptechGridTable--row:hover {
      background-color: $adoptech-light-grey-09;
    }
  }

  &--7-columns {
    .adoptechGridTable--header,
    .adoptechGridTable--row {
      grid-template-columns: 8fr 3fr 1fr 3fr 2fr 3fr 1fr;

      > div:last-child {
        justify-self: flex-end;
      }
    }
    .adoptechGridTable--row:hover {
      background-color: $adoptech-light-grey-09;
    }
  }

  &--8-columns {
    .adoptechGridTable--header,
    .adoptechGridTable--row {
      grid-template-columns: 8fr 3fr 1fr 2fr 3fr 2fr 2fr 1fr;

      > div:last-child {
        justify-self: flex-end;
      }
    }
  }

  .adoptechGridTable--row > div {
    padding: 10px 0;
  }

  .meatballMenu--button .iconButton {
    font-size: 25px;
  }
}
