@mixin AdoptechInputGreyBackground {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 8px 16px;
  color: #999995;
  font-size: 14px;
  border-radius: 4px;
  background: #F6F6F6;
}
