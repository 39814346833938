@import '../../styles/variables';

.colourPalette {
  display: flex;

  &--gap-4px {
    gap: 4px;
  }

  &--gap-10px {
    gap: 10px;
  }

  &--paletteItem {
    cursor: pointer;
    width: 18px;
    height: 18px;
  }

  &--iconShadow {
    filter: drop-shadow(2px 2px 3px $adoptech-light-grey-02);
  }
}
