@import '../../styles/variables';

.invitationSentModal {
  .modal-content {
    border-radius: 3px;
    background-color: $adoptech-primary-orange;
    color: $adoptech-primary-white;
    text-align: center;
  }

  .modal-header {
    border-bottom: 0;
    justify-content: flex-end;
    font-size: 1.1rem;
    padding: 0.5rem 1rem;
  }

  .modal-dialog {
    width: 460px;
  }

  .modal-body {
    font-size: 18px;
    padding: 0 40px;
  }

  .modal-footer {
    border-top: 0;
    font-size: 14px;
    padding: 5px 40px 20px;
    justify-content: center;
  }

  &--cross {
    cursor: pointer;
  }
}
