.addOwnLegislation {
  &--mainColumns {
    display: flex;
    padding: 24px 40px;
    gap: 24px;
  }

  &--sectionTitle {
    color: #444445;
    font-size: 20px;
    margin-bottom: 8px;
  }

  &--sectionContent {
    display: flex;
    flex-direction: column;
    gap: 16px;
  };

  &--overviewSection {
    width: 400px;
  }

  &--descriptionSection {
    width: 100%;
    textarea {
      height: 417px;
    }
  }
}
