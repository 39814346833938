@import '../../../styles/variables';
@import './WhiteCheckbox';

@mixin DocumentRowMixin {
  display: flex;
  width: 292px;
  padding: 16px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid #CCC;
  background: #fff;
  justify-content: space-between;
  font-size: 16px;
  align-items: center;
  color: #444445;

  &:hover {
    cursor: pointer;
  }
}

@mixin TrustHubSection {
  margin-top: 32px;

  &--title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .svg-inline--fa {
      margin-right: 8px;
    }

    .toggleSwitch {
      color: #666665;
      font-size: 14px;
    }
  }

  &--titleInfo {
    display: flex;
    gap: 8px;
    font-size: 24px;
    align-items: center;
    font-weight: bold;
  }

  &--panel {
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.20);
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &--noContent {
    opacity: 0.5;
    color: #333335;
    font-size: 14px;
  }

  .editButton {
    padding: 4px 16px;
    min-width: 78px;
  }

  &--descriptionRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--addNewRow {
    @include DocumentRowMixin();
    border-style: dotted;
    border-width: 2px;
    width: 100%;

    &:hover {
      background: linear-gradient(to right, #f2f2f2, #fff);
    }
  }

  .adoptechCheckbox {
    width: auto;

    display: flex;
    align-items: center;
    height: 32px;

    .emptyLabel { visibility: hidden; }
    @include WhiteCheckbox();
  }

  &--document {
    @include DocumentRowMixin();

    .fa-leaf {
      width: 32px;
      height: 32px;
    }

    .fa-shield-check {
      width: 32px;
      height: 32px;
      color: #666665;
    }

    .fa-lock-keyhole {
      height: 16px;
    }

    .adoptechCheckbox {
      width: 20px;
    }

    &.hoverable {
      &:hover {
        background: linear-gradient(to right, #f2f2f2, #fff);
      }
    }
  }

  &--documentTitle {
    overflow-wrap: break-word;
    max-width: 147px;
    font-weight: 500;

    &.overflowTitle {
      max-width: 100px;
      width: 100px;
    };

    &.overflowTitleLarge {
      max-width: 130px;
      width: 130px;
    }
  }

  &--companyActions {
    display: flex;
    gap: 16px;

    .adoptechButtonContainer .btn.adoptechButton {
      min-width: 75px;
    }
  }

  &--lineDivider {
    width: 1px;
    height: 38px;
    background: #d9d9d9;
  }

  &--titleActions {
    display: flex;
    gap: 24px;
    align-items: center;
  }

  &--bulkActionsRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  [id$='--bulkActionSelect'] {
    width: 272px;
    .bulkOptions {
      display: flex;
      gap: 9px;
      align-items: center;

      svg {
        width: 22px;
        height: 22px;
        color: #666665;
      }

      &--label {
        display: flex;
        flex-direction: column;
      }
      &--labelText {
        font-size: 16px;
        color: #444445;
      }
      &--labelDescription {
        color: #999995;
        font-size: 12px;
      }
    }
  }
}
