@import '../../../styles/variables';

.eventDetailsDrawer {
  width: 720px;
  &--row {
    display: flex;
    flex-flow: row;
    padding-bottom: 10px;
  }
  &--field {
    width: 100%;
    strong {
      padding-right: 10px;
    }
  }
}
