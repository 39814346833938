@import '../../styles/variables.scss';
@import './DragToScrollTheme';

.adoptechGridTable {
  &--header {
    font-size: 14px;
    display: grid;
    grid-auto-flow: column;
    padding: 0.5rem 1rem;
    color: $adoptech-dark-grey-07;

    .sortableTableHeader {
      &:first-of-type {
        position: relative;
        right: 1rem;
      }

      &--header {
        font-weight: normal;
        margin-bottom: 2px;
      }
    }
  }

  &--row {
    font-size: 14px;
    display: grid;
    align-items: center;
    grid-auto-flow: column;

    border-radius: 5px;
    box-shadow: 1px 1px 5px $adoptech-light-grey-03;
    background-color: $adoptech-primary-white;
    margin-bottom: 0.5rem;
    padding: 0.5rem 1rem;
    color: $adoptech-dark-grey-03;

    cursor: pointer;
    position: relative;
  }

  &.noPaddingTheme {
    .adoptechGridTable--row {
      border: 1px solid #CCC;
      height: 48px;
      padding: 0px 16px;
      box-shadow: none;
      margin-bottom: 0px;
      border-radius: 0px;

      &:not(&:last-child) {
        border-bottom: 0px;
      }
    }
  }

  &--warning {
    box-shadow: none;
    border: solid 1px $adoptech-red;
    background-color: $adoptech-red-02;
    padding: 0rem 1rem;
  }

  &--slim {
    padding: 0rem 1rem;
    .btn {
      padding: 0.2rem 0.3rem;
    }
  }

  &--alert {
    border: solid 1px $adoptech-red;
    background-color: $adoptech-red-02;
  }

  &--red-color {
    color: $adoptech-red;
  }
}

.draggableTableTheme {
  @include DragToScrollTheme;
}
