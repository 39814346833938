@import '../../../../src/styles/variables';

.inputField {
  background-color: $adoptech-light-grey-07;
  display: flex;
  width: 100%;

  &--inputArea {
    background: $adoptech-light-grey-10;
    border-radius: 4px;
    border: 0;
    font-size: 16px;
    height: 40px;
    outline: none;
    padding: 0 10px;
    width: 100%;

    &-light {
      color: $adoptech-dark-grey-08;
    }
  }

  &--showPasswordButton {
    align-items: center;
    color: $adoptech-dark-grey-05;
    cursor: pointer;
    display: flex;
    font-size: 19px;
    padding-right: 16px;
  }
}
