.esgSection, .complianceSection{
  .editMode {
    .esgSection--document, .complianceSection--document {
      gap: 16px;
      justify-content: initial;
    }

    .esgSection--documentLogoWithTitle, .complianceSection--documentLogoWithTitle {
      display: flex;
      gap: 16px;
      align-items: center;
    }
  }

}
