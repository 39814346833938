@import '../../styles/variables';

.assessmentPendingResponses {
  &--assessmentResponse {
    width: 100%;
  }

  &--body {
    width: 100%;
  }

  &--delete {
    margin-left: 20px;
  }

  &--header {
    color: $adoptech-dark-grey-05;
    display: flex;
    margin-bottom: 4px;
  }

  &--lastModified {
    align-items: center;
    display: flex;
    font-size: 16px;
    flex-grow: 1;
  }

  &--panelContent {
    align-items: center;
    color: $adoptech-dark-grey-03;
    display: flex;
    font-size: 20px;
    margin: 20px;
  }

  &--pendingHeader {
    width: calc(50% - 20px);
    margin-left: 20px;
  }

  &--reminderButton {
    display: flex;
    align-items: center;
  }

  &--reminderIcon {
    margin-right: 10px;
    color: $adoptech-primary-orange;
  }

  &--stateIcon {
    color: $adoptech-red;
    font-size: 20px;
    margin-right: 16px;

    &-orange {
      color: $adoptech-primary-orange;
    }
  }

  &--vendorName {
    width: 50%;
  }

  &-verticallyCentered {
    align-items: center;
    display: flex;
  }
}
