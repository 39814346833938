@import '../../../styles/variables.scss';

.agreementsTemplates {
  &--title {
    align-items: center;
    color: $adoptech-dark-grey-05;
    display: inline-flex;
    font-size: 24px;
    padding-right: 30px;
    margin-bottom: 1rem;
  }

  $padding-top: 16px;

  &--categoryTitle {
    font-size: 24px;
    padding: $padding-top 0px;
    font-weight: 400;
  }

  &--categoryTemplates {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }
  &--search {
    margin-left: 32px;
  }

  &--noDataPlaceholder {
    padding-top: $padding-top;
  }

  .partOfMyPlanBanner {
    margin-top: 20px;
    float: right;
  }
}
