@import '../../styles/variables';

.adoptechCircleIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 32px;
  height: 32px;
  border-radius: 30px;
  font-weight: bold;
  color: $adoptech-dark-grey-03;
  text-transform: uppercase;

  &--lightBlue {
    background: $adoptech-light-blue-02;
  }

  &--green {
    background: $adoptech-green-06;
  }

  &--red {
    background: $adoptech-red-04;
  }

  &--lightBlue2 {
    background: $adoptech-light-blue-03;
  }
}
