@import '../../styles/variables';

.meatballMenu {
  .dropdown-item {
    color: $adoptech-dark-grey-05;
    padding: 10px 16px 10px 12px;
    font-size: 14px;
    line-height: 20px;

    &:hover,
    :focus {
      color: $adoptech-dark-grey-05;
      background-color: $adoptech-light-grey-07;
    }

    svg {
      margin-right: 0.75rem;
      width: 20px;
    }
  }

  .dropdown-item.disabled,
  .dropdown-item:disabled {
    opacity: 0.5;
  }

  .dropdown-menu {
    border: none;
    box-shadow: 0 0 8px #00000029;
    padding-bottom: 4px;
    margin-top: 15px;

    > a:first-of-type {
      margin-top: 4px;
    }
  }

  .dropdown-divider {
    margin: 4px 0;
  }

  &--button {
    border-radius: 50%;
    color: $adoptech-dark-grey-05;

    .iconButton {
      display: flex;
      font-size: 40px;
      height: 1em;
      justify-content: center;
      width: 1em;
    }

    &:hover {
      background-color: $adoptech-light-grey-05;
    }

    &-open {
      background-color: $adoptech-light-grey-05;
    }
  }

  &--pointer {
    height: 10px;
    margin-top: -18px;
    overflow: hidden;
    position: relative;

    &::after {
      background: white;
      bottom: 25px;
      box-shadow: 0 0 8px #00000029;
      content: '';
      height: 50px;
      //left: -5px;
      position: absolute;
      top: 14px;
      transform: rotate(45deg);
      width: 50px;
    }

    &-lhs {
      &::after {
        left: -5px;
      }
    }

    &-rhs {
      &::after {
        right: -5px;
      }
    }
  }
}
