@import '../../styles/variables';

.adoptechTextArea {
  color: $adoptech-dark-grey-05;
  display: flex;
  flex-direction: column;

  label {
    font-size: 14px;
    color: $adoptech-dark-grey-07;
    margin-bottom: 2px;
  }

  input {
    height: 40px;

    &:focus {
      border: 1px solid $adoptech-dark-grey-08;
    }
  }

  textarea {
    height: 80px;

    &:focus {
      border: 1px solid $adoptech-dark-grey-08;
    }
  }

  &.adoptechTextArea-error {
    background-color: $adoptech-red-background;
    border-color: $adoptech-red;
    color: $adoptech-red;
  }
}
