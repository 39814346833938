@import './../../../styles/variables';

.clientsPage {
  &--tableHeader {
    display: flex;
    gap: 30%;
    margin-bottom: 16px;
  }

  &--filterButtons, &--actionsButtons {
    display: flex;
    gap: 8px;
    justify-content: space-between;
  }

  // table layout
  .adoptechFlexTable {
    .adoptechGridTable--header,
    .adoptechGridTable--row {
      grid-template-columns: 200px 200px 200px auto;
    }
  }
}


