@import '../../../styles/variables';

.auditsTemplates {

  &--list {
    $gap: 32px;

    padding-top: $gap;
    display: flex;
    gap: $gap;
  }
}
