@import '../../styles/variables';

.adoptechButtonContainer {
  display: inline-block;

  .btn.adoptechButton {
    background-color: transparent;
    border: 1px solid $adoptech-dark-grey-11;
    color: $adoptech-dark-grey-05;
    border-radius: 4px;
    min-width: 110px;
    padding-left: 20px;
    padding-right: 20px;
    white-space: nowrap;
    opacity: 1;

    &--shadow {
      box-shadow: 3px 3px 8px $adoptech-light-grey-05;
    }

    &--middleSize {
      padding: 2px;
      font-size: 14px;
    }

    &--middleSize2 {
      padding: 5px 16px;
      font-size: 14px;
      height: 32px;
    }

    &-withGreenIcon {
      border: 1px solid rgba(108, 170, 69, 0.50);
      border-radius: 4px;
      .fa-check {
        color: #6CAA45;
      }

      &.active .fa-check {
        color: white;
      }

      &.active.btn-primary {
        background-color: #6CAA45;
        color: white;
      }
      &.active.btn-primary:focus {
        box-shadow: 2px 2px 8px #6CAA45;
      }
    }

    &-withRedIcon {
      border-radius: 4px;
      border: 1px solid rgba(234, 85, 66, 0.50);
      .fa-xmark {
        color: #EA5542;
      }

      &.active .fa-xmark {
        color: white;
      }

      &.active.btn-primary {
        background-color: #EA5542;
        color: white;
      }

      &.active.btn-primary:focus {
        box-shadow: 2px 2px 8px #EA5542;
      }
    }

    &-small {
      min-width: auto;
      padding-right: 10px;
      padding-left: 10px;
    }

    &-smallRounded {
      min-width: auto;
      padding: 4px 8px;
      border: 1px solid $adoptech-light-grey-01;
      border-radius: 8px;
      color: $adoptech-dark-grey-05;
      font-size: 14px;
    }

    .adoptechButton--content {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      gap: 8px;
    }

    &:hover {
      background-color: $adoptech-dark-grey-05;
      border-color: transparent;
      color: $adoptech-light-grey-10;
    }

    &--with-large-icon {
      min-width: 122px;
      height: 32px;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      .adoptechButton--content {
        height: 16px;
        line-height: 1rem;
      }

      [class$='icon'] {
        height: 14px;
        width: auto;
      }
    }

    &:focus {
      box-shadow: none;
    }

    &-disabled, &:disabled {
      opacity: 0.6;
      cursor: auto;

      &:hover {
        background-color: transparent;
        border-color: $adoptech-light-grey-03;
        color: $adoptech-dark-grey-07;
        opacity: 0.6;
      }
    }

    &-inverse {
      background-color: $adoptech-primary-white;
      border-color: transparent;
      color: $adoptech-red;
    }

    &-uppercase {
      text-transform: uppercase;
    }

    &-primary {
      background-color: $adoptech-orange-01;
      border-color: transparent;
      color: $adoptech-primary-white;
    }
    &-primary:hover {
      opacity: 0.9;
    }

    &-primaryOrange,
    &-primaryOrange:hover {
      background-color: $adoptech-primary-orange;
      border-color: transparent;
      color: $adoptech-light-grey-10;
      border-radius: 12px;
      text-transform: none;
    }

    &-pointer-events-none {
      pointer-events: none;
    }

    &-primaryTransparent {
      &:not(.adoptechButton-disabled) {
        background-color: $adoptech-orange-04;
        border-color: $adoptech-orange-01;
        color: $adoptech-dark-grey-03;
        transition: none;

        &:hover {
          background-color: $adoptech-orange-01;
          color: $adoptech-primary-white;
          /* TODO: fix stylelint */
          /* stylelint-disable */
          .adoptechButton--icon {
            color: $adoptech-primary-white;
          }
          /* stylelint-enable */
        }

        .adoptechButton--icon {
          color: $adoptech-orange-01;
        }
      }
    }

    &-successTransparent {
      &:not(.adoptechButton-disabled) {
        background-color: $adoptech-green-04;
        border-color: $adoptech-green-03;
        color: $adoptech-dark-grey-03;
        transition: none;

        &:hover {
          background-color: $adoptech-green-03;
          color: $adoptech-primary-white;
          /* TODO: fix stylelint */
          /* stylelint-disable */
          .adoptechButton--icon {
            color: $adoptech-primary-white;
          }
          /* stylelint-enable */
        }

        .adoptechButton--icon {
          color: $adoptech-green-03;
        }
      }
    }

    &-successTransparent.adoptechButton--rounded {
      &.adoptechButton-disabled {
        border: 1px solid $adoptech-dark-grey-07;
      }
    }

    &-warningTransparent {
      &:not(.adoptechButton-disabled) {
        background-color: $adoptech-red-02;
        border-color: $adoptech-red;
        color: $adoptech-dark-grey-03;
        transition: none;

        &:hover {
          background-color: $adoptech-red;
          color: $adoptech-primary-white;
          /* TODO: fix stylelint */
          /* stylelint-disable */
          .adoptechButton--icon {
            color: $adoptech-primary-white;
          }
          /* stylelint-enable */
        }

        .adoptechButton--icon {
          color: $adoptech-red;
        }
      }
    }

    &-orangeOnOrange {
      &:not(.adoptech-disabled) {
        background-color: $adoptech-tertiary-orange;
        border-color: $adoptech-tertiary-orange;
        color: $adoptech-primary-white;

        &:hover {
          background-color: $adoptech-secondary-orange;
        }
      }
    }

    &-orangeOnWhite {
      &:not(.adoptech-disabled) {
        background-color: $adoptech-primary-white;
        border-color: $adoptech-primary-orange;
        color: $adoptech-primary-orange;

        &:hover {
          background-color: $adoptech-secondary-orange;
          color: $adoptech-primary-white;
        }
      }
    }

    &-whiteOnDarkOrange {
      &:not(.adoptech-disabled) {
        background-color: $adoptech-orange-08;
        border-color: $adoptech-orange-08;
        color: $adoptech-primary-white;
        font-weight: bold;

        &:hover {
          background-color: $adoptech-primary-dark-gray;
          border-color: $adoptech-primary-dark-gray;
          color: $adoptech-primary-white;
        }
      }
    }

    &-darkOrangeOnWhite {
      &:not(.adoptech-disabled) {
        background-color: $adoptech-primary-white;
        border-color: $adoptech-primary-white;
        color: $adoptech-orange-08;

        &:hover {
          background-color: $adoptech-primary-dark-gray;
          border-color: $adoptech-primary-dark-gray;
          color: $adoptech-primary-white;
        }
      }
    }

    &-transparent {
      &:not(.adoptech-disabled) {
        border: none;

        &:hover {
          background-color: $adoptech-primary-white;
          color: $adoptech-dark-grey-03;
        }
      }
    }

    &-gray {
      border-color: transparent;
      background-color: $adoptech-light-grey-05;
      color: $adoptech-primary-white;

      &:hover {
        background-color: $adoptech-dark-grey-05;
        color: $adoptech-light-grey-05;

        .adoptechButton--icon {
          color: $adoptech-light-grey-05;
        }
      }
    }

    &-darkGray {
      border-color: transparent;
      background-color: $adoptech-dark-grey-05;
      color: $adoptech-primary-white;

      &:hover {
        background-color: $adoptech-dark-grey-07;
        color: $adoptech-light-grey-07;
      }
    }

    &-white {
      color: $adoptech-dark-grey-05;
      border-color: $adoptech-light-grey-01;
      background-color: $adoptech-primary-white;

      &:hover {
        background-color: $adoptech-dark-grey-05;
        color: $adoptech-light-grey-08;

        .adoptechButton--icon {
          color: $adoptech-light-grey-08;
        }
      }
    }

    &-warning {
      background-color: $adoptech-red;
      border-color: transparent;
      color: $adoptech-primary-white;

      &:hover {
        background-color: $adoptech-red;
        color: $adoptech-primary-white;

        .adoptechButton--icon {
          color: $adoptech-primary-white;
        }
      }
    }

    &-successFilled {
      color: $adoptech-light-grey-07;
    }

    &-success:hover,
    &-successFilled {
      background-color: $adoptech-dark-green;
      border-color: transparent;
    }

    &-successFilled:disabled {
      background-color: transparent;
      border-color: $adoptech-light-grey-03;
      color: $adoptech-dark-grey-07;
    }

    &-successFilled:hover {
      background-color: $adoptech-dark-green;
      opacity: 0.8;
    }

    &-google, &-microsoft {
      border-color: $adoptech-dark-grey-05;
      color: $adoptech-dark-grey-03;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 15px;
      border-radius: 4px;

      &:hover {
        background-color: $adoptech-light-grey-03;
        border-color: $adoptech-dark-grey-05;
        color: $adoptech-dark-grey-03;
      }
    }

    .adoptechButton--googleLogo {
      width: 24px;
      position: absolute;
      left: 0;
    }

    .adoptechButton--microsoftLogo {
      width: 24px;
      position: absolute;
      left: 0;
    }

    .adoptechButton--icon {
      font-size: 1rem;
    }

    &--large {
      padding: 6px 16px;
      width: 125px;
      height: 44px;
    }

    &--with-right-icon {
      [class$='icon'] {
        height: 1.5rem;
      }
    }

    &--rounded {
      border-radius: 4px;
      &:hover {
        border-radius: 4px;
      }
    }

    &--asSearchInputTheme {
      border-color: #ccc;
      .adoptechButton--content {
        justify-content: space-between;
        color: #ccc;

        &:hover {
          color: white;
        }

        svg {
          height: 20px;
        }
      }
    }
  }
}
