@import '../../styles/variables';

.autoSaveToast {
  bottom: 70px;
  position: fixed;
  right: calc(50% - 90px);
  z-index: 30;

  .toast {
    border: 1px solid $adoptech-dark-grey-07;
  }

  .toast-body {
    background-color: $adoptech-dark-grey-07;
    font-size: 18px;
    color: $adoptech-primary-white;
  }
}
