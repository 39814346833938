@import '../../styles/variables';

.lozenge {
  background-color: $adoptech-dark-grey-05;
  border-radius: 100px;
  color: $adoptech-light-grey-07;
  display: inline-flex;
  font-size: 16px;
  margin-left: 10px;
  padding: 2px 8px 0px;

  &-primary {
    background-color: $adoptech-primary-orange;
  }

  &-success {
    background-color: $adoptech-green;
  }

  &--delete {
    margin-left: 0.5rem;
    cursor: pointer;
  }

  &--light-grey {
    background-color: $adoptech-dark-grey-07;
  }
}

