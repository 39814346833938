@import '../../styles/variables';

.assessmentsMenuItem {
  align-items: left;
  color: $adoptech-dark-grey-08;
  display: flex;
  flex-direction: column;
  padding: 8px 20px 0;
  position: relative;
  font-size: 16px;
  border: none;

  &::after {
    content: '';
    display: block;
    margin: 0 auto;
    padding-top: 2px;
    margin-bottom: 4px;
    width: 100%;
  }

  &:hover {
    cursor: default;

    &::after {
      border-bottom: 4px solid $adoptech-dark-grey-08;
      content: '';
      display: block;
      margin: 0 auto;
      padding-top: 2px;
      width: 100%;
    }
  }
  &--label {
    align-self: center;
    display: flex;
  }

  &-disabled {
    color: $adoptech-dark-grey-06;

    &:hover {
      cursor: not-allowed;
    }
  }

  &-selected {
    color: $adoptech-dark-grey-05;

    &::after {
      border-bottom: 4px solid $adoptech-dark-grey-05;
      content: '';
      display: block;
      margin: 0 auto;
      padding-top: 2px;
      width: 100%;
    }

    &:hover {
      cursor: default;

      &::after {
        border-bottom: 4px solid $adoptech-dark-grey-08;
        content: '';
        display: block;
        margin: 0 auto;
        padding-top: 2px;
        width: 100%;
      }
    }
  }

  &--lozenge {
    color: $adoptech-light-grey-07;
    position: relative;
    top: -1.2rem;
    left: -1.2rem;
    font-size: smaller;
    border-radius: 2rem;
    padding: 0rem 0.4rem;
    color: white;
  }
}
