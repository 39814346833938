@import '../../styles/variables.scss';

.partOfMyPlanBanner {
  display: flex;
  align-items: center;

  &--text {
    font-size: 14px;
    color: $adoptech-dark-grey-07;
    margin-left: 5px;
  }

  &--greenTick {
    font-size: 20px;
    color: $adoptech-green;
  }
}
