@import '../../mixins/TrustHubSection.scss';
@import '../../../../styles/variables';

.policiesSection, .reportsSection {
  @include TrustHubSection();

  &--documents {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &--documentCheckboxSection {
    display: flex;
    gap: 16px;
    align-items: center;
    max-width: 80%;
  }

  &--documentTitle.editMode {
    max-width: 100%;
  }
}
