@import '../../styles/variables';

.checklistTemplates {
  &--title {
    color: $adoptech-dark-grey-05;
    font-size: 24px;
    padding-right: 30px;
    padding-top: 20px;
    margin-bottom: 20px;
  }

  &--grid {
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin-top: 20px;

      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }
}
