@import '../../styles/variables';

.deleteAssessmentModal {
  margin: 0px;
  padding: 0px;

  &--deleteName {
    font-weight: 700;
  }

  &--description {
    padding: 0 40px;
  }

  &--recipientsTable {
    padding: 0px;
  }

  &--modalBody.modal-body {
    padding: 0px;
  }

  &--recipients table.adoptechTable--wrapper td.adoptechTable--checkbox label {
    padding: 0;
    padding-left: 40px;
  }

  table.adoptechTable--wrapper tr th {
    margin: 0px;
  }
  .adoptechTable table.adoptechTable--wrapper {
    margin-top: 0.8rem;
  }
}
