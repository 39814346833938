@import '../../styles/variables';

.adoptechPageNavBar {
  font-size: 24px;
  padding: 23px 40px;
  margin: 0px -40px;
  display: flex;
  align-items: center;
  color: $adoptech-dark-grey-05;
  border-bottom: 1px solid $adoptech-light-grey-04;
  display: flex;

  &--headerButtons {
    margin-left: auto;
  }

  &--info {
    display: flex;
    align-items: center;
  }

  &--navBack {
    width: auto;
    height: 38px;
    cursor: pointer;
    color: $adoptech-primary-orange;
    margin-right: 16px;
  }
}
