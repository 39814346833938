@mixin WhiteCheckbox {
  input:not(:checked) + label {
    &::before, &::after {
      border: 1px solid $adoptech-light-grey-01;
      background-color: $adoptech-primary-white;
    }
  }

  input:checked + label {
    &::before, &::after {
      background-color: $adoptech-dark-grey-05;
    }

    &::before {
      border: none;
    }
  }
}
