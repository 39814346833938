@import '../../styles/variables';

@keyframes entityCard-slideInRight {
  from {
    opacity: 0;
    transform: translateX(-1000px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes entityCard-shrinkHorizontally {
  from {
    margin-right: 20px;
    width: 300px;
  }

  to {
    margin-right: 0;
    width: 0;
  }
}

.entityCard {
  background: $adoptech-primary-white;
  box-shadow: 0 0 10px $adoptech-box-shadow-hover;
  border-radius: 3px;
  overflow: hidden;

  width: 274px;
  height: 260px;

  display: flex;
  flex-direction: column;

  &--animated {
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-name: entityCard-slideInRight;
    animation-duration: 1s;
  }

  &--progressBar {
    background-color: $adoptech-orange-02;
    height: 10px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &--progressBarStatic {
    background-color: $adoptech-green;
    height: 10px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &--body {
    padding: 25px 20px 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: -5px;
    color: $adoptech-dark-grey-07;
  }

  &--title {
    color: $adoptech-dark-grey-03;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 5px;
  }

  &--detailsText {
    color: $adoptech-dark-grey-07;
    font-size: 12px;
    line-height: 16px;
  }

  &--footer {
    display: flex;
    flex: 1;
    align-items: flex-end;
  }

  &--lastUpdate {
    color: $adoptech-dark-grey-07;
    font-size: 12px;
    line-height: 14px;
    margin-right: 5px;
  }

  &--date {
    color: $adoptech-dark-grey-05;
    font-size: 12px;
    line-height: 14px;
  }
  &--pointer {
    cursor: pointer;
  }
}
