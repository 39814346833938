@import '../../styles/variables';

.sharesTable {
  .table-responsive {
    overflow-y: auto;
    max-height: 220px;
  }

  .adoptechTable-modal .adoptechTable--wrapper td:first-child,
  .adoptechTable-modal .adoptechTable--wrapper th:first-child {
    padding-left: 20px;
  }

  .adoptechTable-modal .adoptechTable--wrapper .sharesTable--userNameCell {
    width: 50%;
  }

  &--deletePermissions:hover {
    color: $adoptech-red;
  }
}
