@import '../../../styles/variables';
@import '~bootstrap/scss/bootstrap';
@import '../../../styles/StickyHeader.scss';
@import '../../../styles/mixins/DropdownMenuItem.scss';

.risksPage {
  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include stickyHeader(2);
  }

  .sortableTableHeader--icon {
    margin-left: 4px;
  }

  &--title {
    display: inline-flex;
    align-items: center;
    color: $adoptech-dark-grey-05;
    font-size: 24px;
    margin: 2rem 0;
  }

  &--headerButtons {
    display: flex;
  }

  &--reports-btn {
    margin-right: 16px;
  }

  &--longName {
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &--7-columns {
    .adoptechGridTable--header,
    .adoptechGridTable--row {
      grid-template-columns: 1fr 60px 112px 82px 80px 124px 116px;
      column-gap: 16px;
      margin-top: 5px;
      &.disabled {
        cursor: not-allowed;
      }
      &>div:not(:first-child) {
        display: flex;
        justify-content: center;
      }
    }

    .adoptechGridTable--row {
      padding: 8px 16px;
      height: 48px;
    }
    .adoptechGridTable--row:hover {
      background-color: $adoptech-light-grey-09;
    }
  }

  .adoptechGridTable--header {
    margin-bottom: 0.5rem;
    @include stickyHeader(1, 6rem);
    .sortableTableHeader--label {
      margin-left: 16px;
    }
  }

  .adoptechTextInput-search {
    width: 320px;
    height: 30px;
  }
  &--reportsButton {
    @include dropdownMenuItems($adoptech-dark-grey-05, $adoptech-dark-grey-08);
  }
}

.pageHint {
  background: $adoptech-light-grey-10;
  min-height: 160px;
  width: 100%;
  padding: 24px;

  display: flex;
  justify-content: space-between;
  font-size: 14px;
  gap: 32px;

  &--right-block {
    display: flex;
    flex-direction: column;
    align-items: column;
    padding: 10px 0px;

    .adoptechButtonContainer {

      .btn.adoptechButton:not(:hover) {
        color: $adoptech-dark-grey-05;
      }
    }
  }

  &--footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // uncomment for video
    // button {
    //   margin-left: 16px;
    // }
  }

  svg {
    width: 20px;
    height: 20px;
    color: $adoptech-dark-grey-07;
  }
}
