.certificatesSection {
  .editMode {
    .certificatesSection--document {
      gap: 16px;
      justify-content: initial;
    }

    .certificatesSection--documentLogoWithTitle {
      display: flex;
      gap: 16px;
      align-items: center;
    }
  }
}
