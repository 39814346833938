@import '../../styles/variables';

.checklist {
  padding-top: 30px;

  &--summary {
    display: flex;
    position: sticky;
    top: 0;
    background-color: $adoptech-light-grey-07;
    z-index: 13;
    margin-left: -10px;
    padding-left: 10px;
    margin-right: -10px;
    padding-right: 10px;
  }

  &--back {
    color: $adoptech-primary-orange;
    cursor: pointer;
    font-size: 32px;
    padding-right: 18px;

    @include media-breakpoint-up(xxl) {
      margin-left: -31px;
    }
  }

  &--summaryTile {
    width: 100%;
  }

  &--description {
    font-size: 16px;
    background-color: $adoptech-primary-white;

    padding: 12px 20px;
    margin-top: 30px;
  }

  &--main {
    padding-top: 20px;
  }

  &--groupHeader {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    width: 80%;
  }

  &--groupHeader-progress {
    align-items: center;
    display: flex;
    height: 100%;
    width: 220px;
  }

  &--groupHeader-progressBar {
    background-color: $adoptech-light-grey-07;
    height: 16px;
    margin: 0 10px;
    width: fill-available;
  }
}
