@import '../../styles/variables.scss';

@mixin DragToScrollTheme {
  .adoptechGridTable {
    &--table {
      overflow: auto;
      cursor: grab;
      touch-action: none;
    }

    &--header,
    &--row {
      cursor: grab;
      display: grid;
      > div {
        height: 100%; // for solid border
      }
    }

    &--header {
      padding: 0px; // for solid border
      .sortableTableHeader {
        &:first-of-type {
          right: auto;
          position: sticky;
          left: 0;
          background-color: $adoptech-primary-white;
          z-index: 1;
        }
      }
    }

    &--row {
      height: 40px;
      border-bottom: 1px solid var(--tertiary-light-grey-05-dddddd, #DDD);
      padding: 0px; // for solid border
      width: fit-content; // to make border visible on scroll
      margin-bottom: 0px;
      box-shadow: none;
      > div {
        padding-top: 8px;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    &--row > div:first-child {
      position: sticky;
      left: 0;
      background-color: $adoptech-primary-white;
      z-index: 1;
    }

    &--divider {
      padding-top: 0px;
      height: 100%;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.05) 100%);
      pointer-events: none;
      width: 8px;
      position: sticky;
      z-index: 1;
    }
  }

  &.dragging {
    user-select: none;
    .adoptechGridTable--header,
    .adoptechGridTable--row {
      cursor: grabbing;
    }
  }
}
