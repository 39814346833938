@import '../../styles/variables';

.allDocuments {
  &--header {
    align-items: center;
  }

  &--title {
    color: $adoptech-dark-grey-05;
    font-size: 24px;
    margin: 2rem 0 0;
  }

  &--uploadButton {
    grid-area: 1 / 3 / 1 / 3;
    justify-self: end;
  }

  .adoptechGridTable {
    &--header, &--row {
      display: grid;
      grid-template-columns: 4fr 1fr 1fr 1fr 200px 1fr;
      gap: 10px;
    }

    &--row {
      cursor: initial;
      &:hover {
        background-color: #f6f6f6;
      }
    }
  }
}
