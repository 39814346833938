@import '../../styles/variables';

.generateAssessmentModal {
  &--inputArea {
    padding-top: 1rem;
  }

  &--label {
    color: $adoptech-dark-grey-07;
    font-size: 14px;
  }
}