@import '../../styles/variables';

.deleteVendorDocumentModal {
  &--documentName {
    font-size: 18px;
    overflow-wrap: break-word;
    padding: 16px 0 0;
  }

  &--documentDescription {
    color: $adoptech-dark-grey-05;
    font-size: 16px;
    padding: 0 0 16px;
  }
}
