@import '../../styles/variables';

.vendorSelector {
  font-size: 15px;
  padding: 0 1rem 1rem 1rem;

  > label {
    color: $adoptech-dark-grey-09;
    display: block;
    font-size: 14px;
  }
}

#vendorSelector {
  & > div {
    background: $adoptech-dark-grey-04;
    border-color: $adoptech-dark-grey-05;

    & > div {
      & > div {
        color: $adoptech-dark-grey-09;
      }

      & > span {
        background-color: $adoptech-dark-grey-05;
      }
    }
  }
}
