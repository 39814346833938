.reportsManageDrawer {
  width: 700px;

  &--label {
    margin-bottom: 16px;
  }

  &--fieldRow:not(:first-of-type) {
    margin-top: 24px;
  }

  &--additionalLabel {
    font-size: 14px;
    margin-bottom: 24px;
  }
}
