@import '../../styles/variables';

.loadingSpinner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  .spinner-border {
    @include theme('color', 'color04');
    height: 40px;
    width: 40px;
  }

  &.inline-small {
    width: initial;
    height: initial;
    margin: 0;
    margin-left: 10px;

    .spinner-border {
      height: 20px;
      width: 20px;
    }
  }
}
