@import '../../styles/variables';

$radio-size: 24px;
$radio-checked-size: 14px;

.adoptechRadioButton {
  margin: 16px 0;

  input[type='radio'] {
    display: none;

    &:checked + label::after {
      transform: scale(1);
    }
  }

  label {
    display: inline-block;
    height: $radio-size;
    position: relative;
    padding: 0 calc($radio-size + 20px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;

    &::before,
    &::after {
      background-color: $adoptech-light-grey-04;
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all 0.25s ease;
      transition-property: transform, border-color;
    }

    &::before {
      left: 0;
      top: 0;
      width: $radio-size;
      height: $radio-size;
      border: 2px solid $adoptech-light-grey-04;
    }

    &::after {
      top: calc($radio-size / 2 - $radio-checked-size / 2);
      left: calc($radio-size / 2 - $radio-checked-size / 2);
      width: $radio-checked-size;
      height: $radio-checked-size;
      transform: scale(0);
      background: $adoptech-dark-grey-05;
    }
  }
}
