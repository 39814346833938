@import '../../styles/variables.scss';

.adoptechRadioButtons-group {
  &--disabled {
    cursor: not-allowed;

    label {
      cursor: not-allowed;
    }
  }
  .adoptechRadioButton {
    $font-size: 15px;

    background: $adoptech-light-grey-09;
    padding: 15px;
    margin: 10px 0;
    font-size: $font-size;
    label {
      padding-right: 0px;
      padding: 0 0 0 32px;
      min-height: 24px;
      height: auto;
      max-height: 100px;
      max-width: 100%;
      overflow: hidden;

      &::before {
        background-color: $adoptech-light-grey-09;
      }

      .additional-label {
        font-size: $font-size - 1;
        opacity: 0.5;
      }
    }
  }

}