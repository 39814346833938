@import '../../../styles/variables.scss';

.agreementsList {

  &:not(:nth-child(2)) {
    margin-top: 2rem;
  }

  @mixin agreementsColumns($spacing) {
    grid-template-columns: $spacing;
  }

  $six-columns-width: 7fr 5fr 5fr 3fr 3fr 1fr;

  &--6-columns {
    .adoptechGridTable--header,
    .adoptechGridTable--row {
      @include agreementsColumns($six-columns-width);
    }
  }

  .adoptechGridTable--row > div {
    padding: 10px 0;
  }

  &--title {
    align-items: center;
    color: #666665;
    display: inline-flex;
    font-size: 24px;
    padding-right: 30px;
  }

  th.agreementsList--name {
    position: relative;
    right: 1rem;
  }
  &--actions {
    grid-column: 6;
  }

  &--header {
    display: grid;
    align-items: center;
    grid-template-columns: 8fr 2fr;
  }

  &--headerButton {
    display: flex;
    justify-content: flex-end;
  }

  &--lastColumn {
    margin-left: auto;
  }

  &--shared-badge {
    border-radius: 10px;
    background: #F2F2F2;
    display: flex;
    padding: 0px 8px;
    align-items: center;
    gap: 8px;
    width: fit-content;
  }
}
