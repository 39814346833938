@import '../../../../../styles/variables';
@import './../../mixins/RHSTableList';

.controlCheckTests {
  @include RHSTableList();

  &--header {
    margin-top: 28px;
  }

  &--tableHeaders, &--tableRow {
    grid-template-columns: 1fr 1fr 6fr 2fr 1fr;
  }



  &--greenTick, &--greyTick, &--redTick, &--yellowTick {
    font-size: 16px;
    margin-right: 16px;
  }

  &--greenTick {
    color: $adoptech-dark-green;
  }

  &--redTick {
    color: $adoptech-red;
  }

  &--yellowTick {
    color: $adoptech-yellow;
  }

  &--greyTick {
    color: $adoptech-light-grey-04;
  }
}
