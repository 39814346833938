@import '../../styles/variables';

.sendAssessmentModal {
  .modal-dialog {
    width: 500px;
  }

  &--errorMessage {
    color: $adoptech-red;
    font-size: 14px;
    margin-top: 12px;
  }

  &--field {
    border: 1px solid $adoptech-light-grey-04;
    background-color: $adoptech-light-grey-07;
    display: flex;
    flex-direction: column;
    height: 40px;
    justify-content: center;
    padding-left: 10px;
  }

  &--instructions {
    color: $adoptech-dark-grey-03;
    font-size: 15px;
  }

  &--label {
    color: $adoptech-dark-grey-05;
    font-size: 14px;
    margin-top: 16px;
    margin-bottom: 2px;
  }
  &--dateContainer {
    display: flex;
    height: 2.4rem;
    flex-direction: column;
  }
  &--clickable {
    cursor: pointer;
    display: block;
  }
  &--selector {
    position: relative;
    align-self: flex-end;
    margin: 0 14px 0 0;
    top: -28px;
    color: $adoptech-dark-grey-09;
  }
  &--buttonsColumn {
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;
    padding: 0.2rem 1rem 0 1.5rem;
  }
}
