@import '../../styles/variables';

.managePolicyRejectionModal {
  .modal-dialog {
    max-width: 701px;

    .modal-body {
      max-height: 499px;
      overflow-y: auto;
      padding: 0;

      & > div:not(.adoptechTable) {
        padding: 0 40px;
      }

      & > div.managePolicyRejectionModal--policyName {
        font-size: 18px;
        padding-top: 17px;
        padding-bottom: 17px;
      }
    }

    .adoptechTable {
      td.managePolicyRejectionModal--cell {
        vertical-align: baseline;
        word-wrap: break-word;
        white-space: pre-wrap;
        min-width: 200px;
      }
    }
  }
}
