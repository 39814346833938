.errorToast {
  bottom: 20px;
  position: fixed;
  right: 20px;
  z-index: 2000;

  &--header {
    font-size: 20px;
    flex-grow: 1;
  }
}
