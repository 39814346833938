@import '../../../styles/variables';

@mixin as-7-columns {
  grid-template-columns: 3fr 3fr 7fr 1fr;
};

.auditReviewTable {
  &--header {
    @include as-7-columns();
    margin-top: 5px;
    font-size: 14px;
    display: grid;
    grid-auto-flow: column;
    padding: 0.5rem 1rem;
    color: $adoptech-dark-grey-07;
  }

  &--row {
    @include as-7-columns();
    font-size: 14px;
    display: grid;
    grid-auto-flow: column;

    border-radius: 5px;
    box-shadow: 1px 1px 5px $adoptech-light-grey-03;
    background-color: $adoptech-primary-white;
    margin-bottom: 0.5rem;
    padding: 0.5rem 1rem;
    color: $adoptech-dark-grey-03;
    border: 1px solid $adoptech-light-grey-03;
    cursor: pointer;
    position: relative;
  }

  &--remove-column {
    display: flex;
    justify-content: flex-end;
  }

  .adoptechTextArea {
    background-color: $adoptech-primary-white;
    border: none;
    padding-top: 5px;
  }

  .adoptechTextArea-error {
    border: solid 1px $adoptech-red;
  }


  [class*='adoptechReactSelect'] {
    [class$='control'] {
      background-color: $adoptech-primary-white;
      border: none;
      &:active {
        background: $adoptech-light-grey-01;
      }
    }
    [class$='indicatorSeparator'] {
      display: none;
    }
  }
  &--error-row {
    $error-color: $adoptech-orange-05;
    background-color: $error-color;
    border: 1px solid $adoptech-orange-06;
    [class*='adoptechReactSelect'] {
      [class$='control'] {
        background-color: $error-color;
      }
    }
    .adoptechTextArea {
      background-color: $error-color;
    }
  }

  textarea:disabled {
    cursor: not-allowed;
  }
}
