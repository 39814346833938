@import '../../styles/variables';

.sendReminderModal {
  .modal-header {
    color: $adoptech-dark-grey-05;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    margin-bottom: 17px;
  }

  .modal-footer {
    padding-top: 40px;
  }

  &--description {
    margin-bottom: 8px;
  }

  &--body {
    b {
      font-size: 18px;
    }
  }
}
