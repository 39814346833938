@mixin CalendarAttachments {
  &--attachment {
    display: flex;
    gap: 50px;
    align-items: center;
    font-size: 14px;

    .fa-trash-can {
      color: red;
      cursor: pointer;
    }
  }

  &--link {
    color: black;
    display: flex;
    gap: 10px;
    align-items: center;
    text-decoration: underline;

    overflow: hidden;
    width: 300px;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      color: black;
      opacity: 0.3;
    }

    &.disabled {
      pointer-events: none;
      color: black;
      opacity: 0.3;
    }
  }
}
