@import '../../../styles/variables';
@import '../PoliciesPageTables/PolicyGrid.scss';

.pendingApprovalPolicies {
  padding-bottom: 3rem;

  &--clickableColumn {
    cursor: pointer;
  }

  &--title {
    align-items: center;
    color: $adoptech-dark-grey-05;
    display: inline-flex;
    font-size: 24px;
    padding-right: 30px;
  }

  &--header {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 2fr;
  }

  .meatballMenu--button .iconButton {
    font-size: 25px;
  }
}
