@import '../../styles/variables';

.addTeamModal {
  &--label {
    color: $adoptech-dark-grey-07;
    font-size: 14px;
    padding-top: 12px;
  }

  &--prompt {
    color: $adoptech-dark-grey-03;
  }
}
