@import '../../styles/variables';

.manageInvoices {
  &--header {
    margin-bottom: 20px;
  }

  &--title {
    color: $adoptech-dark-grey-04;
    font-size: 20px;
  }
}
