@import '../../styles/variables';

.deleteVendorModal {
  &--vendorName {
    font-size: 18px;
    padding: 8px 16px;
    background-color: $adoptech-light-grey-07;
    margin-top: 16px;
    overflow: hidden;
  }
}
