@import '../../styles/variables';

.checklistSummaryTile {
  display: flex;
  flex-direction: column;
  width: 100%;

  &--close {
    margin-right: 10px;
  }

  &--column {
    margin-left: 10px;
    margin-right: 20px;
    height: 78px;

    .btn {
      margin-left: 10px;
    }

    &-grow {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      margin-right: 0;
    }

    &-verticallyCentered {
      align-items: center;
      display: flex;
      height: 100%;
    }
  }

  &--columnData {
    color: $adoptech-dark-grey-04;
    font-size: 18px;
  }

  &--columnHeader {
    color: $adoptech-dark-grey-07;
    font-size: 16px;
  }

  &--complete {
    margin-right: 10px;
  }

  &--completeIcon {
    margin-right: 10px;
  }

  &--info {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
  }

  &--mainContent {
    display: flex;
    height: 100%;
    margin: 10px;
  }

  &--preview {
    margin-right: 10px;
  }

  &--previewIcon {
    margin-right: 10px;
  }

  &--progressBar {
    background-color: $adoptech-orange-01;
    height: 10px;
  }
}
