@import '../../../../styles/variables';
@import './../../mixins/WhiteCheckbox';

.trustHubAuthModal {
  color: $adoptech-dark-grey-05;

  .modal-content {
    border-radius: 8px;
  }

  &--header {
    display: flex;
    padding: 32px 32px 0;
    font-size: 28px;
    align-items: center;
    gap: 16px;

    .fa-xmark {
      margin-left: auto;
    }

    .fa-xmark, .fa-angle-left {
      cursor: pointer;
    }
  }

  &--body {
    padding: 32px;
  }

  &--subTitle {
    position: relative;
    bottom: 30px;
  }

  &--contentFooter {
    &:hover {
      cursor: pointer;
    }
    font-size: 14px;
    img {
      height: 16px;
    }
  }


  &--stepRequired,
  &--stepLogin,
  &--stepRequest,
  &--stepRegisterInvite {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &--stepRequest {
    .termsLabel {
      color: $adoptech-orange;
    }

    @include WhiteCheckbox();
  }
}
