@import '../../../styles/variables';
@import '../../../components/Agreements/AgreementEditor/ClausesList';

.auditsEditor {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 30px;

  &--back {
    color: $adoptech-primary-orange;
    cursor: pointer;

    display: flex;
    align-items: center;
    font-size: 4rem;
  }

  &--spinner {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  &--summary {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 13;
    margin-left: -10px;
    padding-left: 10px;
    margin-right: -10px;
    padding-right: 10px;
  }

  &--summaryTile {
    width: 100%;
    margin-bottom: 18px;
    background-color: $adoptech-primary-white;
  }

  &--header {
    display: flex;
    padding: 20px 20px 4px;
    color: $adoptech-dark-grey-07;
    font-size: 14px;
    font-weight: 400;
  }

  @include clausesList();
  .adoptechAccordionCard {
    margin-bottom: 10px;

    .card-header {
      height: 45px;
      box-shadow: 1px 3px 5px $adoptech-light-grey-03;
    }
  }
}
