.addSupplierManualDetailsScreen {
  .modal-dialog {
    min-width: 720px;
  }

  &--requirements {
    margin-top: 8px;
    border-radius: 4px;
    background: #F6F6F6;
    display: flex;
    padding: 8px;
    align-items: center;

    ul {
      margin-bottom: 0px;
    }
  }
}
