@import '../../styles/variables.scss';

.readRequestModal {
  .modal-dialog {
    max-width: 576px;
  }
  .modal-body {
    height: 100px;
  }

  .modal-footer {
    display: flex;
    & :nth-child(2) {
      flex: auto;
      text-align: end;
      padding-right: 0.5rem;
    }
  }
}
