@import '../../styles/variables.scss';

$body-padding: 20px;
$form-padding: 30px;
$medium-hs-width: 770px;
$small-hs-width: 450px;
$sub-header-height: 67px;
$footer-max-height: 150px;
$thin-width: 400px;
$header-height: 60px;

@mixin AdoptechHSFooter {
  min-height: $header-height;
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  align-items: center;
  background-color: $adoptech-light-grey-06;
}

.adoptechHS {
  &--drawer {
    width: $small-hs-width;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &--formContainer {
      overflow-y: auto;
      padding: $form-padding;
      flex: 1;

      &.noFormPadding {
        padding: 0px;
      }
    }

    &--with-sub-header {
      [class$='formContainer'] {
        height: calc(100% - $footer-max-height - $sub-header-height);
      }
    }

    &--footer {
      @include AdoptechHSFooter();
    }

    .adoptechDrawer--header {
      padding: 0px $body-padding 0px calc($body-padding);
      min-height: $header-height;
      &--with-back-button {
        padding-left: calc($body-padding / 2);
      }

      div:first-child {
        overflow: hidden;
        white-space: nowrap;
        margin-right: 10px;
      }
    }

    &--sub-header {
      display: flex;
      align-items: center;
      color: $adoptech-dark-grey-06;
      border-bottom: 1px solid $adoptech-light-grey-05;
      padding: 10px;
    }

    &--sub-title {
      font-size: 22px;
    }

    &--back-button {
      font-size: 30px;
      cursor: pointer;
    }
  }

  &--medium {
    width: $medium-hs-width;
  }

  &--thin {
    width: $thin-width;

    .adoptechDrawer {
      &--header {
        padding: 0px $body-padding 0px $body-padding;
      }
    }

    .adoptechHS--drawer--formContainer {
      padding: $body-padding $body-padding 0 $body-padding;
    }
  }

  &--no-padding {
    [class*='formContainer'] {
      padding: 0px;
    }
  }
}
