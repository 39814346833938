@import '../../../styles/variables';
@import './CalendarAttachments/CalendarAttachments';

.editEventModal {
  padding: 8px 8px;
  .modal-content {
    border-radius: 8px;
  }

  @media (min-width: 667px) {
    .modal-dialog {
      max-width: 720px;
    }
  }

  &--fieldRow {
    margin-top: 16px;
    display: flex;
  }

  &--field {
    width: 100%;
  }

  &--field:not(:first-of-type) {
    padding-left: 20px;
  }

  .adoptechButton.adoptechButton-primary {
    text-transform: none;
  }

  @include CalendarAttachments;
}
