@import '../../../styles/variables';
@import '../PoliciesPageTables/PolicyGrid.scss';

.livePolicies {
  &--clickableColumn {
    cursor: pointer;
  }

  &--header {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 2fr;
  }

  &--title {
    align-items: center;
    color: $adoptech-dark-grey-05;
    display: inline-flex;
    font-size: 24px;
    padding-right: 30px;
  }

  &--filter {
    display: flex;
    align-items: center;
    justify-content: right;
    span {
      margin-left: 10px;
    }
  }

  &--readWarning {
    cursor: pointer;
    font-size: 14px;
    border-radius: 18px;
    border: 1px solid $adoptech-light-grey-03;
    width: 48px;
    height: 30px;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $adoptech-dark-grey-05;
    transition: background-color 0.1s;

    &:hover {
      background-color: $adoptech-light-grey-06;
    }
    &:active {
      background-color: $adoptech-light-grey-02;
    }
  }

  &--readWarningIcon {
    font-size: 20px;
    color: $adoptech-orange-01;
  }
}
