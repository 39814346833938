@mixin DrawerLines {
  padding-top: 30px;
  padding-bottom: 30px;

  // all except line
  &--title, &--fields {
    padding-left: 30px;
    padding-right: 30px;
  }

  &--line {
    background: #DDD;
    height: 1px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

@mixin DrawerFormStyles {
  &--title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  &--fields {
    display: flex;
    gap: 16px;
    flex-direction: column;
  }

  .addButton {
    margin-left: auto;
    display: flex;
  }

  @include DrawerLines();

  &--2-columns-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}
