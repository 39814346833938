.editSupplierForm {
  &--title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  &--fields {
    display: flex;
    gap: 16px;
    flex-direction: column;
  }
  .react-datepicker-popper {
    z-index: 2;
  }
}
