@mixin FieldTitle {
  font-weight: bold;
  display: flex;

  color: #666665;
  align-items: center;
}

@mixin WhiteTextFields {
  .adoptechTextInput2, .adoptech-select2-container, .adoptechTextAreaInternalLabel-container {
    background: white;
  }

  .adoptechTextAreaInternalLabel label, .adoptech-select2-container--label {
    color: #212529;
  }
}

@mixin FieldBlocks {
  @include WhiteTextFields;

  &--fieldBlocks, &--fieldBlock {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &--fieldBlocks {
    padding: 24px;
  }

  &--fieldBlock {
    padding: 8px 16px;
    background: white;
    border-radius: 8px;
    font-size: 14px;

    &-error {
      border-left: 3px solid red;
      border-radius: 0px;
    }
  }

  &--fieldTitle {
    @include FieldTitle;
    justify-content: space-between;
  }
}
