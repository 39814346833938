@import '../../../styles/variables';

.NoValidRoleText {
  padding-top: 40px;
  font-size: 16px;
  color: $adoptech-dark-grey-05;

  &--intercom {
    color: $adoptech-orange-08;
    font-size: 14px;

    &:hover {
      color: $adoptech-primary-dark-gray;
    }

    svg {
      margin-left: 6.5px;
    }
  }
}
