@import '../../styles/variables';

.adoptechRoundCheckbox {
  input[type='checkbox'] {
    display: none;
  }

  label {
    display: flex;
    margin-bottom: 0;
    cursor: pointer;
    user-select: none;
    padding: 12px;
    color: $adoptech-dark-grey-05;
    font-size: 14px;
    line-height: 18px;
  }

  &--iconContainer {
    align-self: center;
    margin-right: 10px;
    height: 20px;
    width: 20px;
    border-radius: 100px;
    border: 1px solid $adoptech-dark-grey-06;
    color: $adoptech-light-grey-07;

    .adoptechRoundCheckbox--icon {
      height: 15px;
      width: 15px;
      padding: 2px;
      visibility: hidden;
    }
  }

  &--checked {
    .adoptechRoundCheckbox--iconContainer {
      border-color: $adoptech-green;
      background-color: $adoptech-green;
      color: $adoptech-primary-white;

      > svg {
        visibility: visible;
      }
    }
  }

  &--disabled {
    .adoptechRoundCheckbox--iconContainer {
      border-color: $adoptech-light-grey-01;
    }

    label {
      cursor: not-allowed;
    }
  }

  &--label {
    align-self: center;
  }
}
