@import '../../styles/variables';

$adoptech-global-search-narrow: $adoptech-searchbar-width;
$adoptech-global-search-wide: $adoptech-searchbar-width + 100px;
$soft-edge-height: 20px;
$border-radius: 5px;

.globalSearch {
  &--input {
    position: fixed;
    box-shadow: $adoptech-box-shadow;
    top: 10px;
    z-index: 1100; // Between errorToast (2000), meatballMenu.show.dropdown-menu (1000)

    width: $adoptech-global-search-narrow;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;

    input {
      background-color: $adoptech-primary-white;
      border-radius: 5px;
      padding-left: 38px;
    }

    &:hover {
      width: $adoptech-global-search-wide;
      -webkit-transition: all 1s ease-in-out;
      -moz-transition: all 1s ease-in-out;
      -o-transition: all 1s ease-in-out;
      transition: all 1s ease-in-out;

      box-shadow: $adoptech-box-shadow-dark;
    }
    .adoptechTextInput-iconContainer {
      align-self: flex-start;
      padding-left: 10px;
      opacity: 0.5;
    }
  }

  &--open {
    width: $adoptech-global-search-wide;
    border-radius: $border-radius;
  }

  &--closed {
    width: $adoptech-global-search-narrow;
    border-radius: $border-radius;
  }

  &--results {
    border-radius: $border-radius;
    box-shadow: 0px 10px 20px 5px $adoptech-light-grey-05;
    overflow: auto;
    height: 190px;
    width: $adoptech-global-search-wide;
    padding: 8px;
    background-color: $adoptech-primary-white;
    position: fixed;
    top: 53px;
    z-index: 1100; // Between errorToast (2000), meatballMenu.show.dropdown-menu (1000)
    display: flex;
    flex-direction: column;
    font-size: 14px;

    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
  }

  &--resultRow {
    padding-bottom: 5px;
    cursor: pointer;
    color: $adoptech-dark-grey-04;

    &:hover {
      font-weight: 600;
      color: $adoptech-orange;
    }
  }

  &--background {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 1099; // below --results and input
  }

  &--resultRows {
    overflow: auto;
    height: 100%;
  }

  &--resultHeader {
    position: sticky;
    min-height: $soft-edge-height;
    margin-bottom: -$soft-edge-height;

    background-image: linear-gradient(
      rgb(255, 255, 255),
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.1)
    );
  }

  &--resultFooter {
    position: sticky;
    min-height: $soft-edge-height;
    margin-top: -$soft-edge-height;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.9),
      rgb(255, 255, 255)
    );
  }
}
