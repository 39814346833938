@import '../../styles/variables';

.addOfficeAddressModal {
  .adoptechModal--subtitle {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .modal-content {
    border-radius: 8px;
  }

  .modal-dialog {
    max-width: 480px;
  }

  .modal-header {
    color: $adoptech-dark-grey-03;
    font-size: 28px;
  }

  label {
    margin-top: 10px;
  }

  &--form {
    > div {
      margin-bottom: 5px;
    }
  }
}
