@import '../../styles/variables';

.adoptechTextInput2 {
  display: flex;
  flex-direction: column;
  position: relative;
  background: $adoptech-light-grey-09;

  &--rounded {
    border-radius: 4px;
    input {
      border-radius: 4px;
    }
  }
  label {
    color: $adoptech-dark-grey-07;
    position: absolute;
    top: 5px;
    left: 8px;
    font-size: 13px;
    user-select: none;
    pointer-events: auto;
  }

  &-container {
    display: flex;
    height: 52px;
    position: relative;
    cursor: text;
    > input:focus {
      border: 1px solid $adoptech-dark-grey-08;
    }
  }

  input {
    padding-top: 24px;
    position: relative;
    background: none;
    border: none;
    width: 100%;
    z-index: 1;
    padding-right: 7px;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &-withIcon {
    input {
      padding-right: 30px;
    }
  }

  svg {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: $adoptech-dark-grey-07;
  }

  &-error {
    border: 1px solid $adoptech-red;
    .adoptechTextInput2-container {
      > input:focus {
        border: none;
      }
    }
  }
  &-error-message {
    display: block;
    font-size: 11px;
    opacity: 0.5;
    color: $adoptech-red;
  }

  &--readOnlyPrefix {
    font-weight: bold;
    display: flex;
    align-items: flex-end;
    padding: 5px 0 4px 7px;
    &:hover {
      cursor: pointer;
    }
    & + input {
      padding-left: 0px;

      &:focus {
        border: none;
      }
    }
  }
}
