@import '../../styles/variables';

.uploadLogoModal {
  .modal-body {
    font-size: 14px;
    padding: 0 32px;
  }

  .modal-content {
    border-radius: 8px;
  }

  .modal-dialog {
    max-width: 384px;
  }

  .modal-header {
    font-size: 28px;
    padding: 32px 32px 10px 32px;
  }

  .modal-footer {
    padding: 10px 32px 32px 32px;
  }

  &--dropZone {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .dropZone--hitArea {
    height: 240px;

    .dropZone--icon {
      font-size: 100px;
    }

    .dropZone--prompt {
      max-width: 100%;
    }
  }
}
