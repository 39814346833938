.supplierDetailsPage {

  &--header {
    display: flex;
    align-items: center;
    margin-top: 26px;
  }
  &--navBack {
    display: flex;
    height: 32px;
    padding: 8px 8px 8px 0px;
    justify-content: center;
    align-items: center;
    color: #F1881F;
    cursor: pointer;
  }
  &--title {
    font-size: 20px;
    font-weight: bold;
  }

  &--badge {
    margin-left: 16px;
    border-radius: 10px;
    background: #F2F2F2;
    display: flex;
    justify-content: center;
    color: #F1881F;
    font-weight: bold;
    padding: 0px 8px;
  }

  &--status-due_diligence svg {
    color: #FFA500;
  }

  &--status-pending svg {
    color: #ccc;
  }

  &--status-approved svg {
    color: #32CD32;
  }

  &--status-offboarding svg {
    color: #FF4500;
  }

  &--status-closed svg {
    color: #DC143C;
  }

}
