@import '../../../styles/variables';

.adoptechPager {
  color: $adoptech-dark-grey-03;
  display: flex;
  align-items: center;
  user-select: none;

  .fa-angle-left, .fa-angle-right {
    font-size: 32px;
    &.arrow-active {
      color: $adoptech-orange;
      cursor: pointer;
    }
    &.arrow-disabled {
      color: $adoptech-dark-grey-07;
    }
  }

  input {
    margin: 8px;
    width: 32px;
    text-align: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
