.light-square-icon {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #CCC;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    color: #666665;
  }
}
