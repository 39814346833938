@import '../../styles/variables';

.managePestelItemsDrawer {
  .accordion {
    padding: 0px 30px 0px 30px;
    border-bottom: 1px solid $adoptech-light-grey-04;
  }

  .card {
    box-shadow: none;
  }

  .card-header {
    border-bottom: none;
    padding: 0px;
  }

  &--fieldRow {
    display: flex;
  }

  &--fieldRow:not(:first-of-type) {
    padding-top: 16px;
  }

  &--fieldRow:last-of-type {
    margin-bottom: 16px;
  }

  &--field:not(:first-of-type) {
    padding-left: 20px;
  }

  &--field {
    width: 100%;
  }

  .card .card-header {
    // fix date-picker overlap
    z-index: unset;
  }

  &--field-risk {
    align-items: center;
    display: flex;
    transform: scale(1.2);
    margin-left: 20px;
  }

  [data-icon='circle-question'] {
    margin-left: 20px;
    color: $adoptech-dark-grey-07;
  }
}