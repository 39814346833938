@import '../../styles/variables';

.tableAnswer2 {
  &--addButton {
    text-align: right;
    margin-top: 35px;
    margin-bottom: -10px;
  }

  &--additionalColumns{
    cursor: pointer;
  }

  &--explanatoryText {
    margin-left: 0.5rem;
    cursor: pointer;
  }
}
