@import '../../../styles/variables.scss';

.editAgreementForm {
  &--section-label {
    font-weight: bold;
    padding-top: 1rem;

    &:first-of-type {
      padding-top: 0;
    }
  }

  &--section-labels {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--section-labels:not(:first-child) {
    margin-top: 25px;
  }

  &--section-badge {
    background-color: $adoptech-orange-01;
    color: $adoptech-primary-white;
    padding: 3px 10px 3px 10px;
    font-size: 15px;
    border-radius: 9px;
  }

  &--table-headers {
    display: grid;
    justify-content: flex-start;
    font-size: 12px;
    opacity: 0.7;
  }

  &--table-row {
    font-size: 15px;
    display: grid;
    border-bottom: 1px solid $adoptech-light-grey-05;
    align-items: center;

    .meatballMenu--button .iconButton {
      font-size: 30px;
    }
  }

  &--external-table-row {
    line-height: 40px;
  }

  &--2-columns {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .adoptech-datepicker2,
    .adoptech-select2-container {
      flex: 0 0 calc(48%);
    }
  }

  &--4-columns {
    grid-template-columns: 7fr 3fr 3fr 1fr;
  }

  &--3-columns {
    grid-template-columns: 7fr 6fr 1fr;
  }

  &--upload-button {
    .btn.adoptechButton-primaryTransparent:not(.adoptechButton-disabled) {
      color: $adoptech-orange-01;
      width: 80px;
      &:hover {
        color: $adoptech-primary-white;
      }
    }
  }

  &--table-wide-column {
    grid-column: 2 / span 2;
  }

  &--section-sub-label {
    font-size: 15px;
    vertical-align: middle;

    img {
      height: 20px;
    }

    svg {
      vertical-align: sub;
    }
  }

  &--section-sub-label:not(:first-child) {
    margin-top: 15px;
  }

  &--section-hint {
    font-size: 13px;
    color: $adoptech-dark-grey-04;
    display: inline-block;
    margin-top: -8px;
    padding-top: 0.7rem;
  }

  &--greenTick {
    color: $adoptech-green;
  }

  &--redTick {
    color: $adoptech-red;
  }

  &--greyTick {
    color: $adoptech-dark-grey-08;
  }

  &--upload-area {
    margin-top: 0.8rem;
    height: 65%;
  }

  &--buttons-row {
    display: flex;
    justify-content: space-between;
  }
}
