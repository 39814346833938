@import '../../styles/variables';

.manageInvoicesGrid {
  margin: 0 -40px;

  .adoptechTable--wrapper .manageInvoicesGrid--row {
    cursor: auto;
  }

  &--actions {
    color: $adoptech-dark-grey-05;
  }

  &--message {
    color: $adoptech-dark-grey-07;
    font-size: 14px;
    text-align: center;
  }
}
