@import '../variables';
@mixin dropdownMenuItems($color, $border-color: null) {
  @if not $border-color {
    $border-color: $color;
  }

  .dropdown-menu {
    padding: 0px;
    border: 0.5px solid $border-color;
    filter: drop-shadow(-4px 4px 10px rgba(0, 0, 0, 0.1)) drop-shadow(4px -4px 10px rgba(0, 0, 0, 0.1));
    border-radius: 3px;
    min-width: auto;
    width: 100%;
    border-radius: 4px;
  }

  .dropdown-item {
    color: $color;
    font-size: 14px;
    min-height: 33px;
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border: 0.5px solid $border-color;
    border-width: 0px 0px 0.5px 0px; // show only bottom border
    white-space: normal;
    &:hover {
      background-color: $color;
      color: white;
    }

    &:last-child {
      border: none;
    }
  }
}
