@import '../../styles/variables';

.panel {
  background-color: $adoptech-primary-white;
  border-radius: 8px;
  box-shadow: 1px 1px 4px $adoptech-box-shadow-color;
  display: inline-block;
  opacity: 1;
  width: inherit;

  &-clickable {
    cursor: pointer;

    &:focus {
      box-shadow: 3px 3px 10px $adoptech-box-shadow-hover;
      outline: none;
    }

    &:hover {
      box-shadow: 3px 3px 10px $adoptech-box-shadow-hover;
    }
  }

  &-darkerBoxShadow {
    box-shadow: 4px 4px 10px $adoptech-primary-black;
  }

  &-selected {
    background-color: $adoptech-primary-orange;

    div {
      color: $adoptech-primary-white;
    }
  }
}
