@import '../mixins/TrustHubSection.scss';

.informationSecuritySection {
  @include TrustHubSection();

  &--2-columns {
    display: flex;
    gap: 32px;

    > div {
      flex: 1;
      margin-top: 0px;
    }
  }

  .policiesSection, .reportsSection {
    &--documentTitle {
      max-width: initial;
      display: flex;

      .fa-circle-check {
        margin-right: 10px;
        color: #A9D44C;
        font-size: 24px;
      }
    }

    &--document {
      width: 100%;
    }
  }
}
