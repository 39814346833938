@import '../../styles/variables';

.loadingScreen {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &--header {
    /* Header */

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    height: 75.97px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    @include media-breakpoint-down(lg) {
      gap: 20px;
    }
  }

  &--logoAndLink {
    position: relative;
    margin-top: 40px;
    width: 100%;
  }

  &--logo {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;

    img {
      height: 28px;
    }
  }
}
