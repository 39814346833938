@import '../../styles/variables.scss';

.existingPolicyWarningModal {
  .modal-dialog {
    max-width: 540px;
  }

  &--subtitle {
    font-size: 15px;
    color: $adoptech-dark-grey-03;
  }

  &--radioButtons {
    display: flex;
    align-items: center;

    margin-top: 20px;
    margin-bottom: 20px;

    .adoptechRadioButton {
      width: 19%;

      label {
        padding-right: 126px;
        padding-left: 36px;
      }
    }
  }

  label {
    color: $adoptech-dark-grey-05;
  }

  &--explanation {
    color: $adoptech-dark-grey-07;
    font-size: 14px;
    flex-basis: 33%;
  }

  .modal-footer {
    margin-top: 20px;
  }
}
