@import '../../styles/variables';

.manageSupplierDrawer {
  width: 720px;
  user-select: none;

  .adoptechAccordionCard .card .card-header {
    // fix date-picker overlap
    z-index: unset;
  }

  &--datepicker-icon {
    font-size: 20px;
    color: $adoptech-dark-grey-07;
    height: 1rem;
    margin-left: 0;
    position: relative;
    left: 314px;
    top: -28px;
  }

  &--label {
    color: $adoptech-dark-grey-07;
    margin: 0px;
  }

  &--fieldRow {
    display: flex;
    align-items: baseline;
  }

  &--fieldRow:not(:first-of-type) {
    display: flex;
    padding-top: 16px;
  }

  &--field {
    width: 100%;
  }

  &--field:not(:first-of-type) {
    padding-left: 20px;
  }

  .adoptechAccordionCard > .expanded {
    box-shadow: none;
  }

  &--severitySelect {
    display: flex;
    justify-content: center;
  }

  &--severityOption {
    text-align: center;
  }

  &--severityOption label {
    padding: 0;
  }

  &--severityOption label:before {
    position: relative;
    width: 100px;
    height: 12px;
    display: block;
    border-radius: 0;
    border: none;
  }

  &--severityOption:nth-child(1) label:before {
    border-radius: 12px 0 0 12px;
    background-color: #75bc46;
  }

  &--severityOption:nth-child(2) label:before {
    background-color: #a8d63f;
  }

  &--severityOption:nth-child(3) label:before {
    background-color: #ebce2a;
  }

  &--severityOption:nth-child(4) label:before {
    background-color: #f1881f;
  }

  &--severityOption:nth-child(5) label:before {
    border-radius: 0 12px 12px 0;
    background-color: #e55f4e;
  }

  &--severityOption label:after {
    left: 38px;
    top: -6px;
    width: 24px;
    height: 24px;
    border: 1px solid #bebebe;
    background-color: white;
  }

  &--xmark {
    margin-left: 5px;
    border: 1px solid $adoptech-mid-blue;
    border-radius: 50%;
    cursor: pointer;
  }

  &--certificationSlug {
    background-color: $adoptech-mid-blue;
    color: $adoptech-primary-white;
    padding: 0 1px 0 10px;
    margin-right: 10px;
    border-radius: 10px;
  }

  &--error input {
    background-color: $adoptech-red-background;
    border: solid 1px $adoptech-red;
  }

  &--error-text {
    color: $adoptech-red;
  }

  &--questionMarkIcon {
    font-size: 34px;
    padding: 0px 25px 0px 0px;
  }
}
