@import '../../../styles/variables';
@import '../../../styles/StickyHeader.scss';

.pestelPage {
  color: $adoptech-dark-grey-05;

  &--pageHeader {
    display: flex;
    width: 100%;

    margin-bottom: 2rem;
    padding-top: 2rem;
    @include stickyHeader(2);
  }

  &--search {
    input {
      height: 38px;
    }
  }

  &--buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
  }

  &--buttons .adoptechButtonContainer {
    margin-left: 20px;
  }

  &--pestelTypeSection {
    display: flex;
    margin-top: 1rem;
    width: 100%;
    flex-direction: column;
  }

  &--pestelHeader {
    display: flex;
    width: 100%;
  }

  &--itemTitle {
    font-size: 22px;
    width: 100%;
  }

  &--description {
    font-size: 16px;
    padding-bottom: 1.5rem;
  }

  &--noDataText {
    padding-top: 40px;
    font-size: 16px;
  }
  .userAvatar--name {
    font-size: 14px;
    color: $adoptech-dark-grey-05;
  }

  .disabled {
    cursor: not-allowed;
  }
  .react-datepicker-popper {
    z-index: 2;
  }

  &--searchRow {
    display: flex;
    align-items: center;
    input {
      height: 38px;
    }

    .lozenge {
      width: fit-content;
      background-color: $adoptech-dark-grey-08;
      height: 24px;

      font-size: 14px;
      color: $adoptech-primary-white;
      display: flex;
      align-items: center;
    }

    .adoptechTextInput {
      flex-grow: 1;
      margin-left: 16px;

      input {
        margin-left: 0px;
      }
    }
  }

  &--registryTitle {
    display: flex;
  }
}
