@import '../../styles/variables';

.adoptechDrawer {
  height: 100%;
  background-color: $adoptech-primary-white;
  position: fixed;
  top: 0;
  right: 0;
  width: 40%;
  z-index: 555;

  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  overflow: auto;

  &--header {
    padding: 30px 20px;
    padding-bottom: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $adoptech-dark-grey-04;
    font-size: 30px;
    font-weight: 300;
    border-bottom: 1px solid $adoptech-light-grey-07;
  }

  &--header-inverted {
    color: $adoptech-primary-white;
    background-color: $adoptech-dark-grey-05;
    border-color: transparent;
  }

  &--badge {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    height: 27px;
    background: $adoptech-dark-grey-10;
    border-radius: 8px;
    font-size: 16px;
    margin-right: 11px;
    min-width: fit-content;

    &-compliant {
      background-color: $adoptech-green;
    }

    &-not-compliant {
      background-color: $adoptech-orange-01;
      min-width: 117px;
    }

    &-not-applicable {
      min-width: fit-content;
    }
  }

  &--close {
    cursor: pointer;
  }
  &--titleContainer {
    margin-right: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  &--title {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 400;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--open {
    transform: translateX(0);
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  }

  &--back-button {
    cursor: pointer;
    margin-right: 11px;
  }
}

.drawerBackgroundScreen {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(120, 238, 9, 0);
  z-index: 550;
  cursor: pointer;
}
