@import '../../../styles/variables';
@import './../../../features/compliance/controls/mixins/RHSTableList';

.complianceControlPoliciesSimpleList {

  @include RHSTableList();

  &--tableRow {
    cursor: auto;

    svg {
      cursor: pointer;
    }
  }
  &--addRow {
    padding-top: 1.8rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .adoptechReactSelect {
      width: 100%;
      margin-right: 1rem;
    }
  }
}
