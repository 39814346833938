@import '../../../../../styles/variables';
@import '../../../../../components/Agreements/AgreementQuestion/AgreementQuestion';

.agreementQuestion {
  &--header {
    display: flex;
    justify-content: space-between;
  }

  &--rightButtons {
    min-width: fit-content;
  }

  &--questionArea-centered {
    display: flex;
    align-items: center;

    p { margin: 0px; }
  }
}
