@import '../../styles/variables';

.genericModal {
  .modal-body {
    color: $adoptech-dark-grey-03;
    font-size: 30px;
    padding: 6px 38px;
  }

  .modal-content {
    border-radius: 3px;
    height: 360px;
    min-width: 540px;
  }

  .modal-dialog {
    width: 460px;
  }

  .modal-footer {
    border-top: 0;
    color: $adoptech-dark-grey-07;
    font-size: 14px;
    padding: 6px 38px 30px;
  }

  .modal-header {
    border-bottom: 0;
    color: $adoptech-dark-grey-04;
    font-size: 30px;
    font-weight: 300;
    padding: 30px 38px 6px;
  }

  &--body {
    color: $adoptech-dark-grey-03;
    font-size: 15px;
  }

  &--footer {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    width: 100%;

    &-twoButtons {
      justify-content: space-between;
    }
  }

  &--header {
    color: $adoptech-dark-grey-04;
    font-size: 30px;
    font-weight: 300;
  }
}
