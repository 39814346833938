@import '../../styles/variables';

.addUserModal {
  &--name {
    display: flex;
    justify-content: space-between;
  }

  &--emailAddress {
    margin-top: 1rem;
  }

  &--footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
