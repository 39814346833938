.sortableTableHeader {
  cursor: pointer;

  &--header {
    display: flex;
  }

  &--icon {
    margin-left: 16px;
    width: 0.875em;
  }

  &--label {
    user-select: none;
  }

  &--noSorting {
    cursor: default;
    pointer-events: none;
  }
}
