@import '../../styles/variables';

.managePaymentMethods {
  margin-bottom: 48px;

  &--header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  &--title {
    color: $adoptech-dark-grey-04;
    font-size: 20px;
  }
}
