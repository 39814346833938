@import '../../styles/variables';

.policyFrameworksList {
  color: $adoptech-dark-grey-05;
  font-size: 16px;

  &--section-labels:not(:first-child) {
    margin-top: 25px;
  }

  &--section-label {
    font-weight: bold;
  }

  &--headers, &--frameworksRow {
    display: grid;
    grid-template-columns: 3fr 8fr 3fr;
  }

  &--framework {
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
  }

  &--empty {
    color: $adoptech-dark-grey-07;
  }

  &--category {
    font-size: 16px;
  }

  &--reference {
    font-size: 16px;
  }

  &--label {
    color: $adoptech-dark-grey-07;
    font-size: 14px;
    padding: 0.5rem 0;
  }

  &--frameworks {
    display: flex;
    flex-direction: column;
  }

  &--frameworksRow {
    padding: 0.5rem 0;
    border-bottom: 1px solid $adoptech-light-grey-05;
  }
}
