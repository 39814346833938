@import '../../styles/variables';

.assessmentQuestionHeader {
  &--title {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 15px;

    > div {
      padding-right: 10px;
    }
  }

  &--categoryProgress {
    align-items: center;
    display: flex;
    height: 100%;
    width: 350px;
  }

  &--categoryProgressAmount {
    width: 40px;
  }

  &--categoryProgressBar {
    background-color: $adoptech-light-grey-07;
    height: 16px;
    margin: 0 10px;
    width: fill-available;
    max-width: 240px;
  }
}
