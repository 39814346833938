@import '../../../../../styles/variables';
@import '../../mixins/RHSTableList.scss';

.complianceControlFrameworksSimpleList {
  @include RHSTableList();

  &--tableRow {
    cursor: auto;
  }

  &--tableHeaders, &--tableRow {
    grid-template-columns: 6fr 10fr 6fr 2fr;;
  }

  &--icon-disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
  &--icon {
    cursor: pointer;
  }

  &--addRow {
    padding-top: 1.8rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .adoptechTextInput.adoptechTextInputContainer {
      width: 100%;
      margin-right: 1rem;
    }
    .adoptechReactSelect {
      width: 100%;
      margin-right: 1rem;
    }
  }

}
