@import '../../styles/variables';

.assessmentResponseHistoryPopup {
  .dropdown-menu {
    border: none;
    padding: 0;

    min-width: 300px;
    max-width: 600px;
  }

  .fa-triangle-exclamation {
    display: none;
  }

  &--exclamation {
    .fa-triangle-exclamation {
      display: inline-block;
    }
  }

  &--button {
    color: $adoptech-dark-grey-05;

    .iconButton {
      display: flex;
      font-size: 40px;
      height: 1em;
      justify-content: center;
      width: 1em;
    }

    svg {
      margin-right: 9px;
    }

    &:hover {
      background-color: $adoptech-light-grey-05;
    }

    &-open {
      background-color: $adoptech-light-grey-05;
    }
  }

  &--icon {
    margin-right: 10px;
  }

  &--pointer {
    height: 10px;
    margin-top: -10px;
    overflow: hidden;
    position: relative;

    &::after {
      background: white;
      bottom: 25px;
      box-shadow: 0 0 8px #00000029;
      content: '';
      height: 50px;
      right: 0;
      position: absolute;
      top: 14px;
      transform: rotate(45deg);
      width: 50px;
    }
  }

  &--body {
    background-color: $adoptech-primary-white;
    box-shadow: 0 3px 10px $adoptech-box-shadow-hover;
    border-radius: 3px;
  }

  &--title-secondary {
    font-size: 18px;
    padding: 10px 20px;
    border-bottom: 1px solid $adoptech-light-grey-01;
    color: $adoptech-dark-grey-05;
  }

  &--lastUpdatedText,
  &--responseLabel {
    font-size: 14px;
    color: $adoptech-dark-grey-07;
  }

  &--lastUpdatedAt {
    font-size: 16px;
    color: $adoptech-dark-grey-05;
    margin-bottom: 15px;
  }

  &--history {
    padding: 20px;
  }

  &--historyEntry {
    padding: 10px 0 15px;

    &:not(:last-child) {
      border-bottom: 1px solid $adoptech-light-grey-01;
    }
  }

  .adoptechTable table.adoptechTable--wrapper td {
    white-space: initial;
  }
}
