@import '../../styles/variables';

.sendAssessmentConfirmationModal {
  &--instructions {
    color: $adoptech-dark-grey-03;
    font-size: 15px;
  }

  &--name {
    color: $adoptech-dark-grey-03;
    font-size: 20px;
    margin-top: 12px;
  }
}
