@import '../../../styles/variables.scss';

.agreementSendReviewModal {
  .modal-content {
    border-radius: 8px;
    font-size: 14px;
  }

  .modal-dialog {
    width: 480px;
    height: 571px;
  }

  &--signatoriesHeader {
    color: $adoptech-dark-grey-07;
    padding: 18px 0px 8px;
  }

  &--signatoriesRow {
    display: flex;
    height: 62px;
    box-shadow: 1px 1px 4px rgb(0 0 0 / 20%);
    border-radius: 8px;

    overflow: hidden;
    white-space: nowrap;

    .userAvatar--name,
    .userAvatar--email {
      text-overflow: ellipsis;
      width: 280px;
      overflow: hidden;
    }

    .adoptechCheckbox {
      width: 20px;
      margin-left: 20px;
      margin-right: 20px;

      label {
        &::before {
          border: none;
          background: $adoptech-dark-grey-05;
        }

        &::after {
          border-width: 0 2px 2px 0;
          background: $adoptech-dark-grey-05;
        }
      }
    }
  }
}
