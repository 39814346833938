@import '../../styles/variables';

.assessmentQuestion {
  border-bottom: 1px solid $adoptech-light-grey-04;
  padding: 20px;
  padding-bottom: 10px;

  &--header {
    color: $adoptech-dark-grey-03;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &--answerArea {
    font-size: 16px;
    padding-bottom: 20px;
  }

  &--text {
    color: $adoptech-dark-grey-05;
    font-size: 16px;
  }

  &--footer {
    display: flex;
    justify-content: space-between;
  }

  &--actionButtons {
    display: flex;
  }

  &--confirmed.btn.adoptechButton {
    width: 110px;
    pointer-events: none;

    .svg-inline--fa {
      margin-right: 4px;
      width: 0.8em;
    }
  }

  .assessmentResponseLibraryPopup--button {
    margin-right: 10px;
  }
}
