@import '../../../styles/variables';

@mixin CompanySectionPanel {
  .panel {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    height: 100%;

    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
  }
}
@mixin CompanySection {
  margin-top: 20px;
  width: 100%;

  &--button {
    .btn.adoptechButton {
      min-width: initial;
      padding: 3px 12px;
    }

    &-withLeftMargin {
      margin-left: 16px;
    }
  }

  &--checkbox {
    color: $adoptech-dark-grey-07;
    margin-top: 8px;
  }

  &--header {
    align-items: center;
    color: $adoptech-dark-grey-05;
    display: flex;
    gap: 8px;
  }

  &--icon {
    font-size: 22px;
  }

  &--title {
    flex-grow: 1;
    font-size: 18px;
  }

  @include CompanySectionPanel;

  &--contents {
    display: flex;
    gap: 24px;
    flex-direction: column;
    &--row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 2fr;
      gap: 40px;
      &-wide-column {
        grid-template-columns: auto;
      }
      &-3-columns {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    &--column {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &--label {
      color: var(--tertiary-dark-grey-09999995, #999995);
      font-size: 12px;
    }

    &--value {
      color: var(--Primary-Adoptech-Dark-Grey, #444445);
      font-size: 16px;
    }
  }
}

.companyInformation {
  @include CompanySection;
}
